// @ts-ignore
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import useSWR from 'swr';
import { Box, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import { ifElse } from 'ramda';
import { Permission as PermissionType } from './types';
import { API_URL } from '../../apiTypes';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { Role } from '../../hooks/useAppUser';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { PermissionForm } from '../../form/permission';
import { appPaths } from '../../paths';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
export interface RouteParams {
    id: string;
}

export const Permission = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);
    const { id } = useParams<RouteParams>();
    const history = useHistory();
    const numericId = Number(id);

    const {
        data: roles,
        isValidating: isValidatingRoles,
        error: errorRoles,
        mutate: mutateRoles,
    } = useSWR<Array<Role>>(`${API_URL.ROLE_LIST}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });
    const {
        data,
        isValidating,
        error: errorData,
        mutate,
    } = useSWR<PermissionType>(`${API_URL.PERMISSION_ITEM}?id=${numericId}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    useEffect(() => {
        mutateRoles();
        if (!equalsZero(numericId)) {
            mutate();
        }
    }, [numericId]);

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi({
                    url: API_URL.PERMISSION_POST,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(`${appPaths.PERMISSIONS}`);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.PERMISSION_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);
    const goBackUrl = `${appPaths.PERMISSIONS}`;
    return (
        <PageWrapper loading={isValidating || isValidatingRoles} error={errorRoles || errorData}>
            <HeaderPage
                stepName={t('Permissions.add-permission')}
                heading={t('Permissions.add-permission')}
                breadCrumbSteps={[{ name: t('Permissions.main-headline'), pathToCutCount: 1 }]}
                noPy
                noPaper
            />
            <Grid item container xs={12} sm={9} md={10}>
                <Paper>
                    <Box p={3}>
                        <PermissionForm
                            onSubmit={submit}
                            buttonTitle={t('Save')}
                            data={data}
                            roles={roles}
                            goBackUrl={goBackUrl}
                            userCanEdit={userCanEdit}
                            t={t}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
