import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Lang } from '../types/core/lang.types';
import { STORAGE_KEYS } from '../localStorageKeys';
import moment from 'moment';
import 'moment/locale/cs';
import { API_URL } from '../apiTypes';
import { fetcher } from '../apiUtils';
import useSWR from 'swr';

interface TranslationContextType {
    t: (query: string) => string;
    changeLang: (lang: Lang) => void;
    lang: Lang | null;
    languageList: Lang[] | null;
}

const TranslationContext = createContext<TranslationContextType>({
    t: () => {
        return 'no translations';
    },
    changeLang: () => {},
    lang: null,
    languageList: null,
});

function TranslationProvider(props: any) {
    const [lang, setLang] = useState<Lang | null>(null);
    const [defaultLang, setDefaultLang] = useState<Lang | null>(null);
    const [storageLang, setStorageLang] = useLocalStorage(STORAGE_KEYS.LANGUAGE, lang);
    const [dictionary, setDictionary] = useState<any>(null);

    const { data: languageList } = useSWR<Lang[]>(`${API_URL.LANGUAGE_LIST}`, fetcher, {
        onSuccess: (res: Lang[]) => {
            if (storageLang && res.some((x: Lang) => x.countryCode === storageLang.countryCode)) {
                setDefaultLang(storageLang);
            } else {
                setDefaultLang(res[0]);
            }
        },
    });

    const { data: languageTextList } = useSWR(
        lang && lang.countryCode
            ? `${API_URL.LANGUAGE_TEXT_LIST}?countryCode=${lang.countryCode}`
            : null,
        fetcher
    );

    // default lang
    useEffect(() => {
        if (lang && lang.countryCode) return;
        if (defaultLang) setLang(defaultLang);
    }, [languageList, defaultLang]);

    // Po změně jazyka načteme a nastavíme slovník
    useEffect(() => {
        if (!languageTextList) return;
        setDictionary(languageTextList);
        setStorageLang(lang);
    }, [languageTextList]);

    useEffect(() => {
        if (!lang || !lang.countryCode) return;
        const splitCountryCode = lang.countryCode?.split('-');
        if (splitCountryCode) moment.locale(splitCountryCode[0]);
    }, [lang]);

    useEffect(() => {
        // TODO: Defaultní datum by se měl brát z prohlížeče
        if (!storageLang) {
            setLang(defaultLang);
            return;
        }
        if (storageLang && storageLang.countryCode) {
            // pokud je nastaven localStorage na lang, který neexistuje nastav default
            if (languageList?.some((lang: Lang) => lang.countryCode === storageLang.countryCode)) {
                setLang(storageLang);
            } else {
                setLang(defaultLang);
            }
        } else {
            setLang(defaultLang);
        }
    }, [storageLang]);

    // Načtení hodnoty ze slovníku
    const t = (query: string) => {
        if (!dictionary) return;
        let current = dictionary;
        current = dictionary.find((phrase: any) => phrase.referenceText === query);
        if (current?.translatedText) return current.translatedText;
        else return `!${query}`;
    };

    // Změna jazyka
    const changeLang = (lang: Lang) => {
        setLang(lang);
    };

    return <TranslationContext.Provider value={{ t, changeLang, lang, languageList }} {...props} />;
}

const useTranslation = () => useContext<TranslationContextType>(TranslationContext);

export { TranslationProvider, useTranslation };
