import React, { useState, createContext, useContext, useEffect } from 'react';

interface NavigationContextType {
    navigationData: NavigationItem;
    setNavigationData: (NavigationItem) => void;
}

interface NavigationItem {
    currentPath: string;
}

const NavigationContext = createContext<NavigationContextType>({
    navigationData: { currentPath: '' },
    setNavigationData: () => {},
});
const useNavigation = () => useContext<NavigationContextType>(NavigationContext);

function NavigationProvider(props: any) {
    const [navigationData, setNavigationData] = useState({});

    return <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />;
}

function withNavigationWatcher(Component) {
    /* eslint-disable react/display-name */
    return function (props: any) {
        const { path } = props.match;
        const { setNavigationData } = useNavigation();

        useEffect(() => {
            setNavigationData({ currentPath: path });
        }, [path, setNavigationData]);

        return React.createElement(Component, props);
    };
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
