import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
        },
        container: {
            overflow: 'hidden',
            // maxHeight: '81vh',
        },
        actions: {
            width: '100px',
        },
        detail: {
            width: '40px',
        },
        addRow: {
            padding: '13.5px 16px',
        },
    })
);
