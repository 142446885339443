import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import { AuditType, CreateAuditType } from '../../types/AuditTypeApiResponse';
import { IForm } from '../../types/IForm';
import FieldText from '../../components/Form/FieldText';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { AuditTypeLayout } from '../../types/AuditTypeLayoutApiResponse';
import FieldSelect from '../../components/Form/FieldSelect';
import { useTranslation } from '../../contexts/translationContext';
import FieldCheckBox from '../../components/Form/FieldCheckBox';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    buttonTitle?: string;
    data?: AuditType | null;
    layoutList?: AuditTypeLayout[];
}

export const AuditTypeForm = ({
    onSubmit,
    data,
    layoutList,
    buttonTitle,
    userCanEdit,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateAuditType = {
        id: 0,
        name: '',
        dangerousFindings: false,
        loginRequired: true,
        observedPersonRequired: 0,
    };
    const [observedPersonRequiredDisabled, setObservedPersonRequiredDisabled] = useState(
        isNil(data) ? null : data.layoutId !== 1 && data.layoutId !== 2
    );

    return (
        <Formik
            enableReinitialize
            initialValues={
                isNil(data)
                    ? initialValues
                    : {
                          ...data,
                          observedPersonRequired:
                              data.observedPersonRequired === null
                                  ? 0
                                  : // @ts-ignore
                                  data.observedPersonRequired === true
                                  ? 2
                                  : 1,
                      }
            }
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                onSubmit({
                    ...values,
                    observedPersonRequired:
                        values.observedPersonRequired === 0 ||
                        (values.layoutId !== 1 && values.layoutId !== 2)
                            ? null
                            : values.observedPersonRequired === 2
                            ? true
                            : false,
                });
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.name}
                                label={t('Master-data.audit-types.detail.input-1')}
                                fullWidth
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.layoutId}
                                label={t('Master-data.audit-types.detail.input-2')}
                                fullWidth
                                component={FieldSelect}
                                onChange={(e: any) =>
                                    setObservedPersonRequiredDisabled(
                                        e.target.value !== 1 && e.target.value !== 2
                                    )
                                }
                            >
                                {layoutList &&
                                    layoutList.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.observedPersonRequired}
                                label={t('Master-data.audit-types.detail.observedPersonRequired')}
                                fullWidth
                                component={FieldSelect}
                                disabled={observedPersonRequiredDisabled}
                            >
                                <MenuItem key={0} value={0}>
                                    {t(
                                        'Master-data.audit-types.detail.observedPersonRequired-optionNone'
                                    )}
                                </MenuItem>
                                <MenuItem key={1} value={1}>
                                    {t(
                                        'Master-data.audit-types.detail.observedPersonRequired-optionOptional'
                                    )}
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    {t(
                                        'Master-data.audit-types.detail.observedPersonRequired-optionRequired'
                                    )}
                                </MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.dangerousFindings}
                                label={t('Master-data.audit-types.detail.input-3')}
                                fullWidth
                                component={FieldCheckBox}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.loginRequired}
                                label={t('Master-data.audit-types.detail.input-4')}
                                fullWidth
                                component={FieldCheckBox}
                            />
                        </Grid>
                        <SubmitButton
                            visible={userCanEdit}
                            goBackUrl={goBackUrl}
                            buttonTitle={buttonTitle}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
