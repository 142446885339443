import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../../containers/PageWrapper';
import { API_URL } from '../../../apiTypes';
import { fetcher, getItemApi } from '../../../apiUtils';
import useSWR from 'swr';
import { Zone } from '../../../types/ZoneApiResponse';
import { AuditQuestion } from '../../../types/AuditQuestionApiResponse';
import { Answer } from '../../../types/ChecklistAnswerApiResponse';
import { useResultSave } from '../Common/ResultSave';
import CircularStatic from '../../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import { appPaths } from '../../../paths';
import { useOnlineCheck } from '../../../hooks/useOnlineCheck';
import { QuestionCounter } from '../Common/Questions/QuestionCounter';
import { QuestionSummaryL3 } from './QuestionSummaryL3';
import '../Common/audit.scss';
import { AuditQuestionAnswer } from '../../../types/AuditQuestionAnswerApiResponse';
import { QuestionFormL3 } from '../../../form/questionsSurveyL3/QuestionsSurveyL3Form';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';
import { useTranslation } from '../../../contexts/translationContext';
import { AlertDialog } from '../../../components/AlertDialog';
import { useLoading } from '../../../contexts/loadingContext';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';
export interface AnswerAttachment {
    questionId: number;
    photo: any;
    isSummary?: boolean;
}

interface Props {
    zone: Zone;
    selectedAudit: any;
    observedPersonRequired?: AuditTypeObservedPersonForm;
}

/**
 * Otázky pro layout 3
 * @param param0
 * @returns
 */
export const QuestionsLayout_3 = ({ zone, selectedAudit, observedPersonRequired }: Props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isOnline, setOnline } = useOnlineCheck();
    const { startLoading, stopLoading } = useLoading();

    // vybraná otázka
    const [selectedQuestion, setSelectedQuestion] = useState<AuditQuestion>();
    // Filtrované otázky dle levelu
    const [questions, setQuestions] = useState<AuditQuestion[]>();
    // index v poli questions
    const [counter, setCounter] = useState<number>(0);
    // pole odpovědí
    const [answers, setAnswers] = useState<Answer[]>([]);

    const [questionsAnswers, setQuestionsAnswers] = useState<AuditQuestionAnswer[]>();
    // pole attachmentů s id odpovědi
    const [answersAttachments, setAnswersAttachments] = useState<AnswerAttachment[]>([]);
    // jestli jsou všechny otázky zodpovězeny, zobraz Dokončit form
    const [checklistFinished, setCheckListFinished] = useState<boolean>();
    // kliklo se na Vytvořit fotku -> skrýt vše mimo foťáku a preview předchozích fotek
    const [takingPicture, setTakingPicture] = useState<boolean>(false);
    const { handleResultSave, actualValue, isLoading } = useResultSave();
    // modal při kliku na Storno
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    // Načte otázky podle auditu
    const { data: allQuestions, isValidating: loadingQuestions } = useSWR<AuditQuestion[]>(
        `${API_URL.AUDIT_QUESTION_LIST}?parentId=${selectedAudit.auditId}`,
        fetcher,
        {
            onSuccess: (res) =>
                setQuestions(
                    res.filter(
                        (question: AuditQuestion) => question.level === selectedAudit?.auditLevel
                    )
                ),
        }
    );

    // Načte odpovědi pro otázky
    const getQuestionsAnswers = async (questions: AuditQuestion[]) => {
        if (!questions) return;
        let tmpAllAnswers: AuditQuestionAnswer[] = [];
        await Promise.all(
            questions.map(async (q: AuditQuestion) => {
                await getItemApi<AuditQuestionAnswer>({
                    url: API_URL.AUDIT_QUESTION_ANSWER_LIST,
                    params: { parentId: q.id },
                    callAfterSuccess: (res) => {
                        tmpAllAnswers = [...tmpAllAnswers, ...res.data];
                    },
                });
            })
        );
        setQuestionsAnswers(tmpAllAnswers);
    };

    useEffect(() => {
        if (!questions) return;
        getQuestionsAnswers(questions);
    }, [questions]);

    const handleCreateAttachments = (photos: any, isSummary?: boolean) => {
        // každou vyfocenou fotku uložit do attachments s id otázky u které se fotilo
        let _answerAttachments: AnswerAttachment[] = [...answersAttachments];
        photos.map((photo: any) => {
            let tmpAnswerAttachment = {
                questionId: selectedQuestion?.id,
                photo: photo,
                isSummary: isSummary,
            } as AnswerAttachment;
            _answerAttachments = [..._answerAttachments, tmpAnswerAttachment];
        });
        setAnswersAttachments(_answerAttachments);
    };

    // Uloží odpovědi, je nutné být online a připojen na VPN
    const handleSubmitData = async () => {
        if (window.navigator.onLine) {
            startLoading();
            getItemApi({
                url: `${API_URL.IS_AUTHENTICATED}`,
                params: {},
                callAfterSuccess: () => {
                    handleResultSave(
                        selectedAudit,
                        answers,
                        answersAttachments,
                        observedPersonRequired
                    );
                },
                callAfterError: () => {
                    setOnline(false);
                },
            });
        } else {
            stopLoading();
            setOnline(false);
        }
    };

    // Uloží otázku
    const onSubmitAnswer = (
        answer: AuditQuestionAnswer,
        isDangerous: boolean,
        comment?: string,
        isSummary?: boolean
    ) => {
        if ((!selectedQuestion || !questions) && !isSummary) return;
        // Pokud už nejsou otázky, zobrazíme summary
        if (!checklistFinished && questions && counter === questions.length - 1) {
            setCheckListFinished(true);
        }

        let tmpAnswer: Answer = {
            ordering: selectedQuestion?.ordering.toString()
                ? selectedQuestion?.ordering.toString()
                : '0',
            questionText: selectedQuestion?.questionText
                ? selectedQuestion?.questionText
                : '__WEEKLY_TOPIC__',
            typeId: selectedQuestion?.typeId ? selectedQuestion?.typeId : 0,
            correctAnswer: 'None',
            answer: 'None',
            answerCustomText: answer.answerText ? answer.answerText : '',
            answerScore: answer.answerScore ? answer.answerScore : 0,
            comment: '',
            questionTypeId: '',
            questionTypeName: '',
            id: selectedQuestion?.id ? selectedQuestion?.id : 0,
            isDangerous: selectedAudit.auditType.dangerousFindings ? isDangerous : null,
        };
        if (comment) tmpAnswer.comment = comment;
        setAnswers([...answers, tmpAnswer]);
        if (!isSummary) setCounter(counter + 1);
    };

    // Když dojde k posunu na další ozká, nebo při initu, vybereme otázku ze tásobníku
    useEffect(() => {
        if (!questions) return;
        if (counter <= questions.length) setSelectedQuestion(questions[counter]);
    }, [counter, questions]);

    return (
        <>
            <Box>
                <Header paddingTop={10} paddingBottom={20}>
                    {/* Počítadlo otázek, nezobrazujeme, když se fotí a na konci */}
                    {!checklistFinished && !takingPicture && !!questions && (
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <QuestionCounter current={counter + 1} total={questions.length} />
                        </Grid>
                    )}
                </Header>
                {questions &&
                    questionsAnswers &&
                    selectedQuestion &&
                    selectedAudit &&
                    zone &&
                    !checklistFinished && (
                        <QuestionFormL3
                            onSubmit={onSubmitAnswer}
                            question={selectedQuestion}
                            questionsAnswers={questionsAnswers}
                            setTakingPicture={setTakingPicture}
                            takingPicture={takingPicture}
                            handleCreateAttachments={handleCreateAttachments}
                            setOpenAlert={setOpenAlert}
                            dangerousFindings={selectedAudit.auditType.dangerousFindings}
                        />
                    )}

                {/* Finální obrazovku - souhrn */}
                {checklistFinished && (
                    <>
                        <QuestionSummaryL3
                            showOfflineIndicator={!isOnline}
                            onFinish={handleSubmitData}
                            handleCreateAttachments={handleCreateAttachments}
                            answers={answers}
                            takingPicture={takingPicture}
                            onSubmit={onSubmitAnswer}
                            setTakingPicture={setTakingPicture}
                            boolParam1={selectedAudit?.boolParam1}
                            setOpenAlert={setOpenAlert}
                        />
                        {/* Indikátor, že dochází k ukládání */}
                        {questions && isLoading && (
                            <Content bottomOffset={30} textAlign={'center'}>
                                <CircularStatic
                                    totalValue={questions?.length}
                                    actualValue={actualValue + 1}
                                    onFinish={() => history.push(appPaths.AUDIT)}
                                />
                            </Content>
                        )}
                    </>
                )}
            </Box>
            <AlertDialog
                open={openAlert}
                title={t('Audit.layout.modal.unsaved-data')}
                content={t('Audit.layout.modal.unsaved-data-confirm')}
                handleClose={() => {
                    setOpenAlert(false);
                }}
                btn1={{ title: t('Audit.layout.modal.option-no') }}
                btn2={{ title: t('Audit.layout.modal.option-yes') }}
                handleConfirm={() => history.push(appPaths.AUDIT)}
            />
        </>
    );
};
