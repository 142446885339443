import { Box, Collapse, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { MenuItem } from '../../menu';
import { MenuItemLink } from '../Menu';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    menuItem: MenuItem;
    children?: any;
    open?: boolean;
}

export const ListItemCollapsible = ({ menuItem, open }: Props) => {
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(open ?? false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    if (
        isAccessibleByUserRoleAndAdminPrivilege === null ||
        isAccessibleByUserRoleAndAdminPrivilege === undefined
    )
        return <Loader />;

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>{menuItem.icon && <menuItem.icon color="primary" />}</ListItemIcon>
                <Typography color="primary" variant="subtitle1">
                    {t(menuItem.title)}
                </Typography>
                <Box px={5} />
                {isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" mountOnEnter>
                <List component="div">
                    {menuItem.children &&
                        menuItem.children
                            /* .filter((i) =>
                                isAccessibleByUserRoleAndAdminPrivilege(i.restrictedAccess)
                            ) */
                            .map((c, index) => <MenuItemLink key={index} index={index} item={c} />)}
                </List>
            </Collapse>
        </>
    );
};
