import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Footer } from '../../../pages/AuditSurvey/Common/Layout/Footer';
import { Button } from 'reactstrap';
import '../Common/audit.scss';
import { useTranslation } from '../../../contexts/translationContext';
import { useStyles } from '../../../pages/AuditSurvey/Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import ClearIcon from '@material-ui/icons/Clear';
import 'react-html5-camera-photo/build/css/index.css';
import Grid from '@mui/material/Grid';
import { appPaths } from '../../../paths';
import { useHistory } from 'react-router-dom';
import { cFieldNames, validationSchema } from './schema';
import { Field, Form, Formik } from 'formik';
import { Header } from '../Common/Layout/Header';
import { Loader } from '../../../components/Loader/Loader';
import { API_URL } from '../../../apiTypes';
import { postApi, postApiFormData } from '../../../apiUtils';

interface Props {
    selectedAudit: any;
    auditorNetId: string;
}

export const QuestionsLayout_5 = ({ selectedAudit, auditorNetId }: Props) => {
    // *** External hooks ***
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    // *** Local states ***
    const [takingPicture, setTakingPicture] = useState(true);
    const [pictures, setPictures] = useState<any[]>([]);
    const [comment, setComment] = useState<string>('');
    const [isLoading, setLoading] = useState(false);

    // *** Local functions ***
    const reloadCamera = useCallback(() => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    }, []);

    const handleTakePhoto = useCallback(
        (dataUri: any) => {
            setPictures([dataUri, ...pictures]);
        },
        [pictures]
    );

    const createCheckList = useCallback(async () => {
        return postApi({
            url: API_URL.CHECK_LIST_CREATE,
            data: {
                auditId: selectedAudit.auditId,
                auditorNetId: auditorNetId,
                auditLevel: 'Level1',
            },
        });
    }, []);

    const createAnswer = useCallback(
        async (checkListId: number, commentValue: string) => {
            return postApi({
                url: API_URL.CHECK_LIST_ANSWER_CREATE,
                data: {
                    checklistId: checkListId,
                    ordering: 0,
                    questionText: 'None',
                    correctAnswer: 'None',
                    answer: 'None',
                    comment: commentValue,
                    isDangerous: true,
                },
            });
        },
        [comment]
    );

    const createAnswerAttachment = useCallback(async (answerId: number, picture: any) => {
        const formData = new FormData();
        let blob = await fetch(picture).then((res) => res.blob());
        const myFile = new File([blob], 'image.png', {
            type: blob.type,
        });
        formData.append('file', myFile);

        return postApiFormData({
            url: `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD}?answerId=${answerId}`,
            data: formData,
        });
    }, []);

    const setCheckListFinished = useCallback(
        async (checkListId: number, callAfterSuccess: any, callAfterError: any) => {
            return postApi({
                url: `${API_URL.CHECK_LIST_IS_FINISHED}?id=${checkListId}`,
                callAfterSuccess,
                callAfterError,
            });
        },
        []
    );

    const handleFormSubmit = useCallback(
        async (formData) => {
            setLoading(true);

            const resultCheckList: any = await createCheckList();
            if (!resultCheckList) {
                setLoading(false);
                return;
            }

            const resultAnswer: any = await createAnswer(resultCheckList.id, formData.comment);
            if (!resultAnswer) {
                setLoading(false);
                return;
            }

            const resultPictuires: any = await Promise.all(
                pictures.map(async (picture: any) => {
                    await createAnswerAttachment(resultAnswer.id, picture);
                })
            );

            if (!resultPictuires) {
                setLoading(false);
                return;
            }

            await setCheckListFinished(
                resultCheckList.id,
                () => {
                    history.push(appPaths.AUDIT);
                },
                () => {
                    setLoading(false);
                }
            );
        },
        [pictures]
    );

    return (
        <Box>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography variant="h1" align="center" className={classes.labelText}>
                    {selectedAudit.auditType.name}
                </Typography>
            </Header>
            {!isLoading && (
                <Formik
                    initialValues={{ comment: '' }}
                    validationSchema={validationSchema(t)}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <Grid
                                container
                                alignContent={'center'}
                                alignItems={'center'}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                {takingPicture && (
                                    <Grid item xs={12}>
                                        <Camera
                                            onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                                            isImageMirror={false}
                                        />
                                        <Button
                                            onClick={() => reloadCamera()}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-reload-camera')}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            whiteSpace: 'nowrap',
                                            overflowX: 'auto',
                                        }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div>
                                                {pictures.map((image: any, index: number) => (
                                                    <div className={classes.image} key={index}>
                                                        <ClearIcon
                                                            className={classes.btnDelete}
                                                            onClick={() => {
                                                                setPictures([
                                                                    ...pictures.slice(0, index),
                                                                    ...pictures.slice(
                                                                        index + 1,
                                                                        pictures.length
                                                                    ),
                                                                ]);
                                                            }}
                                                        />
                                                        <img
                                                            className={classes.imgPreview}
                                                            src={image}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ padding: 10 }}>
                                    <Field
                                        name={cFieldNames.comment}
                                        label={t('Audit.layout.input-comment-required')}
                                        placeholder={t('Audit.layout.input-comment-required')}
                                        rows={3}
                                        onChange={(c: any) => setComment(c.target.value)}
                                        component={'textarea'}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>

                            <Footer>
                                <Box sx={{ display: 'flex' }}>
                                    <Button
                                        onClick={() => history.push(appPaths.AUDIT)}
                                        variant="contained"
                                        className={classes.navButton}
                                        type="submit"
                                    >
                                        {t('Storno')}
                                    </Button>
                                    <Button
                                        variant="outline"
                                        className={classes.navButton}
                                        // onClick={() => setIsFinishing(true)}
                                        disabled={pictures.length === 0 || comment.trim() === ''}
                                        type="submit"
                                    >
                                        {t('Save-data')}
                                    </Button>
                                </Box>
                            </Footer>
                        </Form>
                    )}
                </Formik>
            )}

            {isLoading && <Loader />}
        </Box>
    );
};
