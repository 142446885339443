import { makeStyles } from '@material-ui/core';

const orange = '#ea8503';
const white = '#fff';
const black = '#000';
const red = '#ff0000';

export const useStylesL4 = makeStyles(() => ({
    labelText: {
        fontSize: '1.5rem',
        color: orange,
        textAlign: 'center',
    },
    listItemText: {
        fontSize: '1.5rem',
        color: white,
        textAlign: 'left',
    },

    formSelect: {
        width: '80%',
        backgroundColor: '#fff',
        height: '50px',
        fontSize: '1.2rem',
    },

    navButton: {
        fontSize: '1.2rem',
        width: '100%',
        minHeight: '50px',
        height: '100%',
        borderRadius: 0,
        backgroundColor: orange,
        border: '1px solid #fff',
        textTransform: 'uppercase',
        color: white,
        '&:hover': {
            backgroundColor: orange,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
        '&:visited': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
    },
}));
