import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { Zone } from '../../types/ZoneApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { appPaths } from '../../paths';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import StickyHeadTable from '../../components/Table/Table';
import { AlertDialog } from '../../components/AlertDialog';
import { ZoneRouteParams } from './ZoneNewEdit';
import { AuditApiResponse } from '../../types/AuditApiResponse';
import { Columns } from './Columns';
import { ZoneDetailFormContainer } from '../../containers/ZoneDetailForm';
import { UserRoles } from '../../types/applicationUser.types';
import { identity } from 'ramda';
import { CopyZoneQuestionsModal } from '../../components/Modal';
import { ZoneLeaderDetail } from '../ZoneLeader/ZoneLeaderDetail';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

export const ZoneDetail = () => {
    const { t } = useTranslation();
    const stepNames = useStepNames();
    const { id, vsmsId, productionId } = useParams<ZoneRouteParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const history = useHistory();
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const {
        data,
        error,
        mutate: mutateAudits,
    } = useSWR<AuditApiResponse>(`${API_URL.AUDIT_LIST}?parentId=${numericId}`, fetcher);

    const {
        data: prod,
        error: productionError,
        mutate: mutateProd,
    } = useSWR<ProductionApiResponse>(
        `${API_URL.PRODUCTION_LIST}?parentId=${numericVsmsId}`,
        fetcher
    );
    const {
        data: zoneDetail,
        error: zoneError,
        mutate: zoneMutate,
    } = useSWR<Zone>(`${API_URL.ZONES_ITEM}?id=${numericId}`, fetcher);

    const onDelete = () => {
        mutateAudits();
        zoneMutate();
        mutateProd();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDIT_DELETE,
            onDelete,
        });

    const tableItemRedirect = ({ id: itemId }) =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${numericId}/audit/${itemId}`
        );
    const handleAddItem = () =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${productionId}/zone/${numericId}/audit/new/0`
        );

    if (!zoneDetail || !data || !prod) return <Loader />;

    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}`;

    return (
        <PageWrapper error={error || zoneError || productionError}>
            <HeaderPage
                heading={zoneDetail.name}
                stepName={t('Master-data.hierarchy.detail.production.zone.main-headline')}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 6 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 4 },
                    {
                        name: t('Master-data.hierarchy.detail.production.main-headline'),
                        pathToCutCount: 2,
                    },
                ]}
                buttonSectionPermission={[UserRoles.Admin]}
                buttonSection={
                    <Grid container spacing={1}>
                        <Button variant="contained" color="primary" onClick={handleAddItem}>
                            {t('Master-data.hierarchy.detail.production.zone.add-audit')}
                        </Button>
                        <Box mx={1} />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenCopyModal(true)}
                        >
                            {t('Master-data.hierarchy.detail.production.zone.copy-questions')}
                        </Button>
                    </Grid>
                }
            />
            <CopyZoneQuestionsModal
                zoneId={numericId}
                refreshZone={onDelete}
                handleClose={() => setOpenCopyModal(false)}
                handleConfirm={identity}
                open={openCopyModal}
            />
            <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                    <ZoneDetailFormContainer
                        mutate={zoneMutate}
                        id={numericId}
                        productionId={numericProductionId}
                        productions={prod}
                        data={zoneDetail}
                        editPermission={[UserRoles.Admin]}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
                <Grid item md={8} xs={12} container spacing={5} direction="column">
                    <Grid item>
                        <StickyHeadTable
                            title={t(
                                'Master-data.hierarchy.detail.production.zone.table-1-headline'
                            )}
                            rows={data}
                            columns={Columns(t)}
                            handleDetailItem={tableItemRedirect}
                            handleDeleteItem={handleDeleteAttempt}
                            handleAddItem={handleAddItem}
                            addItemAndDeletePermissions={[UserRoles.Admin]}
                        />
                    </Grid>
                    <Grid item>
                        <ZoneLeaderDetail />
                    </Grid>
                    <AlertDialog
                        open={openAlert}
                        title={t('Delete')}
                        content={t(
                            'Master-data.hierarchy.detail.production.zone.audit.message-delete-confirm'
                        )}
                        handleClose={handleAlertClose}
                        handleConfirm={handleAlertConfirm}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
