import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        marginRight: 0,
    },
    editorClassName: {
        border: '1px solid #F1F1F1',
        minHeight: '200px',
    },
}));
