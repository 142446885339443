import { Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FieldText from '../../components/Form/FieldText';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { useTranslation } from '../../contexts/translationContext';
import { cFieldNamesPwd, validationSchemaPwd } from './schema';
interface Props {
    onSubmit: SubmitHandler<any>;
}

export function LocalUserPasswordChangeForm({ onSubmit }: Props) {
    const { t } = useTranslation();
    const initialValues = {
        passwordChange: '',
        passwordChange1: '',
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchemaPwd(t)}
                onSubmit={(values) => {
                    onSubmit(values.passwordChange);
                }}
            >
                {({ handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    name={cFieldNamesPwd.passwordChange}
                                    type="password"
                                    label={t('Local-users.detail.input-4')}
                                    component={FieldText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    name={cFieldNamesPwd.passwordChange1}
                                    type="password"
                                    label={t('Local-users.detail.input-5')}
                                    component={FieldText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton
                                    buttonTitle={t('Local-users.detail.button-change-password')}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
}
