import { useHistory } from 'react-router';
import { ItemWithId } from '../apiTypes';

export function useDetailRoutes<T extends ItemWithId>(
    baseRoute: string,
    route: string,
    detailSuffix?: string
) {
    const history = useHistory();

    const handleEditItem = (item: T) => {
        history.push(`${baseRoute}${route}/${item.id}`);
    };

    const handleAddItem = () => {
        history.push(`${baseRoute}${route}${detailSuffix ? `/${detailSuffix}/` : '/'}0`);
    };

    const handleDetailItem = (item: T) => {
        history.push(`${baseRoute}${route}${detailSuffix ? `/${detailSuffix}/` : '/'}${item.id}`);
    };

    return {
        handleAddItem,
        handleEditItem,
        handleDetailItem,
    };
}
