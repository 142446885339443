export const useStepNames = (): Record<string, string> => ({
    HOME: 'Home',
    KPIGROUP: 'Kpi Group',
    KPIITEM: 'Kpi Item',
    VALUE: 'Value',
    ACTION: 'Action',
    ACTIONLIST: 'Action List',
    MASTERDATA: 'Hlavní data',
    VSMS: 'VSMS',
    NEW_ZONE: 'Nová zóna',
    NEW_AUDIT: 'Nový audit',
    NEW_AUDITOR: 'Nový auditor',
    NEW_LEADER: 'Nový vedoucí',
    NEW_VSM: 'Nový VSM',
    ZONE: ' Zone',
    AUDIT: 'Audit',
    LEADER: 'Vedoucí',
    PRODUCTION: 'Produkce',
    NEW_PRODUCTION: 'Nová produkce',
    QUESTION: 'Otázka',

    NEW_QUESTION: 'Nová otázka',
    ANSWER: 'Odpověď',
    NEW_QUESTION_ANSWER: 'Nová odpověď',
    QUESTION_TYPE: 'Typ otázky',
    NEW_QUESTION_TYPE: 'Nový typ otázky',
    NEW_AUDIT_TYPE: 'Nový typ auditu',
    NEW_AUDIT_TYPE_LAYOUT: 'Nový layout typu auditu',
    AUDIT_TYPE: 'Typ auditu',
    AUDIT_TYPE_LAYOUT: 'Layout typu auditu',
    USER: 'User',
    PERMISSIONS: 'Oprávnění',
    PERMISSION_ADD: 'Nová oprávnění',
    NOTIFICATION: 'Notification',
    NOTIFICATION_ADD: 'Add Notification',
    TASKS: 'Tasks',
    TASK: 'Úkol',
    LOCAL_USERS: 'Uživatelé',
    NEW_LOCAL_USER: 'Nový uživatel',
    EDIT_LOCAL_USER: 'Editovat uživatele',
    LANGUAGES: 'Jazyky',
    NEW_LANGUAGE: 'Nový jazyk',
    EDIT_LANGUAGE: 'Upravit jazyk',
    LANGUAGE_TEXTS: 'Texty jazyků',
    EDIT_LANGUAGE_TEXT: 'Upravit text',
});
