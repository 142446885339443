import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { CreateVsms } from '../../types/VsmApiResponse';
import { putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { UserRoles } from '../../types/applicationUser.types';
import { AuditForm, IAuditForm } from '../../form/audits';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
interface Props extends IAuditForm {
    mutate: () => void;
    editPermission?: Array<UserRoles>;
}

export const AuditDetailFormContainer = ({
    data,
    zoneId,
    zones,
    auditTypes,
    mutate,
    editPermission,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editPermission);
    const edit = async (values: CreateVsms) => {
        if (!editPermission) return;
        await putApi({
            url: API_URL.AUDIT_UPDATE,
            data: values,
            callAfterSuccess: () => {
                mutate();
                NotificationManager.success(t('Notify.item-updated'));
            },
        });
    };

    return (
        <Paper>
            <Box p={3}>
                <AuditForm
                    onSubmit={edit}
                    data={data}
                    buttonTitle={t('Edit')}
                    auditTypes={auditTypes}
                    zoneId={zoneId}
                    zones={zones}
                    userCanEdit={userCanEdit}
                    goBackUrl={goBackUrl}
                />
            </Box>
        </Paper>
    );
};
