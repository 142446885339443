import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { AuditQuestion } from '../../types/AuditQuestionApiResponse';
import { cFieldNames, validationSchema } from './schema';
import { useStyles } from '../../pages/AuditSurvey/Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Alert } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import { AnswerValue } from '../../types/ChecklistAnswerApiResponse';
import InfoIcon from '@material-ui/icons/Info';
import { DescriptionModal } from '../../pages/AuditSurvey/Common/DescriptionModal';
import { useAuditBtnPos } from '../../hooks/useAuditBtnPos';
import { useTranslation } from '../../contexts/translationContext';
import { Content } from '../../pages/AuditSurvey/Common/Layout/Content';
import { Footer } from '../../pages/AuditSurvey/Common/Layout/Footer';

interface Props {
    question: AuditQuestion;
    onSubmit: (answer: AnswerValue, isDangerous: boolean, comment?: string) => void;
    isWrongAnswer: boolean;
    setIsWrongAnswer: any;
    handleCreateAttachments: any;
    takingPicture: boolean;
    setTakingPicture: any;
    dangerousFindings: boolean;
}

const initialValues: any = {
    comment: '',
};

export const QuestionForm = ({
    question,
    onSubmit,
    isWrongAnswer,
    setIsWrongAnswer,
    handleCreateAttachments,
    takingPicture,
    setTakingPicture,
    dangerousFindings,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [openDescription, setOpenDescription] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');

    const [photoRequiredError, setPhotoRequiredError] = useState<boolean>();
    const [commentRequiredError, setCommentRequiredError] = useState<boolean>();
    const [imageCapture, setImageCapture] = useState<any[]>([]);
    const [tmpAnswer, setTmpAnswer] = useState<AnswerValue>();
    const [isDangerous, setIsDangerous] = useState<boolean>(false);

    const handleOpenModal = (description: string | null) => {
        if (description) {
            setModalText(description);
            setOpenDescription(true);
        }
    };

    const handleCloseModal = () => {
        setModalText('');
        setOpenDescription(false);
    };

    const handleTakePhoto = (dataUri) => {
        setImageCapture([dataUri, ...imageCapture]);
        setTakingPicture(false);
    };

    useEffect(() => {
        if (!question.photoRequiredForWrongAnswer) return;
        if (imageCapture.length > 0) setPhotoRequiredError(false);
    }, [imageCapture]);

    // Pokud je odpověď negativní - vyprázdníme seznam fotek
    useEffect(() => {
        setImageCapture([]);
    }, [isWrongAnswer]);

    const handleFormSubmit = (formData) => {
        if (!tmpAnswer) return;
        let isError = false;
        // Je fotografie povinná? Pokud není vybrána, zobrazíme error
        if (question.photoRequiredForWrongAnswer && imageCapture.length < 1) {
            isError = true;
            setPhotoRequiredError(true);
        }

        // Komentář je povinný
        if (formData.comment === '') {
            isError = true;
            setCommentRequiredError(true);
        }

        if (isError) return;

        // Validace je ok. Nastavíme přílohy, potvrdíme.
        handleCreateAttachments(imageCapture);
        setImageCapture([]);
        setTakingPicture(false);
        onSubmit(tmpAnswer, isDangerous, formData.comment);
    };

    // reload kamery - po vypnutí browseru telefonu a znovu zapnutí je občas černá obrazovka
    const reloadCamera = () => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    };

    if (isWrongAnswer)
        // Negativní odpověď, možnost pořídit fotografii a zapsat komentář
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t)}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <Content bottomOffset={60}>
                                <Grid
                                    container
                                    alignContent={'center'}
                                    alignItems={'center'}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {!takingPicture && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.questionText}
                                                >
                                                    {question?.questionText}
                                                </Typography>
                                                {question.description && (
                                                    <InfoIcon
                                                        className={classes.questionIcon}
                                                        onClick={() =>
                                                            handleOpenModal(question.description)
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h2"
                                                    className={classes.labelText}
                                                    style={{ marginTop: '10px' }}
                                                >
                                                    {t('Audit.layout.label-your-answer')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                <Typography
                                                    variant="h2"
                                                    className={classes.subHeading}
                                                >
                                                    {tmpAnswer === AnswerValue.YES &&
                                                        t('Audit.layout.answer-option-yes')}
                                                    {tmpAnswer === AnswerValue.NO &&
                                                        t('Audit.layout.answer-option-no')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                lg={12}
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Field
                                                    name={cFieldNames.comment}
                                                    label={t('Audit.layout.input-comment-required')}
                                                    placeholder={t(
                                                        'Audit.layout.input-comment-required'
                                                    )}
                                                    rows={4}
                                                    onChange={(c) =>
                                                        c.target.value.length > 0
                                                            ? setCommentRequiredError(false)
                                                            : setCommentRequiredError(true)
                                                    }
                                                    component={'textarea'}
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        {!takingPicture && (
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={() => setTakingPicture(true)}
                                                    variant="contained"
                                                    className={classes.formButton}
                                                >
                                                    {t('Audit.layout.btn-create-photo')}
                                                </Button>
                                            </Grid>
                                        )}
                                        {takingPicture && (
                                            <>
                                                <Camera
                                                    onTakePhoto={(dataUri) => {
                                                        handleTakePhoto(dataUri);
                                                    }}
                                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                    isImageMirror={false}
                                                />
                                                <Button
                                                    onClick={() => reloadCamera()}
                                                    variant="contained"
                                                    className={classes.formButton}
                                                    style={{ margin: 5, width: 'fit-content' }}
                                                >
                                                    {t('Audit.layout.btn-reload-camera')}
                                                </Button>
                                            </>
                                        )}
                                        <div
                                            style={{
                                                width: '90vw',
                                                margin: '0 auto',
                                                whiteSpace: 'nowrap',
                                                overflowX: 'auto',
                                            }}
                                        >
                                            <div style={{ width: '100%' }}>
                                                <div>
                                                    {imageCapture.map(
                                                        (image: any, index: number) => (
                                                            <div
                                                                className={classes.image}
                                                                key={index}
                                                            >
                                                                <ClearIcon
                                                                    className={classes.btnDelete}
                                                                    onClick={() => {
                                                                        setImageCapture([
                                                                            ...imageCapture.slice(
                                                                                0,
                                                                                index
                                                                            ),
                                                                            ...imageCapture.slice(
                                                                                index + 1,
                                                                                imageCapture.length
                                                                            ),
                                                                        ]);
                                                                    }}
                                                                />
                                                                <img
                                                                    className={classes.imgPreview}
                                                                    src={image}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    {photoRequiredError && !takingPicture && (
                                        <Grid item xs={12}>
                                            <Alert severity="error" style={{ margin: '5px 0' }}>
                                                {t('Audit.validation.photo-required')}
                                            </Alert>
                                        </Grid>
                                    )}
                                    {commentRequiredError && !takingPicture && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                {t('Audit.validation.comment-required')}
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Content>
                            <Footer>
                                {dangerousFindings && !takingPicture ? (
                                    <>
                                        <Box sx={{ display: 'flex', marginBottom: 20 }}>
                                            <Button
                                                variant="outline"
                                                className={classes.navButton}
                                                onClick={() => setIsWrongAnswer(false)}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Button
                                                variant="outline"
                                                className={classes.navButton}
                                                type="submit"
                                                onClick={() => {
                                                    setIsDangerous(false);
                                                }}
                                            >
                                                {t(
                                                    'Audit.layout.L4.next-finding-not-dangerous-findings'
                                                )}
                                            </Button>
                                            <Button
                                                variant="outline"
                                                className={`${classes.navButton} dangerous`}
                                                type="submit"
                                                onClick={() => {
                                                    setIsDangerous(true);
                                                }}
                                            >
                                                {t(
                                                    'Audit.layout.L4.next-finding-dangerous-findings'
                                                )}
                                            </Button>
                                        </Box>
                                    </>
                                ) : (
                                    <Box sx={{ display: 'flex' }}>
                                        {!takingPicture && (
                                            <>
                                                <Button
                                                    variant="outline"
                                                    className={classes.navButton}
                                                    onClick={() => setIsWrongAnswer(false)}
                                                >
                                                    {t('Back')}
                                                </Button>
                                                <Button
                                                    variant="outline"
                                                    className={classes.navButton}
                                                    type="submit"
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                )}
                            </Footer>
                        </Form>
                    )}
                </Formik>

                <DescriptionModal
                    text={modalText}
                    open={openDescription}
                    onClose={() => handleCloseModal()}
                />
            </>
        );

    // Odpověď ano/ne
    return (
        <>
            <Content bottomOffset={60}>
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Grid item xs={12} style={{ marginTop: '0px', minHeight: 130 }}>
                        <Typography variant="subtitle1" className={classes.questionText}>
                            {question?.questionText}
                        </Typography>
                        {question.description !== null && (
                            <InfoIcon
                                className={classes.questionIcon}
                                onClick={() => handleOpenModal(question.description)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Content>
            <Footer>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        onClick={() => {
                            if (question.correctAnswer === AnswerValue.NO)
                                onSubmit(AnswerValue.NO, false);
                            else setIsWrongAnswer(true);
                            setTmpAnswer(AnswerValue.NO);
                        }}
                        variant="contained"
                        className={classes.formButton}
                    >
                        {t('Audit.layout.answer-option-no')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (question.correctAnswer === AnswerValue.YES)
                                onSubmit(AnswerValue.YES, false);
                            else setIsWrongAnswer(true);
                            setTmpAnswer(AnswerValue.YES);
                        }}
                        className={classes.formButton}
                    >
                        {t('Audit.layout.answer-option-yes')}
                    </Button>
                </Box>
            </Footer>
            <DescriptionModal
                text={modalText}
                open={openDescription}
                onClose={() => handleCloseModal()}
            />
        </>
    );
};
