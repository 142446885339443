import React from 'react';
import { createContext, useContext, useState } from 'react';

export enum Language {
    EN = 'en-US',
    CS = 'cs-CZ',
}

export type ILanguageContext = {
    language: Language;
};

const LanguageProvider = (props: any) => {
    const [language, setLanguage] = useState<Language>(Language.EN);

    return (
        <LanguageContext.Provider
            value={{
                language,
            }}
            {...props}
        />
    );
};

const LanguageContext = createContext<ILanguageContext>({ language: Language.EN });
const useLanguageContext = () => useContext<ILanguageContext>(LanguageContext);

export { LanguageProvider, useLanguageContext };
