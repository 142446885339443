// @ts-ignore
import { createTheme } from '@material-ui/core';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1d3c45',
            light: '#9593f6',
        },
        secondary: {
            main: '#d2601a',
            light: '#2e2ca0',
        },
    },
    overrides: {
        MuiButton: {
            outlined: {
                paddingTop: 9,
                paddingBottom: 9,
            },
            contained: {
                paddingTop: 9,
                paddingBottom: 9,
            },
        },
        MuiTableCell: {
            root: {
                padding: 12,
            },
        },
    },
    typography: {
        h1: {
            fontSize: '1.5rem',
            fontWeight: 600,
        },
        h2: {
            fontSize: '1.3rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        subtitle1: {
            fontSize: '1.1rem',
        },
        subtitle2: {
            fontSize: '1.1rem',
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '1rem',
        },
        caption: {
            fontSize: '0.9rem',
        },
        button: {
            fontSize: '0.9rem',
            fontWeight: 600,
        },
    },
});
