import React from 'react';
import { auditRoutes } from './appRoutes';
import { Route } from 'react-router-dom';

export function AppAuditContent() {
    return (
        <>
            {auditRoutes.map(({ path, component }, index) => (
                <Route exact key={index} path={path} component={component} />
            ))}
        </>
    );
}
