import React from 'react';

interface Props {
    children: any;
    paddingTop?: number;
    paddingBottom?: number;
}

export const Header = ({ children, paddingTop, paddingBottom }: Props) => {
    return (
        <div
            style={{
                backgroundColor: 'black',
                //backgroundColor: 'green',
                paddingTop: `${paddingTop || 10}px`,
                paddingBottom: `${paddingBottom || 0}px`,
            }}
        >
            {children}
        </div>
    );
};
