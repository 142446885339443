import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    id: 'id',
    level: 'level',
    ordering: 'ordering',
    questionText: 'questionText',
    correctAnswer: 'correctAnswer',
    photoRequiredForWrongAnswer: 'photoRequiredForWrongAnswer',
    parentAuditId: 'parentAuditId',
    typeId: 'typeId',
    description: 'description',
    intParam1: 'intParam1',
};

export const questionAnswers = ['None', 'Yes', 'No', 'CannotBeAnswered'];
export const questionAnswersOptions = questionAnswers.map((q) => ({ id: q, name: q }));

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.parentAuditId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0))
            .required(t(cFormValidationMessages.required)),
        [cFieldNames.typeId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0))
            .nullable()
            .required(t(cFormValidationMessages.required)),
        [cFieldNames.questionText]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.level]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.description]: yup.string().nullable(),
        [cFieldNames.intParam1]: yup.number().nullable(),
    });
};
