import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    username: 'username',
    password: 'password',
    persist: 'persist',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.username]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.password]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
