import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../../containers/PageWrapper';
import { API_URL } from '../../../apiTypes';
import { fetcher } from '../../../apiUtils';
import useSWR from 'swr';
import { Button } from 'reactstrap';
import { useAppUser } from '../../../hooks/useAppUser';
import { Audit } from '../../../types/AuditApiResponse';
import { AuditTypeSurvey } from '../../../types/AuditTypeApiResponse';
import { useStyles } from '../Common/styles';
import '../Common/audit.scss';
import { SelectGembaL4 } from './SelectGembaL4';
import { SelectGroupL4 } from './SelectGroupL4';
import { QuestionsLayout_4 } from './QuestionsLayout_4';
import { useTranslation } from '../../../contexts/translationContext';
import { Footer } from '../Common/Layout/Footer';
import { Content } from '../Common/Layout/Content';
import { Field, Form, Formik } from 'formik';
import { cFieldNames, validationSchema } from './schema';
import { UserSearchField } from '../../../components/Form/UserSearchField';

interface Props {
    auditType: AuditTypeSurvey;
}

export enum GembaType {
    Weekly = 'Weekly',
    Extraordinary = 'Extraordinary',
}

export enum GembaTypeTranslated {
    Weekly = 'Audit.layout.L4.gemba-type-1',
    Extraordinary = 'Audit.layout.L4.gemba-type-2',
}

export const AuditLayout_4 = ({ auditType }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { appUser } = useAppUser();

    // States pro nastavení pozice buttonů
    /*const lastButtonRef = useRef<any>(null);
     const { buttonsContainerStyle, setButtonsContainerStyle, setCalcButtonsPos } = useAuditBtnPos({
        lastElementRef: lastButtonRef,
    }); */
    const [userNetId, setUserNetId] = useState<string | null>(appUser ? appUser.netId : null);

    const [zoneGroups, setZoneGroups] = useState<number[]>();
    const [selectedZoneGroup, setSelectedZoneGroup] = useState<number>();

    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    const [selectedGemba, setSelectedGemba] = useState<GembaType | undefined>(undefined);
    const [filteredZones, setFilteredZones] = useState<any[]>();

    // Seznam auditů pro daného usera
    const { data: auditsForUser, isValidating: loadingAudits } = useSWR<Audit[]>(
        userNetId
            ? `${API_URL.AUDIT_LIST_FOR_USER}?auditorNetId=${userNetId}&auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    // Random zóna
    const { data: randomGroup } = useSWR<number>(
        selectedGemba === GembaType.Weekly && userNetId
            ? `${API_URL.AUDIT_GET_RANDOM_GROUP}?auditorNetId=${userNetId}&auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    // refresh seznamu při změně skupiny
    useEffect(() => {
        if (!auditsForUser || !selectedGemba) return;
        if (selectedGemba === GembaType.Weekly && randomGroup && !selectedZoneGroup) {
            setSelectedZoneGroup(randomGroup);
        }
        getFilteredZoneList();
    }, [randomGroup, selectedZoneGroup, selectedGemba]);

    const getFilteredZoneList = () => {
        if (!auditsForUser || !selectedGemba) return;
        let tmpFilteredZones: any[] = [];
        auditsForUser.map((audit: any) => {
            if (selectedGemba === GembaType.Weekly && selectedZoneGroup) {
                if (selectedZoneGroup === audit.intParam1) {
                    if (tmpFilteredZones.findIndex((zone: any) => zone.id === audit.zone.id) === -1)
                        tmpFilteredZones = [
                            ...tmpFilteredZones,
                            { ...audit.zone, intParam1: audit.intParam1, auditId: audit.auditId },
                        ];
                }
            } else if (selectedGemba === GembaType.Extraordinary) {
                if (tmpFilteredZones.findIndex((zone: any) => zone.id === audit.zone.id) === -1)
                    tmpFilteredZones = [
                        ...tmpFilteredZones,
                        { ...audit.zone, intParam1: audit.intParam1, auditId: audit.auditId },
                    ];
            }
        });
        setFilteredZones(tmpFilteredZones);
    };

    if (showSurvey && filteredZones && selectedGemba && userNetId)
        return (
            <QuestionsLayout_4
                zoneList={filteredZones}
                gembaType={selectedGemba}
                auditsForUser={auditsForUser}
                dangerousFindings={auditType.dangerousFindings}
            />
        );

    return (
        <Box>
            {!userNetId && (
                <Content bottomOffset={30}>
                    <Grid
                        container
                        alignContent={'center'}
                        alignItems={'center'}
                        style={{ textAlign: 'center' }}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={{ netId: null }}
                                validationSchema={validationSchema(t)}
                                onSubmit={(values) => setUserNetId(values.netId)}
                            >
                                {({ handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Typography
                                                    align="center"
                                                    className={classes.labelText}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {t('Audit.select-user')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    name={cFieldNames.netId}
                                                    component={UserSearchField}
                                                    className={classes.formSelect}
                                                    disableLabel
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="outline"
                                                    className={classes.navButton}
                                                    type="submit"
                                                    style={{ width: '200px' }}
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Content>
            )}

            {/* výběr typu Gemby - Weekly (po výběru zvolit skupinu) / Extraordinary (po výběru rovnou fotit) */}
            {!selectedGemba && userNetId && (
                <SelectGembaL4 setSelectedGemba={setSelectedGemba} setShowSurvey={setShowSurvey} />
            )}

            {/* když Weekly - Výběr skupiny */}
            {selectedZoneGroup &&
                selectedGemba &&
                selectedGemba === GembaType.Weekly &&
                userNetId && (
                    <SelectGroupL4
                        filteredZones={filteredZones}
                        auditsForUser={auditsForUser}
                        selectedZoneGroup={selectedZoneGroup}
                        zoneGroups={zoneGroups}
                        setZoneGroups={setZoneGroups}
                        setSelectedZoneGroup={setSelectedZoneGroup}
                    />
                )}

            {showSurvey && !userNetId && (
                <Footer>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="outline"
                            className={classes.navButton}
                            onClick={() => {
                                if (!selectedGemba) history.goBack();
                                if (selectedGemba) setSelectedGemba(undefined);
                            }}
                        >
                            {t('Back')}
                        </Button>
                    </Box>
                </Footer>
            )}

            {!showSurvey && (
                <Footer>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="outline"
                            className={classes.navButton}
                            onClick={() => {
                                if (!selectedGemba) history.goBack();
                                if (selectedGemba) setSelectedGemba(undefined);
                            }}
                        >
                            {t('Back')}
                        </Button>
                        {selectedZoneGroup && selectedGemba && userNetId && (
                            <Button
                                variant="outline"
                                className={classes.navButton}
                                onClick={() => setShowSurvey(true)}
                            >
                                {t('Photo')}
                            </Button>
                        )}
                    </Box>
                </Footer>
            )}
        </Box>
    );
};
