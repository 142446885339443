// @ts-ignore
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getListApi } from '../apiUtils';
import TextField from './Form/TextField';
import { useStyles } from './Form/TextField/styles';
import { useTranslation } from '../contexts/translationContext';

interface Props<T> {
    savedValue: T | null;
    label?: string;
    listUrl: string;
    paramName: string;
    className?: string;
    getOptionLabel: (option: T) => string;
    getOptionSelected: (option: T, value: T) => boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange: (event: object, value: T | null, reason: string) => void;
}

export function AutocompleteAsync<T>({
    savedValue,
    label,
    listUrl,
    paramName,
    className,
    getOptionLabel,
    getOptionSelected,
    onChange,
    ...props
}: Props<T>) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [options, setOptions] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);

    const defaultNoOptions = t('Autocomplete.start-writing');
    const [noOptions, setNoOptions] = useState(defaultNoOptions);

    useEffect(() => {
        const getItems = async () => {
            setLoading(true);

            const params = {};
            params[paramName] = debouncedValue;

            const result = await getListApi<T>({
                url: listUrl,
                params,
            });
            if (result) {
                setOptions(result);
            }
            if (!result || result.length === 0) {
                setNoOptions(t('Autocomplete.no-results'));
            }

            setLoading(false);
        };

        if (debouncedValue.length > 0) {
            getItems();
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (value.length === 0) {
            setNoOptions(defaultNoOptions);
            setOptions([]);
        }

        const timeoutId = setTimeout(() => setDebouncedValue(value), 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [defaultNoOptions, value]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setValue('');
        }
    }, [open]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            size="medium"
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            options={options}
            renderOption={(option: any) => <span>{`${option.name} (${option.netId})`}</span>}
            loading={loading}
            noOptionsText={noOptions}
            classes={{
                root: classes.input,
            }}
            // @ts-ignore
            value={savedValue}
            // @ts-ignore
            onChange={onChange}
            closeText={t('Autocomplete.close')}
            clearText={t('Autocomplete.clear')}
            openText={t('Autocomplete.open')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    onChange={(event) => setValue(event.target.value)}
                    className={className}
                    InputProps={{
                        ...params.InputProps,
                        classes: {
                            root: '0',
                            input: classes.input,
                        },
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
