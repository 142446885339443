import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    id: 'id',
    name: 'name',
    email: 'email',
    password: 'password',
    password1: 'password1',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.id]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.name]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.email]: yup.string(),
        [cFieldNames.password]: yup.string().required(t(cFormValidationMessages.required)),
        password1: yup
            .string()
            .oneOf([yup.ref('password'), null], t(cFormValidationMessages.passwordsDoNotMatch)),
    });
};

export const validationSchemaEdit = (t: any) => {
    return yup.object().shape({
        [cFieldNames.name]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.email]: yup.string(),
    });
};

export const cFieldNamesPwd = {
    passwordChange: 'passwordChange',
    passwordChange1: 'passwordChange1',
};

export const validationSchemaPwd = (t: any) => {
    return yup.object().shape({
        [cFieldNamesPwd.passwordChange]: yup.string().required(t(cFormValidationMessages.required)),
        passwordChange1: yup
            .string()
            .oneOf(
                [yup.ref('passwordChange'), null],
                t(cFormValidationMessages.passwordsDoNotMatch)
            ),
    });
};
