import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import FieldSelect from '../../components/Form/FieldSelect';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { Auditor, CreateAuditor } from '../../types/AuditorApiResponse';
import { UserSearchField } from '../../components/Form/UserSearchField';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { useTranslation } from '../../contexts/translationContext';

export interface IAuditorForm extends IForm {
    data?: Auditor | null;
    auditId: number;
}
interface Props extends IAuditorForm {
    onSubmit: (values: any) => void;
    buttonTitle: string;
}

export const AuditorForm = ({ onSubmit, data, auditId, userCanEdit, goBackUrl }: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateAuditor = {
        id: 0,
        auditLevel: '',
        parentAuditId: auditId,
        netId: '',
    };
    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                userCanEdit && onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.auditLevel}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.auditor.input-1'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                            >
                                <MenuItem key="Level1" value="Level1">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.auditor.input-1-option-1'
                                    )}
                                </MenuItem>
                                <MenuItem key="Level2" value="Level2">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.auditor.input-1-option-2'
                                    )}
                                </MenuItem>
                                <MenuItem key="Level3" value="Level3">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.auditor.input-1-option-3'
                                    )}
                                </MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.netId}
                                label="Net id"
                                fullWidth
                                component={UserSearchField}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <SubmitButton visible={userCanEdit} goBackUrl={goBackUrl} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
