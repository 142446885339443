import { makeStyles } from '@material-ui/core/styles';

export const green = '#00ff00';
export const red = '#ff0000';

export const useStyles = makeStyles(() => ({
    mainHeader: {
        padding: 10,
    },
    checkboxFilter: {
        paddingLeft: 20,
    },

    sectionHeadline: {
        padding: '5px 10px 0 10px',
        fontSize: '1.5rem',
    },
    numberSection: {
        fontSize: '2.5rem',
        textDecoration: 'bold',
        textAlign: 'center',
    },
    numberSectionHeadline: {
        margin: 5,
        fontSize: '1.2rem',
        textDecoration: 'bold',
        textAlign: 'center',
    },
    tableHeader: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    noEvaluation: {
        borderTop: '1px solid #000',
        textAlign: 'center',
        padding: 0,
        maxHeight: '25%',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'stretch',
        flexFlow: 'column',
        justifyContent: 'spaceAround',
    },
    flexItem: { flexGrow: 0 },
    flexItemStretch: { flexGrow: 4 },
    carouselMain: {
        height: '100%',
        paddingTop: 0,
        button: {
            backgroundColor: 'red',
        },
    },
    carouselNavButtonsDef: {
        borderRadius: 0,
        '&[aria-label="Next"]': {
            right: '33vw',
        },
    },
    carouselNavButtonsDefWrapper: {
        bottom: '0',
        top: 'unset',
    },
    isOk: {
        backgroundColor: green,
    },
    isNotOk: {
        backgroundColor: red,
    },
}));
