// @ts-ignore
import React from 'react';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { FieldInputProps } from 'formik';
import { useStyles } from '../styles';

interface FieldCheckBoxInterface {
    field: FieldInputProps<any>;
    form: object;
    label: string | undefined;
}

const FieldCheckBox = ({ field, form, label, ...props }: FieldCheckBoxInterface) => {
    const classes = useStyles();

    return (
        <FormControlLabel
            control={<Checkbox color="primary" checked={field.value} {...field} {...props} />}
            label={label}
            classes={{
                root: classes.root,
            }}
        />
    );
};

export default FieldCheckBox;
