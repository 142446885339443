import React from 'react';
import Paper from '@material-ui/core/Paper';

interface Props {
    children: any;
}

export const Footer = ({ children }: Props) => {
    return (
        <Paper
            style={{ backgroundColor: 'black', position: 'fixed', bottom: 0, left: 0, right: 0 }}
            elevation={3}
        >
            {children}
        </Paper>
    );
};
