import { useHistory, useLocation, useParams } from 'react-router';
import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { LanguageText } from '../Language/types';
import { LanguageTextForm } from '../../form/languageText/LanguageTextForm';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
/* import { LanguageText } from '../LanguageText/types';
import { LanguageTextForm } from '../../form/languageText/LanguageTextForm'; */

export interface RouteParams {
    countryCode: string;
}

interface StateProps {
    state: {
        languageText: LanguageText;
    };
}

export const LanguageTextNewEdit = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { countryCode } = useParams<RouteParams>();
    const { state: location }: { state: StateProps } = useLocation();
    const { languageText } = location.state;

    const onSubmit = (values) => {
        putApi({
            url: API_URL.LANGUAGE_TEXT_UPDATE,
            data: values,
            callAfterSuccess: () => {
                NotificationManager.success(t('Notify.item-updated'));
                history.push(`${appPaths.LANGUAGE}/${countryCode}`);
            },
        });
    };

    const goBackUrl = `${appPaths.LANGUAGE}/${countryCode}`;
    return (
        <PageWrapper>
            <HeaderPage
                heading={t('Languages.detail.edit.headline')}
                stepName={t('Languages.detail.edit.headline')}
                breadCrumbSteps={[{ name: t('Languages.main-headline'), pathToCutCount: 3 }]}
                noPaper
                noPy
            />

            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Paper>
                        <Box p={3}>
                            <LanguageTextForm
                                onSubmit={onSubmit}
                                data={languageText}
                                goBackUrl={goBackUrl}
                                buttonTitle={t('Save')}
                                t={t}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
