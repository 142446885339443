import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ReportColumn } from '../../components/Reports/ReportColumn';
import { Report, ReportAttachments, ReportLevel } from '../../types/ReportsTypes';
import { CarouselReport } from '../../components/Carousel';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    report?: Report;
    fullScreen: boolean;
}

export const ReportsColumnsContainer: FC<Props> = ({ report, fullScreen }) => {
    const { t } = useTranslation();
    const [selectedCheckListId, setSelectedCheckListId] = useState<number | undefined>(undefined);

    const handleSelectedCheckListId = (id: number) => {
        setSelectedCheckListId(id);
    };

    const attachments: ReportAttachments | null = useMemo(() => {
        if (!report) return null;
        if (!selectedCheckListId) return report.attachments;
        return report.attachments.filter((x) => x.checklistId === selectedCheckListId);
    }, [report, selectedCheckListId]);

    return (
        <Grid container spacing={2} className="report" color={'white'}>
            <Grid container item md={7} spacing={2}>
                <Grid container item md={12} justifyContent="center">
                    {!fullScreen && <Typography variant="h4">Audit</Typography>}
                </Grid>
                {report && (
                    <Grid container item md={12} spacing={1}>
                        <ReportColumn
                            title={t('Report.panel-1.headline')}
                            items={report.level1}
                            attachements={report.attachments}
                            onSelectItem={handleSelectedCheckListId}
                            selectedCheckListId={selectedCheckListId || undefined}
                        />
                        <ReportColumn
                            title={t('Report.panel-2.headline')}
                            items={report.level2}
                            attachements={report.attachments}
                            onSelectItem={handleSelectedCheckListId}
                            selectedCheckListId={selectedCheckListId || undefined}
                        />
                        <ReportColumn
                            title={t('Report.panel-3.headline')}
                            items={report.level3}
                            attachements={report.attachments}
                            onSelectItem={handleSelectedCheckListId}
                            selectedCheckListId={selectedCheckListId || undefined}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item md={5}>
                <Grid container item md={12} justifyContent="flex-end">
                    {report?.production && (
                        <Typography variant="body2">{report.production.name}</Typography>
                    )}
                </Grid>
                <Grid container item md={12} justifyContent="center">
                    {!fullScreen && <Typography variant="h4">Nalezené nesrovnalosti</Typography>}
                </Grid>
                <Box mt={6}>
                    {report && attachments && (
                        <CarouselReport attachments={attachments} fullScreen={fullScreen} />
                    )}
                    {selectedCheckListId ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setSelectedCheckListId(undefined)}
                        >
                            Zrušit filtr neshody
                        </Button>
                    ) : (
                        <></>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
