import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../apiUtils';
import { ErrorPage } from '../../components/Snackbar';
import { API_URL } from '../../apiTypes';
import { PageWrapper } from '../../containers/PageWrapper';
import { useHistory } from 'react-router';
import { appPaths } from '../../paths';
import StickyHeadTable from '../../components/Table/Table';
import { LanguageText, LanguageTextApiResponse } from '../Language/types';
import { UserRoles } from '../../types/applicationUser.types';
import { Columns } from './Columns';
import { Loader } from '../../components/Loader/Loader';
import TextField from '../../components/Form/TextField';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Toolbar } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { useAuthContext } from '../../contexts/AuthContext';

interface Props {
    countryCode: string;
}

export function LanguageTextList({ countryCode }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appUser } = useAuthContext();
    const isReadOnly = appUser?.roles.includes(UserRoles.Admin) ? false : true;
    const history = useHistory();
    const [languageTextList, setLanguageTextList] = useState<any[]>();
    const [filter, setFilter] = useState<string>('');
    const { data, error } = useSWR<LanguageTextApiResponse>(
        countryCode ? `${API_URL.LANGUAGE_TEXT_LIST}?countryCode=${countryCode}` : null,
        fetcher
    );

    const handleEditItem = ({ id }) => {
        if (languageTextList) {
            history.push(
                `${appPaths.LANGUAGE_TEXT}/${countryCode}/${languageTextList[id].referenceText}`,
                {
                    state: {
                        languageText: languageTextList[id],
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (!data) return;
        let tmpLanguageTextList: LanguageText[] = [];
        let filteredData = data.filter(
            (f: LanguageText) =>
                f.referenceText?.toUpperCase()?.includes(filter.toUpperCase()) ||
                f.translatedText?.toUpperCase()?.includes(filter.toUpperCase()) ||
                f.comment?.toUpperCase()?.includes(filter.toUpperCase())
        );
        filteredData.map((langText: LanguageText, index: number) => {
            tmpLanguageTextList = [
                ...tmpLanguageTextList,
                {
                    id: index,
                    languageCountryCode: langText.languageCountryCode,
                    referenceText: langText.referenceText,
                    translatedText: langText.translatedText,
                    comment: langText.comment,
                },
            ];
        });
        setLanguageTextList(tmpLanguageTextList);
    }, [data, filter]);

    if (error) return <ErrorPage error={error} />;
    if (!languageTextList) return <Loader />;

    return (
        <PageWrapper loading={!data || !languageTextList}>
            <Grid container>
                <Grid item xs={6}>
                    <Toolbar>
                        <Typography variant="h6" id="tableTitle" color="secondary" component="div">
                            {t('Languages.detail.table-headline')}
                        </Typography>
                    </Toolbar>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="text"
                        placeholder={t('Languages.detail.table-search-input')}
                        className={classes.searchButton}
                        onChange={(event: any) => setFilter(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StickyHeadTable
                        columns={Columns(t)}
                        rows={languageTextList}
                        handleEditItem={isReadOnly ? undefined : handleEditItem}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
}
