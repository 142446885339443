import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { SwrError } from '../apiUtils';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AccessForbidden = () => <Alert severity="warning">Access Forbidden</Alert>;

export const FailedToLoad = () => <Alert severity="error">Failed to load</Alert>;

interface Props {
    error: SwrError;
}

export const ErrorPage: React.FC<Props> = ({ error }) => {
    switch (error.status) {
        case 403:
            return <AccessForbidden />;
        default:
            return <FailedToLoad />;
    }
};
