import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { ProductionForm } from '../../form/production';
import { CreateVsms, VsmApiResponse } from '../../types/VsmApiResponse';
import { putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { Production } from '../../types/ProductionApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { IForm } from '../../types/IForm';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
interface Props extends IForm {
    vsmsId: number;
    id: number;
    data: Production | null;
    vsms: VsmApiResponse | null;
    mutate: () => void;
    editPermission?: Array<UserRoles>;
}
export const ProductionDetailFormContainer = ({
    vsmsId,
    id,
    data,
    vsms,
    mutate,
    editPermission,
    goBackUrl,
}: Props) => {
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const { t } = useTranslation();
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editPermission);
    const editProduction = async (values: CreateVsms) => {
        if (!userCanEdit) return;
        await putApi({
            url: API_URL.PRODUCTION_UPDATE,
            data: values,
            callAfterSuccess: () => {
                mutate();
                NotificationManager.success(t('Notify.item-updated'));
            },
        });
    };
    return (
        <Paper>
            <Box p={3}>
                <ProductionForm
                    onSubmit={editProduction}
                    vsms={vsms}
                    data={data}
                    buttonTitle={t('Edit')}
                    vsmsId={vsmsId}
                    userCanEdit={userCanEdit}
                    goBackUrl={goBackUrl}
                />
            </Box>
        </Paper>
    );
};
