// @ts-ignore
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FieldInputProps } from 'formik';
import TextField from '../TextField';
import { EndAddorment } from '../TextField/TextField';

interface FieldSelectInterface {
    field: FieldInputProps<any>;
    form: {
        touched: any;
        errors: any;
    };
    fullWidth: boolean | undefined;
    onChange?: (e: any) => void;
    loading?: boolean;
}

const FieldSelect = ({
    field,
    form: { touched, errors },
    fullWidth,
    onChange,
    loading,
    ...props
}: FieldSelectInterface) => {
    const hasError = () => {
        if (touched[field.name] && errors[field.name]) {
            return true;
        }
    };

    return (
        <FormControl fullWidth={fullWidth}>
            <TextField
                {...field}
                {...props}
                select
                variant="outlined"
                fullWidth
                endAddorment={loading ? EndAddorment.LOADING : null}
                helperText={touched[field.name] && errors[field.name] && errors[field.name]}
                error={hasError()}
                onChange={(e) => {
                    field.onChange(e);
                    onChange && onChange(e);
                }}
            />
        </FormControl>
    );
};

export default FieldSelect;
