import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../../pages/AuditSurvey/Common/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    data: any;
}
// TODO zobecnit, css dát mimo
export const QuestionFormL3SummaryTable = ({ data }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="h2"
                className={classes.labelText}
                style={{ color: '#fff', margin: '10px' }}
            >
                {t('Audit.layout.L3.table-headline')}
            </Typography>
            <TableContainer style={{ border: '1px solid #fff' }}>
                <Table aria-label="simple table">
                    <TableBody
                        style={{
                            backgroundColor: '#ea8503',
                        }}
                    >
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                        color: '#fff',
                                        border: '2px solid #fff',
                                    }}
                                >
                                    {row.questionText}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    style={{
                                        color: '#fff',
                                        border: '2px solid #fff',
                                        backgroundColor: '#0066a1',
                                    }}
                                >
                                    {row.answerScore}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
