import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL } from '../apiTypes';
import { getItemApi, postApi } from '../apiUtils';
import { appPaths } from '../paths';
import { UserRoles } from '../types/applicationUser.types';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from './translationContext';
import { ContextRoutes } from '../containers/Router/RouterContainer';
export interface AppUser {
    id: string;
    netId: string;
    name: string;
    email: string;
    roles: string[];
}

export type IAuthContext = {
    appUser: AppUser | null;
    isLoading: boolean;
    signIn?: (
        username: string,
        password: string,
        persist: boolean,
        prefix: string,
        localHistory: any,
        t: any
    ) => void;
    signOut?: () => void;
    isAccessibleByUserRoleAndAdminPrivilege?: (roles?: UserRoles[]) => boolean;
};

const AuthProvider = (props: any) => {
    const history = useHistory();
    const [appUser, setAppUser] = useState<AppUser | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    // Calls login API. Sets user state if success
    const signIn = (
        username: string,
        password: string,
        persist: boolean,
        prefix: string,
        localHistory: any,
        t: any
    ) => {
        setLoading(true);
        postApi({
            url: `${API_URL.LOGIN}?username=${username}&password=${password}&persist=${persist}`,
            callAfterSuccess: (result) => {
                if (prefix === ContextRoutes.AuditMobile) localHistory.push(appPaths.AUDIT);

                setAppUser(result.data.user);
                setLoading(false);
                localHistory.push(appPaths.TASKS);
                NotificationManager.success(t('Notify.request-successful'));
            },
            callAfterError: () => {
                setLoading(false);
                NotificationManager.error(t('Login-page.login-failed'));
            },
        });
    };

    // Calss logout api. Sets user state to null.
    const signOut = () => {
        setLoading(true);
        getItemApi({
            url: `${API_URL.LOGOUT}`,
            params: {},
            callAfterSuccess: () => {
                setAppUser(null);
                sessionStorage.removeItem('appUser');
                setLoading(false);
            },
            callAfterError: () => setLoading(false),
        });
    };

    // Rights check
    const isAccessibleByUserRoleAndAdminPrivilege = (roles) => {
        if (!appUser) return true;

        // Admin has always rights
        if (appUser.roles.includes(UserRoles.Admin)) return true;

        if (!roles) return false;
        if (roles.toString().includes(UserRoles.Any)) return true;
        // Others roles check
        if (appUser.roles.some((role) => roles.toString().includes(role))) return true;
        return false;
    };

    // Checks if user is auth
    useEffect(() => {
        setLoading(true);
        getItemApi({
            url: `${API_URL.IS_AUTHENTICATED}`,
            params: {},
            callAfterSuccess: (result) => {
                if (!result.data) {
                    setLoading(false);
                    return;
                }

                // Uživatel je přihlášen, načteme si jeho info
                getItemApi({
                    url: `${API_URL.GET_CURRENT_USER}`,
                    params: {},
                    callAfterSuccess: (result) => {
                        setAppUser(result.data);
                        setLoading(false);
                        history.push(appPaths.TASKS);
                    },
                    callAfterError: () => setLoading(false),
                });
            },
            callAfterError: () => setLoading(false),
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{ appUser, isLoading, signIn, signOut, isAccessibleByUserRoleAndAdminPrivilege }}
            {...props}
        />
    );
};

const AuthContext = createContext<IAuthContext>({ isLoading: false, appUser: null });
const useAuthContext = () => useContext<IAuthContext>(AuthContext);

export { AuthProvider, useAuthContext };
