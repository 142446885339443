import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from '../../../contexts/translationContext';
import { useStyles } from './styles';
import LanguageIcon from '@material-ui/icons/Language';
import { Header } from './Layout/Header';
import { Content } from './Layout/Content';
import { Footer } from './Layout/Footer';
import { LanguageChanger } from '../../../components/LanguageChanger/LanguageChanger';
import { Button } from 'reactstrap';
import useSWR from 'swr';
import { fetcher } from '../../../apiUtils';
import { AuditTypeSurvey, AuditTypeSurveyApiResponse } from '../../../types/AuditTypeApiResponse';
import { API_URL } from '../../../apiTypes';
import { useAppUser } from '../../../hooks/useAppUser';
import { useHistory } from 'react-router';
import { appPaths } from '../../../paths';

/**
 * Hlavní komponenta mobilních auditů.
 * @returns
 */
export const AuditSurvey = () => {
    const { t } = useTranslation();
    const { appUser } = useAppUser();
    const history = useHistory();
    const classes = useStyles();

    const [languageOpen, setLanguageOpen] = useState<boolean>(false);

    // Načte povolené audity podle uživatele
    const { data: auditTypes, isValidating: loadingAudits } = useSWR<AuditTypeSurveyApiResponse>(
        `${API_URL.AUDIT_TYPE_ALLOWED_AUDITS}?netId=${appUser?.netId}`,
        fetcher
    );

    // Přesměruje na layout konkrétního vybraného auditu
    const onSubmit = (auditType: AuditTypeSurvey) => {
        history.push(appPaths.AUDIT_ZONE, {
            state: { auditType: auditType, dangerousFindings: auditType.dangerousFindings },
        });
    };

    const auditTypesList = auditTypes?.map((auditType: AuditTypeSurvey) => (
        <Button
            key={auditType.id}
            variant="contained"
            onClick={() => onSubmit(auditType)}
            className={classes.formButton}
            style={{ marginBottom: '20px', marginTop: '20px' }}
        >
            {auditType.name}
        </Button>
    ));

    return (
        <>
            <Box>
                <Header>
                    <Typography
                        variant="subtitle1"
                        className={classes.questionIcon}
                        style={{ textAlign: 'right' }}
                        onClick={() => setLanguageOpen(true)}
                    >
                        <LanguageIcon style={{ margin: '10px 30px 10px 0px', fontSize: '35px' }} />
                    </Typography>
                    <Typography align="center" className={classes.labelText}>
                        {t('Audit.main-headline')}
                    </Typography>
                </Header>

                <Content bottomOffset={30}>
                    <div style={{ textAlign: 'center' }}>{auditTypesList}</div>
                </Content>

                <Footer>
                    <Button
                        variant="outline"
                        className={classes.navButton}
                        onClick={() => history.push('/')}
                    >
                        {t('Exit')}
                    </Button>
                </Footer>
            </Box>
            <LanguageChanger isOpen={languageOpen} onClose={() => setLanguageOpen(false)} />
        </>
    );
};
