import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { PageWrapper } from '../../containers/PageWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { AuditQuestionAnswer } from '../../types/AuditQuestionAnswerApiResponse';
import { QuestionAnswersForm } from '../../form/questionsAnswers';
import { QuestionRouterParams } from '../AuditQuestion/AuditQuestionNewEdit';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';

export interface AnswerRouterParams extends QuestionRouterParams {
    questionId: string;
}

export const AuditQuestionAnswersNewEdit = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);
    const { id, vsmsId, productionId, zoneId, auditId, questionId } =
        useParams<AnswerRouterParams>();
    const numericId = Number(id);
    const history = useHistory();
    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${auditId}/question/${questionId}`;

    const { data: answer, mutate: answerMutate } = useSWR<AuditQuestionAnswer>(
        numericId !== 0 ? `${API_URL.AUDIT_QUESTION_ANSWER_ITEM}?id=${numericId}` : null,
        fetcher
    );

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi({
                    url: API_URL.AUDIT_QUESTION_ANSWER_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(goBackUrl);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDIT_QUESTION_ANSWER_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    return (
        <PageWrapper>
            <HeaderPage
                stepName={numericId === 0 ? stepNames.NEW_QUESTION_ANSWER : stepNames.ANSWER}
                breadCrumbSteps={[
                    { name: stepNames.MASTERDATA, pathToCutCount: 11 },
                    { name: stepNames.VSMS, pathToCutCount: 9 },
                    { name: stepNames.PRODUCTION, pathToCutCount: 7 },
                    { name: stepNames.ZONE, pathToCutCount: 2 },
                    { name: stepNames.AUDIT, pathToCutCount: 2 },
                    { name: stepNames.QUESTION, pathToCutCount: 3 },
                ]}
                noPaper
                noMb
                buttonSectionPermission={editRoles}
            />

            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                    <Paper>
                        <Box p={3}>
                            <QuestionAnswersForm
                                data={answer}
                                auditQuestionId={Number(questionId)}
                                onSubmit={submit}
                                buttonTitle="Uložit"
                                userCanEdit={userCanEdit}
                                goBackUrl={goBackUrl}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
