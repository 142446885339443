export interface CreateLanguage {
    countryCode: string;
    name: string;
}

export interface Language extends CreateLanguage {
    [key: string]: string | number | null;
}

export const languageInputEmpty: CreateLanguage = {
    countryCode: '',
    name: '',
};

export interface UpdateLanguageText {
    languageCountryCode: string;
    referenceText: string;
    translatedText: string;
    comment: string;
}
export interface LanguageText extends UpdateLanguageText {
    [key: string]: string | number | null;
}

export const languageTextInputEmpty: UpdateLanguageText = {
    comment: '',
    languageCountryCode: '',
    referenceText: '',
    translatedText: '',
};

export type LanguageApiResponse = Array<Language>;
export type LanguageTextApiResponse = Array<LanguageText>;
