import { Field, Form, Formik } from 'formik';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { equals } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import { CreateVsms, VsmApiResponse } from '../../types/VsmApiResponse';
import { Production } from '../../types/ProductionApiResponse';
import { IForm } from '../../types/IForm';
import { IZoneCopyForm } from '../../types/ZoneCopy';
import FieldSelect from '../../components/Form/FieldSelect';
import { ZoneApiResponse } from '../../types/ZoneApiResponse';
import Button from '@material-ui/core/Button';
import React, { useEffect, useRef, useState } from 'react';
import FieldCheckBox from '../../components/Form/FieldCheckBox';
import { Audit, AuditApiResponse } from '../../types/AuditApiResponse';
import { useTranslation } from '../../contexts/translationContext';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: CreateVsms | null;
    buttonTitle: string;
    productions?: Production[] | null;
    zones?: ZoneApiResponse | null;
    productionId: number;
    vsms?: VsmApiResponse | null;
    onVsmChange: (id: number) => void;
    onProductionChange: (id: number) => void;
    onZoneChange: (id: number) => void;
    audits?: AuditApiResponse | null;
    allZones?: ZoneApiResponse | null;
    zoneId: number;
    productionLoading?: boolean;
    vsmsLoading?: boolean;
    zoneLoading?: boolean;
    auditLoading?: boolean;
    closeModal?: (e: any) => void;
}

export const ZoneCopyForm = ({
    onSubmit,
    productions,
    userCanEdit,
    vsms,
    onProductionChange,
    onVsmChange,
    onZoneChange,
    audits,
    zones,
    allZones,
    zoneId,
    productionLoading,
    zoneLoading,
    vsmsLoading,
    auditLoading,
    closeModal,
}: Props) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    const [auditList, setAuditList] = useState<any>();
    const initialValues: IZoneCopyForm = {
        zoneOriginId: 0,
        zoneDestinationId: zoneId,
        copyAuditors: false,
        vmsId: 0,
        productionId: 0,
        auditId: 0,
    };

    useEffect(() => {
        if (!audits) return;
        let tmpAuditList: any[] = [];
        tmpAuditList = [
            {
                id: 0,
                auditTypeName: t(
                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-4-all'
                ),
                auditTypeId: 0,
                parentZoneId: 0,
                parentZoneName: 0,
            } as any,
            ...audits,
        ];
        setAuditList(tmpAuditList);
    }, [audits]);

    return (
        <Formik
            innerRef={ref}
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                if (values.auditId === 0) values.auditId = undefined;
                onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.vmsId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-1'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                                onChange={(e) => onVsmChange(e.target.value)}
                                loading={vsmsLoading}
                            >
                                {vsms &&
                                    vsms.map((v) => (
                                        <MenuItem key={v.id} value={v.id}>
                                            {v.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.productionId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-2'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                                onChange={(e) => onProductionChange(e.target.value)}
                                loading={productionLoading}
                            >
                                {productions &&
                                    productions.map((p) => (
                                        <MenuItem key={p.id} value={p.id}>
                                            {p.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.zoneOriginId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-3'
                                )}
                                fullWidth
                                component={FieldSelect}
                                onChange={(e) => onZoneChange(e.target.value)}
                                disabled={!userCanEdit}
                                loading={zoneLoading}
                            >
                                {zones &&
                                    zones.map((p) => (
                                        <MenuItem key={p.id} value={p.id}>
                                            {p.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.auditId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-4'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                                loading={auditLoading}
                            >
                                {auditList &&
                                    auditList.map((a) => (
                                        <MenuItem key={a.id} value={a.id}>
                                            {a.auditTypeName}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.zoneDestinationId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-5'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                                // onChange={(e) => onProductionChange(e.target.value)}
                            >
                                {allZones &&
                                    allZones.map((z) => (
                                        <MenuItem key={z.id} value={z.id}>
                                            {equals(z.id, zoneId)
                                                ? `${z.name} ${t(
                                                      'Master-data.hierarchy.detail.production.zone.modal-copy-input-5-current'
                                                  )}`
                                                : z.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.copyAuditors}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.modal-copy-input-6'
                                )}
                                component={FieldCheckBox}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        {userCanEdit && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Box mt={1}>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent="flex-end"
                                        alignItems={'center'}
                                    >
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                onClick={closeModal}
                                                variant={'outlined'}
                                            >
                                                {t('Close')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                type={'submit'}
                                                variant={'contained'}
                                            >
                                                {t('Confirm')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
