import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles } from './Icons.styles';

export const Success = () => {
    const classes = useStyles();
    return <CheckCircleIcon classes={{ root: classes.success }} />;
};

export const Warning = () => {
    const classes = useStyles();
    return <WarningIcon classes={{ root: classes.warning }} />;
};

export const Failure = () => {
    const classes = useStyles();
    return <ErrorIcon classes={{ root: classes.failure }} />;
};
