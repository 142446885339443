import { Theme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { ContextRoutes } from '../../containers/Router/RouterContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from '../../contexts/translationContext';
import { LoginForm } from '../../form/login/LoginForm';
import { appPaths } from '../../paths';
import { NotificationManager } from 'react-notifications';
import { Lang } from '../../types/core/lang.types';

export function LoginPage() {
    const { t, changeLang, lang, languageList } = useTranslation();
    const [selectedLang, setSelectedLang] = useState<Lang | null>(lang);

    const { signIn, isLoading } = useAuthContext();
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!selectedLang || !languageList) return;
        if (selectedLang === lang) return;
        changeLang(selectedLang);
    }, [selectedLang]);

    useEffect(() => {
        if (!lang) return;
        setSelectedLang(lang);
    }, [lang]);

    const onSubmit = (username: string, password: string, persist: boolean) => {
        if (!signIn) return;
        signIn(username, encodeURIComponent(password), persist, pathname.split('/')[1], history, t);
    };
    if (!signIn || isLoading || !languageList || !lang || !selectedLang) return <Loader />;

    return (
        <LoginForm
            onSubmit={onSubmit}
            isMobileView={isMobileView}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            languageList={languageList}
            t={t}
        />
    );
}
