import { PermissionsList } from './pages/Permissions/PermissionsList';
import { TasksList } from './pages/Tasks/TasksList';
import { TasksDetail } from './pages/TasksDetail/TasksDetail';
import { Permission } from './pages/Permissions/Permission';
import { appPaths } from './paths';
import { MasterData, NewEditMasterData } from './pages/MasterData';
import { ProductionNew, VsmsDetail } from './pages/VsmsDetail';
import { ProductionList } from './pages/Production';
import { ZoneDetail, ZoneNewEdit } from './pages/Zone';
import { AuditDetail, AuditNewEdit } from './pages/Audit';

import { AuditorNewEdit } from './pages/Auditor';
import { AuditQuestionNewEdit } from './pages/AuditQuestion';
import { QuestionTypeList } from './pages/QuestionType';
import { QuestionTypeNewEdit } from './pages/QuestionType/QuestionTypeEditNew';
import { UserRoles } from './types/applicationUser.types';
import { AuditTypeList, AuditTypeNewEdit } from './pages/AuditType';
import { AuditTypeLayoutList, AuditTypeLayoutNewEdit } from './pages/AuditTypeLayout';
import { InfoPage } from './pages/InfoPage';
import { Reports } from './pages/Reports';
import { Report5S } from './pages/Report5S/index';
import { ReportSelect } from './pages/ReportSelect';
import { ZoneLeaderNewEdit } from './pages/ZoneLeader/ZoneLeaderNewEdit';
import { AuditSurvey } from './pages/AuditSurvey/Common/AuditSurvey';
import { AuditLayoutRouter } from './pages/AuditSurvey/Common/AuditLayoutRouter';
import { LoginPage } from './pages/LoginPage';
import { LocalUserPage } from './pages/LocalUser/LocalUser';
import { LocalUserNewEdit } from './pages/LocalUser/LocalUserNewEdit';
import { QuestionsRouter } from './pages/AuditSurvey/Common/QuestionsRouter';
import { AuditQuestionAnswersNewEdit } from './pages/AuditQuestionAnswers';
import { LanguagePage } from './pages/Language/Language';
import { LanguageNewEdit } from './pages/Language/LanguageNewEdit';
import { LanguageTextNewEdit } from './pages/LanguageText/LanguageTextNewEdit';
import { Report5SSelect } from './pages/Report5SSelect/index';
import { ReportTasks } from './pages/ReportTasks';
import { HomePage } from './pages/Home/HomePage';

export interface IRoute {
    path: string;
    component: JSX.Element | any;
    protected: boolean;
    restrictedAccess?: Array<UserRoles>;
}

const routes: IRoute[] = [
    {
        path: '/',
        component: HomePage,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: appPaths.MASTER_DATA,
        component: MasterData,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: appPaths.TASKS,
        component: TasksList,
        protected: false,
    },
    {
        path: `${appPaths.TASKS}/:id`,
        component: TasksDetail,
        protected: false,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: appPaths.LOCAL_USER,
        component: LocalUserPage,
        protected: true,
    },
    {
        path: `${appPaths.LOCAL_USER}/:id`,
        component: LocalUserNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: appPaths.LANGUAGE,
        component: LanguagePage,
        protected: false,
    },
    {
        path: `${appPaths.LANGUAGE}/:countryCode`,
        component: LanguageNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.LANGUAGE_TEXT}/:countryCode/:reference`,
        component: LanguageTextNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: appPaths.PERMISSIONS,
        component: PermissionsList,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.PERMISSIONS}/:id`,
        component: Permission,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS}/new/:id`,
        component: NewEditMasterData,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },

    {
        path: `${appPaths.MASTER_DATA_VSMS}/:id`,
        component: VsmsDetail,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION}/:id`,
        component: ProductionList,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION}/new/:id`,
        component: ProductionNew,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE}/:id`,
        component: ZoneDetail,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE}/new/:id`,
        component: ZoneNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT}/new/:id`,
        component: AuditNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT}/:id`,
        component: AuditDetail,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_LEADER}/new/:id`,
        component: ZoneLeaderNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_LEADER}/edit/:id`,
        component: ZoneLeaderNewEdit,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_AUDITOR}/new/:id`,
        component: AuditorNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_AUDITOR}/edit/:id`,
        component: AuditorNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION}/:id`,
        component: AuditQuestionNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION_ANSWER}/new/:id`,
        component: AuditQuestionAnswersNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION_ANSWER}/edit/:id`,
        component: AuditQuestionAnswersNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.QUESTION_TYPES}`,
        component: QuestionTypeList,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.QUESTION_TYPES}/:id`,
        component: QuestionTypeNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.AUDIT_TYPE}`,
        component: AuditTypeList,
        protected: true,
    },
    {
        path: `${appPaths.AUDIT_TYPE}/:id`,
        component: AuditTypeNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.AUDIT_TYPE_LAYOUT}`,
        component: AuditTypeLayoutList,
        protected: true,
    },
    {
        path: `${appPaths.AUDIT_TYPE_LAYOUT}/:id`,
        component: AuditTypeLayoutNewEdit,
        protected: true,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        path: `${appPaths.INFO_PAGE}`,
        component: InfoPage,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.LOGIN_PAGE}`,
        component: LoginPage,
        protected: false,
        restrictedAccess: [],
    },
];

export const auditContextRoutes: IRoute[] = [
    /* {
        path: '/',
        component: MasterData,
        protected: true,
        // restrictedAccess: [UserRoles.Admin],
    }, */
    {
        path: `${appPaths.AUDIT}`,
        component: AuditSurvey,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.AUDIT_ZONE}`,
        component: AuditLayoutRouter,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.AUDIT_QUESTION}`,
        component: QuestionsRouter,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.LOGIN_PAGE}`,
        component: LoginPage,
        protected: false,
        restrictedAccess: [],
    },
];

export const offContextRoutes: IRoute[] = [
    {
        path: `${appPaths.REPORTS_AUDIT_S5_SELECT}/:countryCode`,
        component: Report5SSelect,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.REPORTS_AUDIT_S5}/:countryCode`,
        component: Report5S,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.REPORTS_PRODUCTION_AUDIT}/:countryCode`,
        component: Reports,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.REPORT_FORM_SELECT}/:countryCode`,
        component: ReportSelect,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.REPORTS}/:countryCode`,
        component: Reports,
        protected: false,
        restrictedAccess: [],
    },
    {
        path: `${appPaths.REPORTS_TASKS}/:countryCode`,
        component: ReportTasks,
        protected: false,
        restrictedAccess: [],
    },
];

export const auditRoutes = auditContextRoutes.map((route) => ({
    ...route,
}));

export default routes.map((route) => ({
    ...route,
}));
