import { Column, ColumnFormat } from '../../components/Table/Table';

export const ColumnsAttachment = (t): Column[] => [
    { id: 'name', label: t('Tasks.detail.attachment-table-col-1') },
];

export const ColumnsAnswer = (t): Column[] => [
    { id: 'authorName', label: t('Tasks.detail.comment-table-col-1') },
    {
        id: 'description',
        label: t('Tasks.detail.comment-table-col-2'),
        format: ColumnFormat.HTML_TEXT,
    },
    {
        id: 'eventTime',
        label: t('Tasks.detail.comment-table-col-3'),
        format: ColumnFormat.DATETIME,
    },
];
