import { useHistory, useParams } from 'react-router';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { Language } from '../Language/types';
import { LanguageForm } from '../../form/language/LanguageForm';
import { LanguageTextList } from '../LanguageText/LanguageTextList';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
export interface RouteParams {
    countryCode: string;
}

export const LanguageNewEdit = () => {
    const { t } = useTranslation();
    const { countryCode } = useParams<RouteParams>();
    const history = useHistory();
    const apiUrl = `${API_URL.LANGUAGE_ITEM}?countryCode=${countryCode}`;
    const [isNew, setIsNew] = useState<boolean>(false);
    const [numericId, setNumericId] = useState<number>();

    const { data, error, mutate, isValidating } = useSWR<Language>(apiUrl, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    useEffect(() => {
        if (countryCode !== 'new') {
            mutate();
            setNumericId(1);
        }
        if (countryCode === 'new') {
            setIsNew(true);
            setNumericId(0);
        }
    }, [countryCode]);

    const onSubmit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<Language>({
                    url: API_URL.LANGUAGE_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(appPaths.LANGUAGE);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.LANGUAGE_UPDATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-updated'));
                        history.push(appPaths.LANGUAGE);
                    },
                })
        )(numericId);

    const goBackUrl = `${appPaths.LANGUAGE}`;
    return (
        <PageWrapper error={error} loading={isValidating}>
            <HeaderPage
                heading={
                    isNew
                        ? t('Languages.detail.main-headline-new')
                        : t('Languages.detail.main-headline-edit')
                }
                stepName={
                    isNew
                        ? t('Languages.detail.main-headline-new')
                        : t('Languages.detail.main-headline-edit')
                }
                breadCrumbSteps={[{ name: t('Languages.main-headline'), pathToCutCount: 1 }]}
                noPaper
                noPy
            />

            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Paper>
                        <Box p={3}>
                            <LanguageForm
                                onSubmit={onSubmit}
                                data={data}
                                goBackUrl={goBackUrl}
                                buttonTitle={t('Save')}
                                isNew={isNew}
                                t={t}
                            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <LanguageTextList countryCode={countryCode} />
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
