import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { appPaths } from './paths';
import { UserRoles } from './types/applicationUser.types';
import InfoIcon from '@material-ui/icons/Info';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import LanguageIcon from '@material-ui/icons/Language';

export enum LinkType {
    External = 'external',
    Sub = 'Sub',
}

export interface MenuItem {
    title: string;
    path: string;
    icon?: any;
    children?: MenuItem[];
    restrictedAccess?: Array<UserRoles>;
    type?: string;
    isReport?: boolean;
}

export const menuItems: MenuItem[] = [
    {
        title: 'Menu.tasks',
        path: appPaths.TASKS,
        icon: AssignmentIcon,
    },
    {
        title: 'Menu.audit',
        path: appPaths.AUDIT,
        icon: AssessmentIcon,
    },
    {
        title: 'Menu.master-data',
        path: appPaths.MASTER_DATA,
        icon: SettingsIcon,
        restrictedAccess: [],
        type: LinkType.Sub,
        children: [
            {
                title: 'Menu.master-data.hierarchy',
                path: appPaths.MASTER_DATA,
            },
            {
                title: 'Menu.master-data.topics',
                path: appPaths.QUESTION_TYPES,
            },
            {
                title: 'Menu.master-data.audit-types',
                path: appPaths.AUDIT_TYPE,
            },
            {
                title: 'Menu.master-data.audit-types-layouts',
                path: appPaths.AUDIT_TYPE_LAYOUT,
            },
        ],
    },
    {
        title: 'Menu.permissions',
        path: appPaths.PERMISSIONS,
        icon: VerifiedUserIcon,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        title: 'Menu.local-users',
        path: appPaths.LOCAL_USER,
        icon: PersonIcon,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        title: 'Menu.languages',
        path: appPaths.LANGUAGE,
        icon: LanguageIcon,
        restrictedAccess: [UserRoles.Admin],
    },
    {
        title: 'Menu.report',
        path: appPaths.REPORT_FORM_SELECT,
        icon: ViewWeekIcon,
        type: LinkType.Sub,
        children: [
            {
                title: 'Menu.report.sub-1',
                path: appPaths.REPORT_FORM_SELECT,
                isReport: true,
                type: LinkType.External,
            },
            {
                title: 'Menu.report.sub-2',
                path: appPaths.REPORTS_AUDIT_S5_SELECT,
                isReport: true,
                type: LinkType.External,
            },
            {
                title: 'Menu.report.sub-3',
                path: appPaths.REPORTS_TASKS,
                isReport: true,
                type: LinkType.External,
            },
        ],
    },
    {
        title: 'Menu.system-info',
        path: appPaths.INFO_PAGE,
        icon: InfoIcon,
    },
];
