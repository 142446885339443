import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import React from 'react';
import { cFieldNames, validationSchema } from './schema';
import FieldSelect from '../../components/Form/FieldSelect';
import { Role } from '../../hooks/useAppUser';
import { Permission, permissionEmpty } from '../../pages/Permissions/types';
import { UserSearchField } from '../../components/Form/UserSearchField';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: Permission | null;
    buttonTitle: string;
    roles?: Array<Role> | null;
    t: any;
}

export const PermissionForm = ({
    onSubmit,
    data,
    buttonTitle,
    roles,
    userCanEdit,
    goBackUrl,
    t,
}: Props) => (
    <Formik
        enableReinitialize
        initialValues={isNil(data) ? permissionEmpty : data}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
            onSubmit(values);
        }}
    >
        {({ handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={12} lg={12}>
                        <Field name={cFieldNames.netId} component={UserSearchField} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            name={cFieldNames.identifier}
                            label={t('Permissions.detail.input-2')}
                            fullWidth
                            component={FieldSelect}
                        >
                            {roles &&
                                roles.map(({ identifier }) => (
                                    <MenuItem key={identifier} value={identifier}>
                                        {identifier}
                                    </MenuItem>
                                ))}
                        </Field>
                    </Grid>
                    <SubmitButton
                        visible={userCanEdit}
                        buttonTitle={buttonTitle}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
            </Form>
        )}
    </Formik>
);
