import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    auditTypeId: 'auditTypeId',
};

export interface ReportSelectValues {
    auditTypeId: number;
}

export const initialValues = {
    [cFieldNames.auditTypeId]: '',
};
export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.auditTypeId]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
