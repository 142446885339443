// @ts-ignore
import React, { useState } from 'react';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './styles';
import { cond, equals, T } from 'ramda';
import { CircularProgress } from '@material-ui/core';

export enum EndAddorment {
    LOADING = 'LOADING',
    PASSWORD = 'PASSWORD',
}
interface TextFieldInterface {
    type?: any;
    variant?: any;
    fullWidth?: any;
    select?: any;
    disabled?: any;
    className?: any;
    style?: any;
    shrink?: any;
    placeholder?: any;
    withMinWidth?: boolean | undefined;
    error?: any;
    helperText?: any;
    label?: string;
    value?: any;
    onChange?: any;
    multiline?: any;
    rows?: any;
    children?: any;
    InputProps?: any;
    endAddorment?: EndAddorment | null;
}

const TextField = ({
    type,
    variant,
    fullWidth,
    select,
    disabled,
    className,
    style,
    shrink,
    placeholder,
    withMinWidth,
    error,
    helperText,
    label,
    value,
    onChange,
    InputProps,
    endAddorment,
    ...props
}: TextFieldInterface) => {
    const classes = useStyles();

    const [passwordVisibility, setPassVisibility] = useState(false);

    const getTextFieldType = () => {
        if (passwordVisibility) {
            return 'text';
        }
        if (type) {
            return type;
        }
        return 'text';
    };

    const getStartAdornment = (type: string) => {
        if (type === 'search') {
            return (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            );
        }

        if (type === 'phone') {
            return <InputAdornment position="start">+420</InputAdornment>;
        }
    };

    return (
        <MUITextField
            type={getTextFieldType()}
            className={className}
            style={style}
            error={error}
            disabled={disabled}
            variant={variant}
            label={label}
            fullWidth={fullWidth}
            select={select}
            placeholder={placeholder}
            helperText={helperText}
            value={value}
            onChange={onChange}
            InputProps={
                InputProps || {
                    classes: {
                        root: withMinWidth ? classes.root : '0',
                        input: classes.input,
                    },
                    startAdornment: getStartAdornment(type),
                    endAdornment: cond([
                        [
                            equals(EndAddorment.PASSWORD),
                            () => (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setPassVisibility(!passwordVisibility)}
                                    >
                                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        ],
                        [
                            equals(EndAddorment.LOADING),
                            () => (
                                <InputAdornment position="end">
                                    <CircularProgress size={'small'} />
                                </InputAdornment>
                            ),
                        ],
                        [T, () => null],
                        // @ts-ignore
                    ])(endAddorment),
                }
            }
            InputLabelProps={{
                shrink,
                classes: {
                    formControl: classes.formControl,
                    shrink: classes.shrink,
                },
            }}
            classes={{
                root: classes.textFieldMargin,
            }}
            {...props}
        />
    );
};

export default TextField;
