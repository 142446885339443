import React, { useState, useEffect, createContext, useContext } from 'react';

import { isNil } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import { getItemApi, getListApi, postApi } from '../apiUtils';
import { API_URL } from '../apiTypes';
import { KpiItemMember, KpiItemMemberLevelAccess } from '../types/KpiMemberType';
import { ApplicationUser, UserRoles } from '../types/applicationUser.types';
import { useHistory } from 'react-router';
import { Login } from '../pages/LoginPage/types';

export interface Role {
    identifier: string;
    description: string;
}

export interface User {
    personalNumber: string;
    cardId: string;
    netId: string;
    name: string;
    email: string;
    jobTitle: string;
    businessCategory: string;
    physicalDeliveryOfficeName: string;
    costCenter: string;
    managerNetId: string;
    fullName: string;
    isValid: boolean;
    isAdmin: boolean;
    roles: Array<string>;
}

export const useAppUser = () => {
    const userInSessionStorage = sessionStorage.getItem('appUser');
    const history = useHistory();

    const [appUser, setAppUser] = useState<User | null>(
        userInSessionStorage ? JSON.parse(userInSessionStorage) : null
    );
    const [userAccess, setUserAccess] = useState<KpiItemMemberLevelAccess | null>(null);
    const [kpiItemId, setKpiItemId] = useState<number | null>(null);
    const [loadingAccess, setLoadingAccess] = useState(false);

    const isAuthenticated = async () => {
        await getItemApi({
            url: API_URL.IS_AUTHENTICATED,
            params: {},
            callAfterSuccess: (res) => {
                if (res.data) getUser();
                // if (!res.data) history.push('/login');
            },
        });
    };

    const getUser = async () => {
        const data = await getItemApi<User>({ url: API_URL.USER_GETCURRENTUSER, params: {} });
        if (data) {
            setAppUser(data);
            sessionStorage.setItem('appUser', JSON.stringify(data));
        }
    };

    const verifyUserIsStored = () => {
        if (userInSessionStorage) {
            return;
        }
        // getUser();
    };

    const getKpiItemMembers = async (id: number) => {
        setLoadingAccess(true);

        const data = await getListApi<KpiItemMember>({
            url: API_URL.KPIITEMMEMBER_LIST,
            params: { parentId: id },
        });
        const user = data?.find(({ netId }) => netId === appUser?.netId);

        if (
            user?.access === KpiItemMemberLevelAccess.OWNER ||
            user?.access === KpiItemMemberLevelAccess.MEMBER
        ) {
            setUserAccess(user.access);
        } else {
            setUserAccess(null);
        }

        setLoadingAccess(false);
    };

    const changeKpiItemId = (itemId: number | null) => {
        setKpiItemId(itemId);
    };

    useEffect(() => {
        if (kpiItemId) {
            getKpiItemMembers(kpiItemId);
        } else {
            setUserAccess(null);
        }
    }, [kpiItemId, appUser]);

    useEffect(() => {
        verifyUserIsStored();
        isAuthenticated();
    }, []);

    const signIn = async (data: Login): Promise<void> => {
        await postApi<ApplicationUser>({
            url: `${API_URL.LOGIN}?username=${data.username}&password=${data.password}`,
            callAfterSuccess: () => {
                // @ts-ignore
                getUser();
            },
            callAfterError: () => {
                // todo
            },
        });
    };

    const signOut = async (): Promise<void> => {
        await getItemApi<ApplicationUser>({
            url: `${API_URL.LOGOUT}`,
            params: {},
            callAfterSuccess: () => {
                setAppUser(null);
                sessionStorage.removeItem('appUser');
                history.push('/login');
            },
        });
    };

    const isAccessibleByUserRoleAndAdminPrivilege = (roles?: Array<UserRoles>) => {
        if (isNil(appUser) || loadingAccess) {
            return true;
        }
        if (appUser?.isAdmin) return true;
        return (
            isNilOrEmpty(roles) || appUser?.roles.some((role) => roles?.toString().includes(role))
        );
    };
    return {
        appUser,
        setAppUser,
        userAccess,
        changeKpiItemId,
        getUser,
        signIn,
        signOut,
        loadingAccess,
        isAccessibleByUserRoleAndAdminPrivilege,
    };
};
