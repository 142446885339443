import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    netId: 'netId',
    parentZoneId: 'parentZoneId',
    taskResponsible: 'taskResponsible',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.netId]: yup.string().required(t(cFormValidationMessages.required)),

        [cFieldNames.parentZoneId]: yup.number().required(t(cFormValidationMessages.required)),
    });
};
