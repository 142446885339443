import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { Grid } from '@material-ui/core';
import { PageWrapper } from '../../containers/PageWrapper';
import React from 'react';
import { UserRoles } from '../../types/applicationUser.types';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import StickyHeadTable from '../../components/Table/Table';
import useSWR from 'swr';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { Columns } from './Columns';
import { appPaths } from '../../paths';
import { useHistory } from 'react-router-dom';
import { AuditTypeLayoutApiResponse } from '../../types/AuditTypeLayoutApiResponse';
import { useTranslation } from '../../contexts/translationContext';

export const AuditTypeLayoutList = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const history = useHistory();

    const {
        data: auditTypeLayout,
        error: errorAuditTypeLayout,
        isValidating,
        mutate,
    } = useSWR<AuditTypeLayoutApiResponse>(`${API_URL.AUDIT_TYPE_LAYOUT_LIST}`, fetcher);

    const handleAddItem = ({ id }) => history.push(`${appPaths.AUDIT_TYPE_LAYOUT}/${id}`);

    return (
        <PageWrapper loading={isValidating} error={errorAuditTypeLayout}>
            <HeaderPage
                stepName={t('Master-data.audit-types-layouts.main-headline')}
                buttonSectionPermission={editRoles}
            />
            <Grid item container xs={12} sm={12} md={12}>
                <StickyHeadTable
                    columns={Columns(t)}
                    handleEditItem={handleAddItem}
                    rows={auditTypeLayout}
                    addItemAndDeletePermissions={editRoles}
                />
            </Grid>
        </PageWrapper>
    );
};
