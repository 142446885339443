import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from '../Common/styles';
import { useHistory } from 'react-router';
import { QuestionOfflineIndicator } from '../Common/Questions/QuestionOfflineIdicator';
import { appPaths } from '../../../paths';
import { useTranslation } from '../../../contexts/translationContext';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';
import { Footer } from '../Common/Layout/Footer';
import { AlertDialog } from '../../../components/AlertDialog';
import { Answer } from '../../../types/ChecklistAnswerApiResponse';
import { useLoading } from '../../../contexts/loadingContext';
interface Props {
    showOfflineIndicator: boolean;
    onFinish: () => void;
    answers: Answer[];
}

export const QuestionSummaryL4 = ({ showOfflineIndicator, onFinish, answers }: Props) => {
    const { t } = useTranslation();
    const { loading } = useLoading();
    const history = useHistory();
    const classes = useStyles();
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    return (
        <>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography variant="h2" className={classes.labelText}>
                    {t('Audit.layout.summary.text')}
                </Typography>
            </Header>

            <Content bottomOffset={30} textAlign="center">
                {showOfflineIndicator && <QuestionOfflineIndicator />}
            </Content>

            <Footer>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        onClick={() => {
                            if (answers.length > 0) {
                                setOpenAlert(true);
                            } else {
                                history.push(appPaths.AUDIT);
                            }
                        }}
                        variant="contained"
                        className={classes.navButton}
                    >
                        {t('Storno')}
                    </Button>
                    <Button
                        onClick={onFinish}
                        disabled={loading}
                        variant="contained"
                        className={classes.navButton}
                    >
                        {t('Save-data')}
                    </Button>
                </Box>
            </Footer>
            <AlertDialog
                open={openAlert}
                title={t('Audit.layout.modal.unsaved-data')}
                content={t('Audit.layout.modal.unsaved-data-confirm')}
                handleClose={() => {
                    setOpenAlert(false);
                }}
                btn1={{ title: t('Audit.layout.modal.option-no') }}
                btn2={{ title: t('Audit.layout.modal.option-yes') }}
                handleConfirm={() => history.push(appPaths.AUDIT)}
            />
        </>
    );
};
