import React from 'react';
import { useLocation } from 'react-router';
import { AuditLayoutId } from '../../../types/AuditMobile';
import { Zone } from '../../../types/ZoneApiResponse';
import { QuestionsLayout_1 } from '../Layout_1/QuestionsLayout_1';
import { QuestionsLayout_3 } from '../Layout_3/QuestionsLayout_3';
import { QuestionsLayout_5 } from '../Layout5/QuestionsLayout_5';
import { useAuthContext } from '../../../contexts/AuthContext';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';
interface StateProps {
    state: {
        zone: Zone;
        selectedAudit: any;
        layoutId: number;
        dangerousFindings: boolean;
        userNetId: string;
        observedPersonRequired?: AuditTypeObservedPersonForm;
    };
}

/**
 * Zobrazí layout otázek podle vybrnaého layoutu.
 * @returns
 */

export const QuestionsRouter = () => {
    const { appUser } = useAuthContext();
    const { state: location }: { state: StateProps } = useLocation();
    const { selectedAudit } = location.state;
    const { zone } = location.state;
    const { layoutId } = location.state;
    const { userNetId } = location.state;
    const { observedPersonRequired } = location.state;

    switch (layoutId) {
        case AuditLayoutId.Layout1:
            return (
                <QuestionsLayout_1
                    selectedAudit={selectedAudit}
                    zone={zone}
                    observedPersonRequired={observedPersonRequired}
                />
            );
        case AuditLayoutId.Layout2:
            // Layout 2 je shodný s layoutem 1 (ve smyslu questions)
            return (
                <QuestionsLayout_1
                    selectedAudit={selectedAudit}
                    zone={zone}
                    observedPersonRequired={observedPersonRequired}
                />
            );
        case AuditLayoutId.Layout3:
            return (
                <QuestionsLayout_3
                    selectedAudit={selectedAudit}
                    zone={zone}
                    observedPersonRequired={observedPersonRequired}
                />
            );
        case AuditLayoutId.Layout5:
            return (
                <QuestionsLayout_5
                    selectedAudit={selectedAudit}
                    auditorNetId={appUser ? appUser.netId : userNetId}
                />
            );
        default:
            break;
    }
};
