import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    productionId: 'productionId',
    auditId: 'auditId',
};

export interface ReportSelectValues {
    productionId: number;
    auditId: number;
}

export const initialValues = {
    [cFieldNames.productionId]: '',
    [cFieldNames.auditId]: '',
};
export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.productionId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.auditId]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
