import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { Box, Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { ZoneRouteParams } from '../Zone';
import { Audit } from '../../types/AuditApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { ZoneApiResponse } from '../../types/ZoneApiResponse';
import { appPaths } from '../../paths';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { AuditorApiResponse } from '../../types/AuditorApiResponse';
import { AuditDetailFormContainer } from '../../containers/AuditrDetailForm';
import { AuditType, AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { AuditQuestionApiResponse } from '../../types/AuditQuestionApiResponse';
import {
    AuditTableAuditorsContainer,
    AuditTableQuestionsContainer,
} from '../../containers/AuditTablesContainer';
import { UserRoles } from '../../types/applicationUser.types';
import { TabPanel } from '../../components/TabPanel';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { AuditLayoutId } from '../../types/AuditMobile';

export interface AuditRouterParams extends ZoneRouteParams {
    zoneId: string;
}

export const AuditDetail = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const { id, vsmsId, productionId, zoneId } = useParams<AuditRouterParams>();
    const numericId = Number(id);
    const numericProductionId = Number(productionId);
    const numericZoneId = Number(zoneId);
    const history = useHistory();
    const auditorPath = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${id}/auditor`;
    const questionPath = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${id}/question`;
    const [selectedTypeLayout, setSelectedTypeLayout] = useState<number>();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {
        data,
        error,
        mutate: mutateAudits,
    } = useSWR<AuditorApiResponse>(`${API_URL.AUDITOR_LIST}?parentId=${numericId}`, fetcher);

    const { data: auditTypes, error: errorAuditType } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher
    );
    const {
        data: auditQuestion,
        error: errorQuestion,
        mutate: mutateQuestion,
    } = useSWR<AuditQuestionApiResponse>(
        `${API_URL.AUDIT_QUESTION_LIST}?parentId=${numericId}`,
        fetcher
    );

    const { data: zones, error: zoneError } = useSWR<ZoneApiResponse>(
        `${API_URL.ZONES_LIST}?parentId=${numericProductionId}`,
        fetcher
    );
    const {
        data: auditDetail,
        error: auditError,
        mutate: auditMutate,
    } = useSWR<Audit>(`${API_URL.AUDIT_ITEM}?id=${numericId}`, fetcher);

    useEffect(() => {
        if (!auditDetail && !auditTypes) return;
        let tmpAuditType = auditTypes?.find((x: AuditType) => x.id === auditDetail?.auditTypeId);
        if (tmpAuditType) setSelectedTypeLayout(tmpAuditType?.layoutId);
    }, [auditDetail, auditTypes]);

    const handleAddAuditorItem = () => history.push(`${auditorPath}/new/0`);
    const handleEditAuditorItem = ({ id }) => history.push(`${auditorPath}/edit/${id}`);
    const handleAddQuestion = () =>
        history.push(`${questionPath}/0`, {
            state: {
                selectedTypeLayout: selectedTypeLayout,
            },
        });
    const handleEditQuestion = ({ id }) =>
        history.push(`${questionPath}/${id}`, {
            state: {
                selectedTypeLayout: selectedTypeLayout,
            },
        });

    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}`;
    if (!zones || !data || !auditDetail || !auditTypes || !selectedTypeLayout) return <Loader />;
    return (
        <PageWrapper
            loading={!zones || !data || !auditDetail || !auditTypes || !auditQuestion}
            error={errorQuestion || error || errorAuditType || zoneError || auditError}
        >
            <HeaderPage
                heading={`${auditDetail.auditTypeName} - ${auditDetail.parentZoneName}`}
                stepName={t('Master-data.hierarchy.detail.production.zone.audit.main-headline')}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 8 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 6 },
                    {
                        name: t('Master-data.hierarchy.detail.production.main-headline'),
                        pathToCutCount: 4,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.main-headline'),
                        pathToCutCount: 2,
                    },
                ]}
                /* breadCrumbSteps={[
                    { name: stepNames.MASTERDATA, pathToCutCount: 8 },
                    { name: stepNames.VSMS, pathToCutCount: 6 },
                    { name: stepNames.PRODUCTION, pathToCutCount: 4 },
                    { name: stepNames.ZONE, pathToCutCount: 2 },
                ]} */
                buttonSectionPermission={editRoles}
                buttonSection={
                    <Grid container spacing={1}>
                        {auditQuestion && auditQuestion.length > 0 && (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        window.open(`/print/question-hierarchy/${id}`, '_blank')
                                    }
                                >
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.print-checklist'
                                    )}
                                </Button>
                                <Box px={2} />
                            </>
                        )}

                        <Button variant="contained" color="primary" onClick={handleAddAuditorItem}>
                            {t('Master-data.hierarchy.detail.production.zone.audit.add-auditor')}
                        </Button>
                        <Box px={2} />
                        <Button variant="contained" color="primary" onClick={handleAddQuestion}>
                            {t('Master-data.hierarchy.detail.production.zone.audit.add-question')}
                        </Button>
                    </Grid>
                }
            />
            <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                    <AuditDetailFormContainer
                        mutate={auditMutate}
                        data={auditDetail}
                        auditTypes={auditTypes}
                        zones={zones}
                        zoneId={numericZoneId}
                        editPermission={editRoles}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
                <Grid item md={8}>
                    <Paper>
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="secondary"
                            onChange={handleChange}
                        >
                            <Tab
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.tab-1-headline'
                                )}
                            />
                            {selectedTypeLayout !== AuditLayoutId.Layout4 && (
                                <Tab
                                    label={t(
                                        'Master-data.hierarchy.detail.production.zone.audit.tab-2-headline'
                                    )}
                                />
                            )}
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <AuditTableAuditorsContainer
                                zoneId={zoneId}
                                productionId={productionId}
                                vsmsId={vsmsId}
                                id={id}
                                data={data}
                                auditMutate={auditMutate}
                                mutateAudits={mutateAudits}
                                handleAddItem={handleAddAuditorItem}
                                handleEditItem={handleEditAuditorItem}
                                editPermission={editRoles}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AuditTableQuestionsContainer
                                zoneId={zoneId}
                                productionId={productionId}
                                vsmsId={vsmsId}
                                id={id}
                                data={auditQuestion}
                                auditMutate={auditMutate}
                                mutateQuestion={mutateQuestion}
                                mutateAudits={mutateAudits}
                                handleAddItem={handleAddQuestion}
                                handleEditItem={handleEditQuestion}
                                editPermission={editRoles}
                            />
                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
