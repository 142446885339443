import React from 'react';
import { AlertDialog } from '../AlertDialog';
import { ZoneCopyForm } from '../../form/zone-copy';
import { pick } from 'ramda';
import useSWR from 'swr';
import { VsmApiResponse } from '../../types/VsmApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi } from '../../apiUtils';
import { useDataApi } from '../../hooks/useDataApi';
import { PageWrapper } from '../../containers/PageWrapper';
import { ZoneCopy } from '../../types/ZoneCopy';
import { cFieldNames } from '../../form/zone-copy/schema';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

interface Props {
    handleClose: (e: any) => void;
    handleConfirm: (e: any) => void;
    open: boolean;
    zoneId: number;
    refreshZone?: () => void;
}

export const swrConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
};

export const CopyZoneQuestionsModal = ({
    open,
    handleClose,
    handleConfirm,
    zoneId,
    refreshZone,
}: Props) => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;

    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);
    const {
        data: vsms,
        isValidating,
        error: vsmsError,
    } = useSWR<VsmApiResponse>(`${API_URL.VSM_LIST}`, fetcher, swrConfig);

    const [{ data: prods, isLoading, isError }, doFetch] = useDataApi(
        `${API_URL.PRODUCTION_LIST}`,
        [],
        [],
        false
    );

    const [{ data: zones, isLoading: zoneLoading, isError: zoneError }, doFetchZone] = useDataApi(
        `${API_URL.ZONES_LIST}`,
        [],
        [],
        false
    );
    const [{ data: audits, isLoading: auditLoading, isError: auditError }, doFetchAudit] =
        useDataApi(`${API_URL.AUDIT_LIST}`, [], [], false);
    const [{ data: allZones, isError: allZonesError }] = useDataApi(
        `${API_URL.ZONES_LIST}`,
        [],
        [],
        true
    );

    const onVsmChange = async (n: number) => {
        doFetch(`${API_URL.PRODUCTION_LIST}?parentId=${n}`);
    };

    const onProductionChange = (n: number) => {
        doFetchZone(`${API_URL.ZONES_LIST}?parentId=${n}`);
    };

    const onZoneChange = (n: number) => {
        doFetchAudit(`${API_URL.AUDIT_LIST}?parentId=${n}`);
    };

    const submit = async (values) => {
        await postApi<ZoneCopy>({
            url: API_URL.COPY_ZONE,
            data: pick(
                [
                    cFieldNames.copyAuditors,
                    cFieldNames.zoneOriginId,
                    cFieldNames.zoneDestinationId,
                    cFieldNames.auditId ?? cFieldNames.auditId,
                ],
                values
            ),
            callAfterSuccess: () => {
                handleClose('');
                refreshZone && refreshZone();
                NotificationManager.success(t('Notify.request-successful'));
            },
        });
    };
    return (
        <AlertDialog
            open={open}
            title={t('Master-data.hierarchy.detail.production.zone.modal-copy-headline')}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            hideButtons
        >
            <PageWrapper
                error={vsmsError || allZonesError || isError || zoneError || auditError}
                loading={isValidating}
            >
                <ZoneCopyForm
                    onSubmit={submit}
                    onProductionChange={onProductionChange}
                    onVsmChange={onVsmChange}
                    onZoneChange={onZoneChange}
                    buttonTitle={''}
                    userCanEdit={userCanEdit}
                    productionId={0}
                    vsms={vsms}
                    productions={prods}
                    audits={audits}
                    allZones={allZones}
                    zoneId={zoneId}
                    zones={zones}
                    vsmsLoading={isValidating}
                    productionLoading={isLoading}
                    zoneLoading={zoneLoading}
                    auditLoading={auditLoading}
                    closeModal={handleClose}
                />
            </PageWrapper>
        </AlertDialog>
    );
};
