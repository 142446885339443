import { Column } from '../../components/Table/Table';

export const Columns = (t): Column[] => [
    { id: 'name', label: t('Master-data.audit-types-layouts.detail.input-1') },
    {
        id: 'description',
        label: t('Master-data.audit-types-layouts.detail.input-2'),
        minWidth: 200,
    },
];
