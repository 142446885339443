import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import { CreateVsms } from '../../types/VsmApiResponse';
import FieldText from '../../components/Form/FieldText';
import { Production } from '../../types/ProductionApiResponse';
import FieldSelect from '../../components/Form/FieldSelect';
import { CreateZone } from '../../types/ZoneApiResponse';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { useTranslation } from '../../contexts/translationContext';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: CreateVsms | null;
    buttonTitle: string;
    productions?: Production[] | null;
    productionId: number;
}

export const ZoneForm = ({
    onSubmit,
    data,
    productions,
    productionId,
    userCanEdit,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateZone = {
        id: 0,
        name: '',
        description: '',
        building: '',
        floor: '',
        parentProductionId: productionId,
    };

    return (
        <Formik
            enableReinitialize
            initialValues={
                isNil(data) ? initialValues : { ...data, description: data.description ?? '' }
            }
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.name}
                                label={t('Master-data.hierarchy.detail.production.zone.input-1')}
                                fullWidth
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.description}
                                label={t('Master-data.hierarchy.detail.production.zone.input-2')}
                                fullWidth
                                minRows={3}
                                multiline
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.parentProductionId}
                                label={t('Master-data.hierarchy.detail.production.zone.input-3')}
                                fullWidth
                                component={FieldSelect}
                                disabled
                            >
                                {productions ? (
                                    productions.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem key={0} value={''} />
                                )}
                            </Field>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.building}
                                label={t('Master-data.hierarchy.detail.production.zone.input-4')}
                                fullWidth
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.floor}
                                label={t('Master-data.hierarchy.detail.production.zone.input-5')}
                                fullWidth
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <SubmitButton visible={userCanEdit} goBackUrl={goBackUrl} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
