import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { ReportLayout3 } from '../../types/Report5STypes';
import { green, red, useStyles } from './styles';
import { Production } from '../../types/ProductionApiResponse';
import { Vsm } from '../../types/VsmApiResponse';
import { FilterParams } from './Report5S';
import { Zone } from '../../types/ZoneApiResponse';
interface Props {
    data: ReportLayout3;
    vsmList: Vsm[];
    productionList: Production[];
    zoneList: Zone[];
    setIsUnevaluatedList: any;
    onFilterChange: (FilterParams) => void;
    defaultFilterParams: FilterParams;
}
export const Report5SFilter = ({
    data,
    vsmList,
    productionList,
    zoneList,
    setIsUnevaluatedList,
    onFilterChange,
    defaultFilterParams,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleChange = (_vsmIds: number[], _productionIds: number[], _zoneIds: number[]) => {
        onFilterChange({
            vsmIds: _vsmIds,
            productionIds: _productionIds,
            zoneIds: _zoneIds,
        });
    };

    return (
        <Grid container className={classes.flexContainer} style={{ height: '95vh' }}>
            <Grid
                item
                xs={12}
                className={classes.flexItem}
                style={{ maxHeight: '45vh', overflow: 'auto' }}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.sectionHeadline}>
                        {t('Report.5S.filter.vsm')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {vsmList.map((vsm: Vsm) => {
                            return (
                                <FormControlLabel
                                    className={classes.checkboxFilter}
                                    key={vsm.id}
                                    control={<Checkbox />}
                                    checked={defaultFilterParams.vsmIds.some(
                                        (id: number) => id === vsm.id
                                    )}
                                    onChange={(e: any) => {
                                        let tmpProdIds = defaultFilterParams.productionIds;
                                        let tmpZoneIds = defaultFilterParams.zoneIds;
                                        if (e.target.checked) {
                                            handleChange(
                                                [...defaultFilterParams.vsmIds, vsm.id],
                                                tmpProdIds,
                                                tmpZoneIds
                                            );
                                        } else {
                                            let tmpIds = defaultFilterParams.vsmIds.filter(
                                                (id: number) => id !== vsm.id
                                            );
                                            productionList.map((prod: Production) => {
                                                if (prod.parentVsmId === vsm.id) {
                                                    // produkce má tohle vsm a je zaškrtnutá --> odebrat z listu
                                                    tmpProdIds = tmpProdIds.filter(
                                                        (id: number) => id !== prod.id
                                                    );
                                                    // projet všechny zóny jestli má jako parenta tuhle produkci
                                                    zoneList.map((zone: Zone) => {
                                                        if (zone.parentProductionId === prod.id) {
                                                            // zóna má tohle prod a je zaškrtnutá --> odebrat z listu
                                                            tmpZoneIds = tmpZoneIds.filter(
                                                                (id: number) => id !== zone.id
                                                            );
                                                        }
                                                    });
                                                }
                                            });
                                            handleChange(tmpIds, tmpProdIds, tmpZoneIds);
                                        }
                                    }}
                                    label={vsm.name}
                                />
                            );
                        })}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.flexItemStretch}
                style={{ maxHeight: '45vh', overflow: 'auto', borderTop: '1px solid #000' }}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.sectionHeadline}>
                        {t('Report.5S.filter.production')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {productionList.map((prod: Production) => {
                            return (
                                <FormControlLabel
                                    className={classes.checkboxFilter}
                                    key={prod.id}
                                    control={<Checkbox />}
                                    checked={defaultFilterParams.productionIds.some(
                                        (id: number) => id === prod.id
                                    )}
                                    onChange={(e: any) => {
                                        let tmpZoneIds = defaultFilterParams.zoneIds;
                                        if (e.target.checked) {
                                            handleChange(
                                                defaultFilterParams.vsmIds,
                                                [...defaultFilterParams.productionIds, prod.id],
                                                defaultFilterParams.zoneIds
                                            );
                                        } else {
                                            let tmpProdIds =
                                                defaultFilterParams.productionIds.filter(
                                                    (id: number) => id !== prod.id
                                                );
                                            // projet zóny jestli má tuhle produkci
                                            zoneList.map((zone: Zone) => {
                                                if (zone.parentProductionId === prod.id) {
                                                    // zóna má tohle prod a je zaškrtnutá --> odebrat z listu
                                                    tmpZoneIds = tmpZoneIds.filter(
                                                        (id: number) => id !== zone.id
                                                    );
                                                }
                                            });
                                            handleChange(
                                                defaultFilterParams.vsmIds,
                                                tmpProdIds,
                                                tmpZoneIds
                                            );
                                        }
                                    }}
                                    label={prod.name}
                                />
                            );
                        })}
                    </FormGroup>
                </Grid>
            </Grid>
            {defaultFilterParams.productionIds.length > 0 && (
                <Grid
                    item
                    xs={12}
                    className={classes.flexItemStretch}
                    style={{ maxHeight: '45vh', overflow: 'auto', borderTop: '1px solid #000' }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.sectionHeadline}>
                            {t('Report.5S.filter.zone')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            {zoneList.map((prod: Zone) => {
                                return (
                                    <FormControlLabel
                                        className={classes.checkboxFilter}
                                        key={prod.id}
                                        control={<Checkbox />}
                                        checked={defaultFilterParams.zoneIds.some(
                                            (id: number) => id === prod.id
                                        )}
                                        onChange={(e: any) => {
                                            if (e.target.checked) {
                                                handleChange(
                                                    defaultFilterParams.vsmIds,
                                                    defaultFilterParams.productionIds,
                                                    [...defaultFilterParams.zoneIds, prod.id]
                                                );
                                            } else {
                                                let tmpIds = defaultFilterParams.zoneIds.filter(
                                                    (id: number) => id !== prod.id
                                                );
                                                handleChange(
                                                    defaultFilterParams.vsmIds,
                                                    defaultFilterParams.productionIds,
                                                    tmpIds
                                                );
                                            }
                                        }}
                                        label={prod.name}
                                    />
                                );
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                className={classes.noEvaluation}
                style={{
                    backgroundColor: data.numberOfProductionsWithoutEvaluation === 0 ? green : red,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => setIsUnevaluatedList(true)}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.numberSectionHeadline}>
                        {t('Report.5S.filter.productions-no-evaluation')}
                    </Typography>
                    <Typography variant="h2" className={classes.numberSection}>
                        {data?.numberOfProductionsWithoutEvaluation}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
