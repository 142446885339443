import React from 'react';
import { Field, Form, Formik } from 'formik';
import { isNil } from 'ramda';
import { Grid } from '@material-ui/core';
import { cFieldNames, validationSchema } from './schema';
import FieldText from '../../components/Form/FieldText';
import {
    AuditQuestionType,
    CreateAuditQuestionType,
} from '../../types/AuditQuestionTypeApiResponse';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { IForm } from '../../types/IForm';
import { useTranslation } from '../../contexts/translationContext';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: AuditQuestionType | null;
    buttonTitle?: string;
}

export const QuestionTypeForm = ({
    data,
    onSubmit,
    buttonTitle,
    userCanEdit,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateAuditQuestionType = {
        id: 0,
        name: '',
    };
    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.name}
                                label={t('Master-data.topics.detail.input-1')}
                                fullWidth
                                component={FieldText}
                            />
                        </Grid>
                        <SubmitButton
                            goBackUrl={goBackUrl}
                            visible={userCanEdit}
                            buttonTitle={buttonTitle}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
