import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    name: 'name',
    description: 'description',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.name]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.description]: yup.string(),
    });
};
