import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { ReportLayout3 } from '../../types/Report5STypes';
import { Line } from 'react-chartjs-2';
import { useStyles } from './styles';
interface Props {
    data: ReportLayout3;
}

interface ChartData {
    labels: string[];
    evaluation: number[];
    target: number[];
}

export const Report5STrendEval = ({ data }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [chartData, setChartData] = useState<ChartData>();

    useEffect(() => {
        if (!data || !data.evaluationOfPreviousWeeks) return;

        const labelsRev = Object.keys(data.evaluationOfPreviousWeeks).map((key: any) => {
            // aby byly labely pod sebou musím splitnout
            return key.split(' ');
        });
        const targetRev = Object.keys(data.evaluationOfPreviousWeeks).map((key: any) => {
            return data.evaluationOfPreviousWeeks[key][0];
        });
        const evaluationRev = Object.keys(data.evaluationOfPreviousWeeks).map((key: any) => {
            return data.evaluationOfPreviousWeeks[key][1];
        });

        // pole jsou otočený, tak musím reversnout
        setChartData({
            labels: labelsRev.reverse(),
            target: targetRev.reverse(),
            evaluation: evaluationRev.reverse(),
        });
    }, []);

    const prepData = {
        labels: chartData?.labels,
        datasets: [
            {
                label: t('Report.5S.trend.chart.dataset-evaluation'),
                data: chartData?.evaluation,
                fill: false,
                backgroundColor: '#118dff',
                borderColor: '#118dff',
            },
            {
                label: t('Report.5S.trend.chart.dataset-target'),
                data: chartData?.target,
                fill: false,
                backgroundColor: '#b3b3b3',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                title: {
                    display: true,
                    text: t('Report.5S.trend.chart.y-title'),
                },
            },
            x: {
                title: {
                    display: true,
                    text: t('Report.5S.trend.chart.x-title'),
                },
            },
        },
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.sectionHeadline}>
                    {t('Report.5S.trend.headline')}
                </Typography>
            </Grid>
            <div
                style={{
                    height: '80%',
                    width: '100%',
                    padding: 15,
                }}
            >
                <Line data={prepData} options={options} />
            </div>
        </>
    );
};
