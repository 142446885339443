export const TASKS = '/tasks';

export const TASKS_ATTACHMENT = '/tasks/:parentId/attachment/:id';

export const PERMISSIONS = '/permissions';

export const LOCAL_USER = '/local-user';

export const LANGUAGE = '/language';

export const LANGUAGE_TEXT = '/language-text';

export const MASTER_DATA = '/master-data';

export const MASTER_DATA_VSMS = '/master-data/vsms';

export const MASTER_DATA_VSMS_PRODUCTION = '/master-data/vsms/:vsmsId/production';

export const MASTER_DATA_VSMS_PRODUCTION_ZONE =
    '/master-data/vsms/:vsmsId/production/:productionId/zone';
export const MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT =
    '/master-data/vsms/:vsmsId/production/:productionId/zone/:zoneId/audit';
export const MASTER_DATA_VSMS_PRODUCTION_ZONE_LEADER =
    '/master-data/vsms/:vsmsId/production/:productionId/zone/:zoneId/leader';
export const MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_AUDITOR =
    '/master-data/vsms/:vsmsId/production/:productionId/zone/:zoneId/audit/:auditId/auditor';
export const MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION =
    '/master-data/vsms/:vsmsId/production/:productionId/zone/:zoneId/audit/:auditId/question';
export const MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION_ANSWER =
    '/master-data/vsms/:vsmsId/production/:productionId/zone/:zoneId/audit/:auditId/question/:questionId/answer';

export const QUESTION_TYPES = '/question-type';

export const HIERARCHY = '/hierarchy';

export const AUDIT_TYPE = '/audit-type';

export const AUDIT_TYPE_LAYOUT = '/audit-type-layout';

export const SWAGGER = '/swagger/index.html';

export const INFO_PAGE = '/info';

export const LOGIN_PAGE = '/login';

export const REPORTS = `${MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT}/:auditId/reports`;

export const REPORTS_PRODUCTION_AUDIT = `/reports/production/:productionId/audit/:auditId`;

export const REPORTS_AUDIT_S5_SELECT = `/reports/select-report/5S`;

export const REPORTS_AUDIT_S5 = `/reports/audit/5S/:id`;

export const REPORTS_TASKS = `/reports/tasks`;

export const REPORT_FORM_SELECT = `/reports/select-report`;

export const AUDIT = `/audit`;

export const AUDIT_ZONE = `/audit/zone`;

export const AUDIT_QUESTION = `/audit/zone/:zoneId/questions/`;

export const appPaths = {
    TASKS,
    TASKS_ATTACHMENT,
    PERMISSIONS,
    LOCAL_USER,
    LANGUAGE,
    LANGUAGE_TEXT,
    MASTER_DATA,
    QUESTION_TYPES,
    HIERARCHY,
    MASTER_DATA_VSMS,
    MASTER_DATA_VSMS_PRODUCTION,
    MASTER_DATA_VSMS_PRODUCTION_ZONE,
    MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT,
    MASTER_DATA_VSMS_PRODUCTION_ZONE_LEADER,
    MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_AUDITOR,
    MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION,
    MASTER_DATA_VSMS_PRODUCTION_ZONE_AUDIT_QUESTION_ANSWER,
    AUDIT_TYPE,
    AUDIT_TYPE_LAYOUT,
    SWAGGER,
    INFO_PAGE,
    LOGIN_PAGE,
    REPORTS,
    REPORT_FORM_SELECT,
    REPORTS_TASKS,
    REPORTS_PRODUCTION_AUDIT,
    REPORTS_AUDIT_S5,
    REPORTS_AUDIT_S5_SELECT,
    AUDIT,
    AUDIT_ZONE,
    AUDIT_QUESTION,
};
