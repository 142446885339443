export interface CreateAnswer {
    id: number;
    answer: string;
    comment: string;
    correctAnswer: string;
    ordering: string;
    questionText: string;
    questionTypeId: string;
    questionTypeName: string;
    [key: string]: string | number | null;
}

export interface Answer {
    id: number;
    answer: string;
    comment: string;
    correctAnswer: string;
    answerCustomText: string | null;
    answerScore: number | null;
    ordering: string;
    questionText: string;
    questionTypeId: string;
    questionTypeName: string;
    isDangerous: boolean | null;
    [key: string]: string | number | null | boolean;
}

export enum AnswerValue {
    YES = 'Yes',
    NO = 'No',
}

export type AnswerApiResponse = Array<Answer>;
