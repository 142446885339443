import React from 'react';
import { Button, FormGroup, Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { Report5sEvaluationOverview } from '../../types/Report5STypes';
import { useStyles } from './styles';
import { Vsm } from '../../types/VsmApiResponse';
import { RestaurantMenu } from '@material-ui/icons';
interface Props {
    vsmList: Vsm[];
    setSelectedVsmId: any;
    selectedVsmId?: number;
    setIsUnevaluatedList: any;
    reportVsmOverviewList: Report5sEvaluationOverview[];
}
export const Report5SUnevaluatedFilter = ({
    vsmList,
    setSelectedVsmId,
    selectedVsmId,
    setIsUnevaluatedList,
    reportVsmOverviewList,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container className={classes.flexContainer} style={{ height: '95vh' }}>
            <Grid
                item
                xs={12}
                className={classes.flexItem}
                style={{ maxHeight: '10vh' }}
                onClick={() => {
                    setIsUnevaluatedList(false);
                    setSelectedVsmId(undefined);
                }}
            >
                <Button fullWidth style={{ height: '100%', padding: '10px' }} variant="contained">
                    {t('Back')}
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.flexItem}
                style={{ maxHeight: '90vh', overflow: 'auto' }}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.sectionHeadline}>
                        {t('Report.5S.filter.vsm')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {vsmList.map((vsm: Vsm) => {
                            let report = reportVsmOverviewList.find(
                                (x: Report5sEvaluationOverview) => x.id === vsm.id
                            );
                            return (
                                <Button
                                    key={vsm.id}
                                    onClick={() => {
                                        if (selectedVsmId && vsm.id === selectedVsmId) {
                                            setSelectedVsmId(undefined);
                                            return;
                                        }
                                        setSelectedVsmId(vsm.id);
                                    }}
                                    style={{ margin: '5px 5px 0px 5px', borderRadius: '0px' }}
                                    className={
                                        report && report.isOk ? classes.isOk : classes.isNotOk
                                    }
                                >
                                    {selectedVsmId && vsm.id === selectedVsmId ? (
                                        <input
                                            type="checkbox"
                                            checked
                                            disabled
                                            style={{ marginRight: 5 }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {vsm.name}
                                </Button>
                            );
                        })}
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
    );
};
