import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from '../../contexts/translationContext';
import { Task, TaskState } from '../Tasks/types';
import moment from 'moment';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Loader } from '../../components/Loader/Loader';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface Props {
    taskList: Task[];
    showOnlyStateFromChart: TaskState | null;
    onFilterByState: (state: TaskState | null) => void;
}
interface ChartData {
    closed: number[];
    opened: number[];
    noSolution: number[];
    labels: string[];
}
export const ReportTasksChart = ({ taskList, showOnlyStateFromChart, onFilterByState }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [chartData, setChartData] = useState<ChartData>();
    const [solvedIn4Weeks, setSolvedIn4Weeks] = useState<number>();
    const [tasksIn10Weeks, setTasksIn10Weeks] = useState<number>();
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        // labely
        let tmpLabels: number[] = [];
        for (let index = 0; index <= 10; index++) {
            tmpLabels = [moment(new Date()).add(-index, 'week').week(), ...tmpLabels];
        }
        let tmpClosed = Array.from(Array(tmpLabels.length).fill(0)) as number[];
        let tmpOpened = Array.from(Array(tmpLabels.length).fill(0)) as number[];
        let tmpNoSolution = Array.from(Array(tmpLabels.length).fill(0)) as number[];
        let tmpSolvedIn4Weeks = 0;
        let tmpTasksIn10Weeks = 0;
        taskList.map((task: Task) => {
            // zeleně
            if (task.state === TaskState.FINISHED) {
                let weekNumber = moment(task.created).week();
                let index = tmpLabels.findIndex((label: number) => label === weekNumber);
                tmpClosed[index] += 1;
                // počet vyřešených úkolů do 4 týdnů (28 dní) jen z úkolů za posledních 10 týdnů (70 dní))
                let solvingDays = moment(task.actionSolvedDate).diff(moment(task.created), 'days');
                let daysTillCreated = moment(new Date()).diff(moment(task.created), 'days');

                if (solvingDays <= 28 && daysTillCreated <= 70) tmpSolvedIn4Weeks += 1;
                if (daysTillCreated <= 70) tmpTasksIn10Weeks += 1;
                return;
            }
            // orange
            if (task.state !== TaskState.FINISHED && task.state !== TaskState.CREATED) {
                let weekNumber = moment(task.created).week();
                let index = tmpLabels.findIndex((label: number) => label === weekNumber);
                tmpOpened[index] += 1;
                return;
            }
            // červeně
            if (task.state === TaskState.CREATED) {
                let weekNumber = moment(task.created).week();
                let index = tmpLabels.findIndex((label: number) => label === weekNumber);
                tmpNoSolution[index] += 1;
                return;
            }
        });

        setSolvedIn4Weeks(tmpSolvedIn4Weeks);
        setTasksIn10Weeks(tmpTasksIn10Weeks);
        setChartData({
            labels: tmpLabels.map((week: number) => week.toString()),
            closed: tmpClosed,
            opened: tmpOpened,
            noSolution: tmpNoSolution,
        });
    }, [taskList]);

    useEffect(() => {
        if (!chartData) return;

        const _data: any = {
            labels: chartData.labels,
            datasets: [
                {
                    label: t('Report.task.chart.dataset-1'),
                    data: chartData.closed,
                    backgroundColor:
                        !showOnlyStateFromChart || showOnlyStateFromChart === TaskState.FINISHED
                            ? 'rgb(0, 255, 0)'
                            : 'rgb(230, 230, 230)',
                },
                {
                    label: t('Report.task.chart.dataset-2'),
                    data: chartData.opened,
                    backgroundColor:
                        !showOnlyStateFromChart || showOnlyStateFromChart === TaskState.ASSIGNED
                            ? 'rgb(255,165,0)'
                            : 'rgb(230, 230, 230)',
                },
                {
                    label: t('Report.task.chart.dataset-3'),
                    data: chartData.noSolution,
                    backgroundColor:
                        !showOnlyStateFromChart || showOnlyStateFromChart === TaskState.CREATED
                            ? 'rgb(255, 0, 0)'
                            : 'rgb(230, 230, 230)',
                },
            ],
        };

        const _options: any = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: t('Report.task.chart.headline'),
                },
            },

            scales: {
                x: {
                    title: {
                        display: true,
                        text: t('Report.task.chart.x-title'),
                    },
                    stacked: true,
                },
                y: {
                    title: {
                        display: true,
                        text: t('Report.task.chart.y-title'),
                    },
                    ticks: {
                        stepSize: 1,
                    },
                    stacked: true,
                },
            },
            onClick: (evt, elements) => {
                if (elements.length > 0) {
                    const datasetIndex = elements[0].datasetIndex;
                    let state: TaskState | null = null;
                    if (datasetIndex === 0) state = TaskState.FINISHED;
                    if (datasetIndex === 1) state = TaskState.ASSIGNED;
                    if (datasetIndex === 2) state = TaskState.CREATED;

                    onFilterByState(state!);
                }
            },
        };

        setData(_data);
        setOptions(_options);
    }, [chartData, showOnlyStateFromChart]);

    if (!options || !data) return <Loader />;

    return (
        <Grid container style={{ height: '45vh', alignItems: 'center', justifyContent: 'center' }}>
            <Grid
                item
                xs={9}
                style={{
                    height: '100%',
                    width: '100%',
                    margin: '0 auto',
                    textAlign: 'center',
                }}
            >
                {taskList.length > 0 && <Bar options={options} data={data} />}
            </Grid>
            <Grid
                item
                xs={3}
                style={{
                    padding: 10,
                }}
            >
                <Box
                    p={1}
                    style={{
                        border: '1px solid black',
                        borderBottom: 'none',
                        width: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography>{t('Report.task.chart.box-1.info-label')}</Typography>
                    <Typography variant="h5">{moment(new Date()).week()}</Typography>
                </Box>
                <Box
                    p={1}
                    style={{
                        border: '1px solid black',
                        width: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    className={
                        solvedIn4Weeks &&
                        tasksIn10Weeks &&
                        (solvedIn4Weeks / tasksIn10Weeks) * 100 > 60
                            ? classes.boxChartSolvedOk
                            : classes.boxChartSolvedNotOk
                    }
                >
                    <Typography>{t('Report.task.chart.box-2.info-label')}</Typography>
                    <Typography variant="h5">
                        {solvedIn4Weeks && solvedIn4Weeks > 0 && tasksIn10Weeks
                            ? ((solvedIn4Weeks / tasksIn10Weeks) * 100).toFixed(2)
                            : 0}{' '}
                        %
                    </Typography>
                </Box>
                {showOnlyStateFromChart && (
                    <Box>
                        <Button
                            onClick={() => onFilterByState(null)}
                            variant="contained"
                            style={{
                                margin: 5,
                                backgroundColor: '#ea8503',
                                color: 'white',
                            }}
                        >
                            {t('Report.task.chart.reset-filter')}
                        </Button>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};
