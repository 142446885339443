import { Box } from '@material-ui/core';
import React, { FC, MutableRefObject } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { ErrorPage } from '../../components/Snackbar';

interface Props {
    loading?: boolean;
    error?: any;
    children: any;
    ref?: MutableRefObject<null> | null;
    style?: any;
    id?: any;
}

export const PageWrapper: FC<Props> = ({
    children,
    loading,
    error,
    ref,
    style,
    ...props
}: Props) => {
    if (error) return <ErrorPage error={error} />;
    if (loading) return <Loader />;
    return (
        <div ref={ref} style={style} {...props}>
            <Box>{children}</Box>
        </div>
    );
};
