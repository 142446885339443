import * as yup from 'yup';

export const cFieldNames = {
    netId: 'netId',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.netId]: yup.string(),
    });
};
