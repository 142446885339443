import { Button, LinearProgress } from '@material-ui/core';
import React from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { VsmApiResponse } from '../../types/VsmApiResponse';
import StickyHeadTable from '../../components/Table/Table';
import { Columns } from './Columns';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { AlertDialog } from '../../components/AlertDialog';
import { appPaths } from '../../paths';
import { UserRoles } from '../../types/applicationUser.types';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

interface Props {}

export const MasterData = ({}: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { data, error, mutate, isValidating } = useSWR<VsmApiResponse>(
        `${API_URL.VSM_LIST}`,
        fetcher
    );

    const onDelete = () => {
        mutate();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.VSM_DELETE,
            onDelete,
        });

    const handleAddItem = () => history.push(`${appPaths.MASTER_DATA_VSMS}/new/0`);

    const handleDetailItem = ({ id }) => history.push(`${appPaths.MASTER_DATA_VSMS}/${id}`);

    if (!data) {
        return <Loader />;
    }
    return (
        <PageWrapper loading={!data || isValidating} error={error}>
            <HeaderPage
                stepName={t('Master-data.hierarchy.main-headline')}
                buttonSection={
                    <Button variant="contained" color="primary" onClick={handleAddItem}>
                        {t('Master-data.hierarchy.add-vsm')}
                    </Button>
                }
                buttonSectionPermission={[UserRoles.Admin]}
            />
            <StickyHeadTable
                rows={data}
                columns={Columns(t)}
                handleDeleteItem={handleDeleteAttempt}
                handleAddItem={handleAddItem}
                handleDetailItem={handleDetailItem}
                addItemAndDeletePermissions={[UserRoles.Admin]}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t('Master-data.hierarchy.message-delete-confirm')}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
};
