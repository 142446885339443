/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import axios, { AxiosError } from 'axios';

function overrideIsAxiosError(override: any): override is AxiosError {
    return (
        typeof override?.response === 'object' &&
        override?.code === 'string' &&
        override?.isAxiosError === 'boolean'
    );
}

interface CallApi {
    url: string;
    callAfterSuccess?: (arg: any | void) => void;
    callAfterError?: (arg: void) => void;
}

interface PostApi extends CallApi {
    // eslint-disable-next-line @typescript-eslint/ban-types
    data?: object;
    params?: Record<string, string | number>;
}

interface DeleteApi extends CallApi {
    id?: any;
}

interface GetApi extends CallApi {
    params: Record<string, string | number>;
}

const axiosHandleErrors = (error: AxiosError) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        switch (error.response.status) {
            case 403:
                error.message = 'Access Forbidden';
                break;
            default:
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.statusText);
                console.log(error.response.headers);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
};

export async function postApi<T>({ url, data, params, callAfterError, callAfterSuccess }: PostApi) {
    try {
        const response = await axios.post<T>(
            url,
            {
                ...data,
            },
            { params }
        );
        if (callAfterSuccess != null) callAfterSuccess(response);
        return response.data;
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        //if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
}

export async function postApiFormData<T>({
    url,
    data,
    params,
    callAfterError,
    callAfterSuccess,
}: PostApi) {
    try {
        const response = await axios.post<T>(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
            },
        });
        if (callAfterSuccess != null) callAfterSuccess(response);
        return response.data;
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        //if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
}

export const putApi = async ({ url, data, params, callAfterError, callAfterSuccess }: PostApi) => {
    try {
        const response = await axios.put(
            url,
            {
                ...data,
            },
            { params }
        );
        if (callAfterSuccess != null) callAfterSuccess(response);

        return response.data;
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        //if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
};

export const deleteApi = async ({ url, id, callAfterError, callAfterSuccess }: DeleteApi) => {
    try {
        await axios({
            method: 'delete',
            url,
            params: id
                ? {
                      id,
                  }
                : null,
        });
        if (callAfterSuccess != null) callAfterSuccess('');
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        //if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
};

export async function getItemApi<T>({ url, params, callAfterError, callAfterSuccess }: GetApi) {
    try {
        const response = await axios.get<T>(url, {
            params,
        });
        if (callAfterSuccess != null) callAfterSuccess(response);
        return response.data;
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        // if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
}

export async function getListApi<T>({ url, params, callAfterError, callAfterSuccess }: GetApi) {
    try {
        const response = await axios.get<Array<T>>(url, {
            params,
        });
        if (callAfterSuccess != null) callAfterSuccess(response);
        return response.data;
    } catch (error) {
        if (overrideIsAxiosError(error)) {
            axiosHandleErrors(error);
        }
        //if (!hideNotifications) NotificationManager.error(error.message, 'Error');
        if (callAfterError != null) callAfterError();
    }
}

export interface SwrError extends Error {
    status?: number;
    statusText?: string;
}

export const fetcher = async (url) => {
    const res = await fetch(url);

    if (!res.ok) {
        const error: SwrError = new Error('An error occurred while fetching the data.');
        error.status = res.status;
        error.statusText = res.statusText;
        throw error;
    }

    return res.json();
};
