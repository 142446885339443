import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { ReportLayout3 } from '../../types/Report5STypes';
import moment from 'moment';
import { dateShowFormat } from '../../types/core/date.types';
import { AuditType } from '../../types/AuditTypeApiResponse';
import { green, red, useStyles } from './styles';
interface Props {
    data: ReportLayout3;
    auditType: AuditType;
}
export const Report5SCurrentEval = ({ data, auditType }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [totalEval, setTotalEval] = useState<number>(0);
    const [totalTarget, setTotalTarget] = useState<number>(0);

    useEffect(() => {
        let totalEval = 0;
        let totalTarget = 0;
        Object.keys(data.evaluationOfLastWeek).forEach((key: any, index) => {
            totalEval = totalEval + data?.evaluationOfLastWeek[key][1];
            totalTarget = totalTarget + data?.evaluationOfLastWeek[key][0];
        });
        setTotalEval(totalEval);
        setTotalTarget(totalTarget);
    }, []);

    const getBackgroudColor = (target: number, evaluation: number) => {
        if (target === 0 && evaluation === 0) return green;
        if (target === evaluation) return green;
        if (target !== 0 && evaluation === 0) return red;
        if ((evaluation / target) * 100 < 80) {
            return red;
        } else {
            return green;
        }
    };

    return (
        <Grid container style={{ height: '100%' }}>
            <Grid item xs={9} style={{ overflow: 'auto' }}>
                <Typography variant="h2" className={classes.sectionHeadline}>
                    {t('Report.5S.current-evaluation')}
                </Typography>
                <TableContainer style={{ height: '35vh' }}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader} component="th">
                                    {auditType.name}
                                </TableCell>
                                <TableCell className={classes.tableHeader} component="th">
                                    {t('Report.5S.table-header-2')}
                                </TableCell>
                                <TableCell className={classes.tableHeader} component="th">
                                    {t('Report.5S.table-header-3')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ height: 'max-content' }}>
                            {Object.keys(data.evaluationOfLastWeek).map((key: any, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {Object.keys(data.evaluationOfLastWeek)[index]}
                                        </TableCell>
                                        <TableCell>{data?.evaluationOfLastWeek[key][0]}</TableCell>
                                        <TableCell
                                            style={{
                                                backgroundColor: getBackgroudColor(
                                                    data?.evaluationOfLastWeek[key][0],
                                                    data?.evaluationOfLastWeek[key][1]
                                                ),
                                            }}
                                        >
                                            {data?.evaluationOfLastWeek[key][1]}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {totalTarget !== 0 && totalEval !== 0 && (
                <Grid
                    item
                    xs={3}
                    style={{
                        borderLeft: '1px solid #000',
                        backgroundColor: getBackgroudColor(totalTarget, totalEval),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box p={3} style={{ textAlign: 'center' }}>
                        <Typography className={classes.numberSectionHeadline} variant="h2">
                            {t('Report.5S.total-current-eval')}
                        </Typography>
                        <Typography className={classes.numberSection}>{totalEval}</Typography>
                        <Typography>{t('Report.5S.last-eval')}</Typography>
                        <Typography>
                            {moment(data.evaluationOfLastWeekLastDate).format(dateShowFormat)}
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};
