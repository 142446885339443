import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    zoneOriginId: 'zoneOriginId',
    zoneDestinationId: 'zoneDestinationId',
    copyAuditors: 'copyAuditors',
    vmsId: 'vmsId',
    productionId: 'productionId',
    auditId: 'auditId',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.vmsId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.productionId]: yup.string().nullable(),
        [cFieldNames.zoneDestinationId]: yup.string().nullable(),
        [cFieldNames.zoneOriginId]: yup.string().nullable(),
    });
};
