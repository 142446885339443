import React from 'react';
import { DesktopContent } from '../../DesktopContent';
import { useAuthContext } from '../../contexts/AuthContext';
import { LoginPage } from '../../pages/LoginPage';
import { Route, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { AppAuditContent } from '../../AppAuditContent';
import { offContextRoutes } from '../../appRoutes';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { QuestionHierarchyPrint } from '../../pages/Print/QuestionHierarchyPrint';

export enum ContextRoutes {
    Desktop = '',
    AuditMobile = 'audit',
    ReportDesktop = 'reports',
    Print = 'print',
}

const DesktopApp = () => {
    const { appUser, isLoading: isUserLoading } = useAuthContext();
    const { lang } = useTranslation();

    if (!lang || isUserLoading) return <Loader />;
    // if (!appUser) return <LoginPage />;
    return (
        <>
            <DesktopContent />
        </>
    );
};

const AuditApp = () => {
    const { appUser, isLoading: isUserLoading } = useAuthContext();
    const { lang } = useTranslation();

    if (!lang || isUserLoading) return <Loader />;
    // if (!appUser) return <LoginPage />;
    return (
        <div style={{ height: '100%', backgroundColor: 'black' }}>
            <AppAuditContent />
        </div>
    );
};

const ReportApp = () => {
    const { lang } = useTranslation();
    if (!lang) return <Loader />;
    return (
        <>
            {offContextRoutes.map((e, index) => (
                <Route exact path={e.path} key={index} component={e.component} />
            ))}
        </>
    );
};

export const RouterContainer = () => {
    const { pathname } = useLocation();

    const routePrefix = pathname.split('/')[1];
    if (routePrefix === ContextRoutes.AuditMobile) return <AuditApp />;
    if (routePrefix === ContextRoutes.ReportDesktop) return <ReportApp />;
    if (routePrefix === ContextRoutes.Print) return <QuestionHierarchyPrint />;
    return <DesktopApp />;
};
