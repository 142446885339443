import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { API_URL } from '../../apiTypes';
import { Columns } from '../../pages/Audit/Columns';
import StickyHeadTable from '../../components/Table/Table';
import { AlertDialog } from '../../components/AlertDialog';
import { appPaths } from '../../paths';
import { AuditRouterParams } from '../../pages/Audit';
import { Auditor, AuditorApiResponse } from '../../types/AuditorApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { useTranslation } from '../../contexts/translationContext';

interface Props extends AuditRouterParams {
    data?: AuditorApiResponse;
    mutateAudits: () => void;
    auditMutate: () => void;
    handleAddItem: () => void;
    handleEditItem: (item: Auditor) => void;
    editPermission?: Array<UserRoles>;
}

export const AuditTableAuditorsContainer = ({
    data,
    zoneId,
    productionId,
    vsmsId,
    id,
    auditMutate,
    mutateAudits,
    handleAddItem,
    handleEditItem,
    editPermission,
}: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onDelete = () => {
        mutateAudits();
        auditMutate();
    };
    const tableItemRedirect = ({ id: itemId }) =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${id}/auditor/${itemId}`
        );

    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDITOR_DELETE,
            onDelete,
        });

    return (
        <>
            <StickyHeadTable
                rows={data}
                columns={Columns(t)}
                // handleDetailItem={tableItemRedirect}
                handleDeleteItem={handleDeleteAttempt}
                handleEditItem={handleEditItem}
                handleAddItem={handleAddItem}
                addItemAndDeletePermissions={editPermission}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t(
                    'Master-data.hierarchy.detail.production.zone.audit.tab-1.message-delete-confirm'
                )}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </>
    );
};
