import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../contexts/translationContext';

export interface ISubmitButton {
    visible?: boolean;
    goBackUrl?: string;
    buttonTitle?: string;
    disabled?: boolean;
}
export const SubmitButton = ({ visible, goBackUrl, buttonTitle, disabled }: ISubmitButton) => {
    const { t } = useTranslation();
    return visible ?? true ? (
        <Grid item xs={12} md={12} lg={12}>
            <Box mt={1}>
                <Grid container spacing={1} justifyContent="flex-end">
                    {goBackUrl && (
                        <Grid item>
                            <Link to={goBackUrl}>
                                <Button variant="outlined" color="primary">
                                    {t('Back')}
                                </Button>
                            </Link>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                        >
                            {buttonTitle ?? t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    ) : (
        <></>
    );
};
