import React from 'react';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { Report5sEvaluationOverview } from '../../types/Report5STypes';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { useStyles } from './styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { generalDateTimeWithSecondsShowFormat } from '../../types/core/date.types';
interface Props {
    reportProductionOverviewList: Report5sEvaluationOverview[];
    reportZoneOverviewList: Report5sEvaluationOverview[];
    setSelectedProductionId: (productionId: number | undefined) => void;
    selectedProductionId: number | undefined;
}
export const Report5SUnevaluatedList = ({
    reportProductionOverviewList,
    reportZoneOverviewList,
    setSelectedProductionId,
    selectedProductionId,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const defaultColumns = [
        {
            field: 'daysFromLastEvaluation',
            headerName: t('Report.5S.unevaluated.table-col-2'),
            flex: 0.2,
            minWidth: 180,
        },
        {
            field: 'lastEvaluation',
            headerName: t('Report.5S.unevaluated.table-col-3'),
            flex: 0.2,
            minWidth: 180,
            renderCell: (data: any) => {
                return data.row.lastEvaluation !== null
                    ? moment(data.row.lastEvaluation).format(generalDateTimeWithSecondsShowFormat)
                    : t('Report.5S.unevaluated.no-evaluation');
            },
        },
        {
            field: 'isOk',
            headerName: 'hidden',
            hide: true,
            flex: 0.2,
            minWidth: 180,
        },
    ];

    const productionColumns = [
        {
            field: 'name',
            headerName: t('Report.5S.unevaluated.table-col-1'),
            flex: 0.2,
            minWidth: 150,
            renderCell: (data: any) => {
                if (selectedProductionId && data.row.id === selectedProductionId)
                    return (
                        <>
                            <input type="checkbox" checked disabled style={{ marginRight: 5 }} />
                            {data.row.name}
                        </>
                    );

                return <>{data.row.name}</>;
            },
        },
        ...defaultColumns,
    ];

    const zoneColumns = [
        {
            field: 'name',
            headerName: t('Report.5S.unevaluated.table-col-zoneName'),
            flex: 0.2,
            minWidth: 150,
        },
        ...defaultColumns,
    ];

    return (
        <PageWrapper loading={!reportProductionOverviewList}>
            <HeaderPage
                stepName={t('Report.5S.unevaluated.main-headline')}
                heading={t('Report.5S.unevaluated.main-headline')}
                marginBottom={0}
            />
            <Grid item container xs={12} sm={12} md={12}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                        borderRight: 'solid 1px black',
                        height: '95vh',
                    }}
                >
                    <div
                        style={{
                            minWidth: isMobileView ? '98vw' : '100%',
                            padding: '5px',
                        }}
                    >
                        <DataGridPro
                            autoHeight
                            rows={reportProductionOverviewList}
                            columns={productionColumns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            density="compact"
                            disableSelectionOnClick
                            getRowClassName={(params) => {
                                return params.row.isOk ? classes.isOk : classes.isNotOk;
                            }}
                            onRowClick={(e) => {
                                if (selectedProductionId && e.row.id === selectedProductionId) {
                                    setSelectedProductionId(undefined);
                                    return;
                                }

                                setSelectedProductionId(e.row.id);
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div style={{ minWidth: isMobileView ? '98vw' : '100%', padding: '5px' }}>
                        <DataGridPro
                            autoHeight
                            rows={reportZoneOverviewList}
                            columns={zoneColumns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            density="compact"
                            disableSelectionOnClick
                            getRowClassName={(params) => {
                                return params.row.isOk ? classes.isOk : classes.isNotOk;
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
