import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    languageCountryCode: 'languageCountryCode',
    referenceText: 'referenceText',
    translatedText: 'translatedText',
    comment: 'comment',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.languageCountryCode]: yup
            .string()
            .required(t(cFormValidationMessages.required)),
        [cFieldNames.referenceText]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.translatedText]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.comment]: yup.string(),
    });
};
