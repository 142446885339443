import React, { FC } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { ReportItem } from './ReportItem';
import { ReportAttachments, ReportLevels } from '../../types/ReportsTypes';

interface Props {
    title: string;
    items?: ReportLevels;
    attachements: ReportAttachments;
    selectedCheckListId: number | undefined;
    onSelectItem: (id: number) => void;
}

export const ReportColumn: FC<Props> = ({
    title,
    items,
    attachements,
    selectedCheckListId,
    onSelectItem,
}) => {
    return (
        <Grid container item md={4} direction={'column'} style={{ width: '100%' }}>
            <Grid item md={12}>
                <Grid container justifyContent="center">
                    <Typography>{title}</Typography>
                </Grid>
                <Grid item>
                    <Box mt={1}>
                        <Paper variant="outlined" elevation={10} style={{ minHeight: '100vh' }}>
                            <Grid container justifyContent="center" direction="column">
                                {items &&
                                    items.map((i, index) => (
                                        <ReportItem
                                            key={i.checklistId + index + i.date}
                                            item={i}
                                            onSelectItem={onSelectItem}
                                            selectedCheckListId={selectedCheckListId}
                                            attachmentsCount={
                                                attachements.filter(
                                                    (x) => x.checklistId === i.checklistId
                                                ).length
                                            }
                                        />
                                    ))}
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
