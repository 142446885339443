import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { Button, Grid } from '@material-ui/core';
import { PageWrapper } from '../../containers/PageWrapper';
import React from 'react';
import { UserRoles } from '../../types/applicationUser.types';
import StickyHeadTable from '../../components/Table/Table';
import useSWR from 'swr';
import { AuditQuestionTypeApiResponse } from '../../types/AuditQuestionTypeApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { Columns } from './Columns';
import { appPaths } from '../../paths';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { AlertDialog } from '../../components/AlertDialog';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../contexts/translationContext';

export const AuditTypeList = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const history = useHistory();

    const {
        data: auditType,
        error: errorAuditType,
        isValidating,
        mutate,
    } = useSWR<AuditQuestionTypeApiResponse>(`${API_URL.AUDIT_TYPE_LIST}`, fetcher);

    const handleAddItem = ({ id }) => history.push(`${appPaths.AUDIT_TYPE}/${id}`);

    const onDelete = () => {
        mutate();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDIT_TYPE_DELETE,
            onDelete,
        });

    return (
        <PageWrapper loading={isValidating} error={errorAuditType}>
            <HeaderPage
                stepName={t('Master-data.audit-types.main-headline')}
                buttonSectionPermission={editRoles}
                buttonSection={
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => handleAddItem({ id: 0 })}
                    >
                        {t('Master-data.audit-types.add-audit-type')}
                    </Button>
                }
            />
            <Grid item container xs={12} sm={12} md={12}>
                <StickyHeadTable
                    columns={Columns(t)}
                    handleDeleteItem={handleDeleteAttempt}
                    handleAddItem={() => handleAddItem({ id: 0 })}
                    handleEditItem={handleAddItem}
                    rows={auditType}
                    addItemAndDeletePermissions={editRoles}
                />
                <AlertDialog
                    open={openAlert}
                    title={t('Delete')}
                    content={t('Master-data.audit-types.message-delete-confirm')}
                    handleClose={handleAlertClose}
                    handleConfirm={handleAlertConfirm}
                />
            </Grid>
        </PageWrapper>
    );
};
