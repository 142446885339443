import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import LinearProgress from '@material-ui/core/LinearProgress';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import StickyHeadTable from '../../components/Table/Table';
import { appPaths } from '../../paths';
import { AlertDialog } from '../../components/AlertDialog';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { Production } from '../../types/ProductionApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { ProductionRouteParams } from './ProductionNew';
import { Columns } from './Columns';
import { ProductionDetailFormContainer } from '../../containers/ProductionDetailForm';
import { ZoneApiResponse } from '../../types/ZoneApiResponse';
import { VsmApiResponse } from '../../types/VsmApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

export const ProductionList = () => {
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const { t } = useTranslation();
    const { id, vsmsId } = useParams<ProductionRouteParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const history = useHistory();
    const {
        data,
        error,
        mutate: mutateZones,
    } = useSWR<ZoneApiResponse>(`${API_URL.ZONES_LIST}?parentId=${numericId}`, fetcher);

    const {
        data: vsms,
        error: vsmsError,
        mutate: mutateVsms,
    } = useSWR<VsmApiResponse>(`${API_URL.VSM_LIST}?parentId=${numericVsmsId}`, fetcher);
    const {
        data: prodDetail,
        error: prodError,
        mutate: prodMutate,
    } = useSWR<Production>(`${API_URL.PRODUCTION_ITEM}?id=${numericId}`, fetcher);

    const onDelete = () => {
        mutateZones();
        prodMutate();
        mutateVsms();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.ZONES_DELETE,
            onDelete,
        });

    const tableItemRedirect = ({ id: itemId }) =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericId}/zone/${itemId}`
        );
    const handleAddItem = () =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericId}/zone/new/0`
        );

    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}`;

    if (!prodDetail || !data || !vsms) return <Loader />;

    return (
        <PageWrapper
            loading={!prodDetail || !data || !vsms}
            error={error || prodError || vsmsError}
        >
            <HeaderPage
                heading={prodDetail.name}
                stepName={t('Master-data.hierarchy.detail.production.main-headline')}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 4 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 2 },
                ]}
                buttonSectionPermission={editRoles}
                buttonSection={
                    <Button variant="contained" color="primary" onClick={handleAddItem}>
                        {t('Master-data.hierarchy.detail.production.add-zone')}
                    </Button>
                }
            />
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <ProductionDetailFormContainer
                        mutate={prodMutate}
                        vsms={vsms}
                        data={prodDetail}
                        id={numericId}
                        vsmsId={numericVsmsId}
                        editPermission={editRoles}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
                <Grid item md={8}>
                    <StickyHeadTable
                        addItemAndDeletePermissions={editRoles}
                        rows={data}
                        columns={Columns(t)}
                        handleDetailItem={tableItemRedirect}
                        handleDeleteItem={handleDeleteAttempt}
                        handleAddItem={handleAddItem}
                    />
                    <AlertDialog
                        open={openAlert}
                        title={t('Delete')}
                        content={t(
                            'Master-data.hierarchy.detail.production.message-delete-confirm'
                        )}
                        handleClose={handleAlertClose}
                        handleConfirm={handleAlertConfirm}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
