import React from 'react';
import { Typography } from '@material-ui/core';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { useStyles } from '../styles';
import { useTranslation } from '../../../../contexts/translationContext';

export const QuestionOfflineIndicator = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <>
            <Typography variant="h2" className={classes.warningText}>
                {t('Audit.layout.summary.text-offline')}
            </Typography>
            <WifiOffIcon style={{ color: '#fff', fontSize: '3em' }} />
        </>
    );
};
