import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import { NotificationContainer } from 'react-notifications';
import { theme } from './assets/theme';
import { AuthProvider } from './contexts/AuthContext';
import { NavigationProvider } from './contexts/navigation';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RouterContainer } from './containers/Router/RouterContainer';
import { LanguageProvider } from './contexts/LanguageContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { TranslationProvider } from './contexts/translationContext';
import { LoadingProvider } from './contexts/loadingContext';

export function App() {
    return (
        <ThemeProvider theme={theme}>
            <LoadingProvider>
                <AuthProvider>
                    <TranslationProvider>
                        <LanguageProvider>
                            <NavigationProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Suspense fallback={<div>loading</div>}>
                                        <Router>
                                            <RouterContainer />
                                        </Router>
                                    </Suspense>
                                </MuiPickersUtilsProvider>
                            </NavigationProvider>
                            <ToastContainer />
                            <NotificationContainer />
                        </LanguageProvider>
                    </TranslationProvider>
                </AuthProvider>
            </LoadingProvider>
        </ThemeProvider>
    );
}
