import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { isNil } from 'ramda';
import React from 'react';
import { cFieldNames, validationSchema } from './schema';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldText from '../../components/Form/FieldText';
import { Login, loginEmpty } from '../../pages/LoginPage/types';
import { Typography } from '@material-ui/core';
import FieldCheckBox from '../../components/Form/FieldCheckBox';
import { Lang } from '../../types/core/lang.types';
import FieldSelect from '../../components/Form/FieldSelect';
import { useHistory } from 'react-router-dom';
import { appPaths } from '../../paths';

interface Props extends IForm {
    onSubmit: (username: string, password: string, persist: boolean) => void;
    data?: Login | null;
    isMobileView?: boolean;
    languageList: Lang[];
    selectedLang: Lang | null;
    setSelectedLang: any;
    t: any;
}

export const LoginForm = ({
    onSubmit,
    data,
    isMobileView,
    languageList,
    selectedLang,
    setSelectedLang,
    t,
}: Props) => {
    const history = useHistory();

    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? loginEmpty : data}
            validationSchema={validationSchema(t)}
            onSubmit={(data) => onSubmit(data.username, data.password, data.persist)}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: isMobileView ? '80vw' : '30vw',
                            margin: '0 auto',
                            paddingTop: '10%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'h1'} style={{ fontSize: '2.5em' }}>
                                    {t('Login-page.main-headline')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    label={t('Login-page.username')}
                                    name={cFieldNames.username}
                                    component={FieldText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    label={t('Login-page.password')}
                                    type="password"
                                    name={cFieldNames.password}
                                    component={FieldText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    label={t('language-change.select-headline')}
                                    value={
                                        selectedLang?.countryCode === null
                                            ? ''
                                            : selectedLang?.countryCode
                                    }
                                    onChange={(e) => {
                                        let tmpLang = languageList?.find(
                                            (x: Lang) => x.countryCode === e.target.value
                                        );
                                        if (tmpLang) setSelectedLang(tmpLang);
                                    }}
                                    style={{ minWidth: 200 }}
                                    component={FieldSelect}
                                >
                                    {languageList.map((lang: Lang, index: number) => (
                                        <MenuItem value={lang.countryCode} key={index}>
                                            {lang.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ marginLeft: '5px' }}>
                                <Field
                                    name={cFieldNames.persist}
                                    label={t('Login-page.stay-logged-in')}
                                    component={FieldCheckBox}
                                />
                            </Grid>
                            <Grid item container spacing={2} justifyContent="flex-end">
                                <Button
                                    onClick={() => history.push(appPaths.TASKS)}
                                    style={{ marginRight: 10 }}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('Login-page.continue-without-sign-in')}
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    {t('Login-page.sign-in')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
