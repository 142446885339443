export interface CreateAuditType {
    id: number;
    [key: string]: string | number | boolean | null;
    name: string;
    dangerousFindings: boolean;
    observedPersonRequired: number | null;
}

export interface AuditType {
    id: number;
    [key: string]: string | number | boolean | null | undefined;
    name: string;
    layoutId?: number;
    layoutName?: string;
    observedPersonRequired?: number | null;
}

export interface AuditTypeSurvey {
    id: number;
    [key: string]: string | number | boolean | null;
    name: string;
    layoutId: number;
    layoutName: string;
    dangerousFindings: boolean;
}

export enum LevelVal {
    Level1 = 'Level 1',
    Level2 = 'Level 2',
    Level3 = 'Level 3',
}

export type AuditTypeSurveyApiResponse = Array<AuditTypeSurvey>;
export type AuditTypeApiResponse = Array<AuditType>;
