import React, { useEffect, useState } from 'react';
import { fetcher } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { Report5sEvaluationOverview, ReportLayout3 } from '../../types/Report5STypes';
import useSWR from 'swr';
import { Report5SCurrentEval } from './Report5SCurrentEval';
import { Loader } from '../../components/Loader/Loader';
import classNames from 'classnames';
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Report5SCurrentEvalChart } from './Report5SCurrentEvalChart';
import { AuditType } from '../../types/AuditTypeApiResponse';
import { AuditLayoutId } from '../../types/AuditMobile';
import { Report5STopicOfTheWeek } from './Report5STopicOfTheWeek';
import { Report5STrendEval } from './Report5STrendEval';
import { Report5SFilter } from './Report5SFilter';
import { useStyles } from './styles';
import { VsmApiResponse } from '../../types/VsmApiResponse';
import { Production, ProductionApiResponse } from '../../types/ProductionApiResponse';
import { Report5SUnevaluatedFilter } from './Report5SUnevaluatedFilter';
import { Report5SUnevaluatedList } from './Report5SUnevaluatedList';
import { useLocation } from 'react-router';
import { Zone, ZoneApiResponse } from '../../types/ZoneApiResponse';
import { ReportHeader } from '../../components/ReportHeader/ReportHeader';

/**
 * Report 5S
 * @returns
 */

export interface FilterParams {
    vsmIds: number[];
    productionIds: number[];
    zoneIds: number[];
}

export const Report5S = () => {
    const classes = useStyles();
    const location = useLocation();
    const [auditType, setAuditType] = useState<AuditType>();
    // pro unevaluated list potřebuju jen jedno vsm id
    const [selectedVsmId, setSelectedVsmId] = useState<number | undefined>(undefined);
    const [selectedProductionId, setSelectedProductionId] = useState<number | undefined>(undefined);
    const [vsmRequest, setVsmRequest] = useState<string>('');
    const [prodRequest, setProdRequest] = useState<string>('');
    const [zoneRequest, setZoneRequest] = useState<string>('');
    const [isUnevaluatedList, setIsUnevaluatedList] = useState<boolean>(false);

    const { data: auditTypeData, isValidating: loadingAuditTypes } = useSWR<AuditType[]>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher
    );

    useEffect(() => {
        if (!auditTypeData) return;
        // vybrat 5S audit type
        setAuditType(auditTypeData.find((r: AuditType) => r.layoutId === AuditLayoutId.Layout3));
    }, [auditTypeData]);

    const {
        data: data,
        isValidating: loadingAudits,
        mutate: mutateReportL3,
    } = useSWR<ReportLayout3>(
        auditType
            ? // z location.search odebrat první znak "?"
              `${API_URL.GET_REPORT_L3}?auditTypeId=${auditType.id}${
                  location.search.length > 0 ? location.search.substring(1) : ''
              }`
            : null,
        fetcher
    );

    const { data: reportVsmOverviewList } = useSWR<Report5sEvaluationOverview[]>(
        auditType && isUnevaluatedList
            ? `${API_URL.GET_REPORT_VSM_EVALUATION}?auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    const { data: reportProductionOverviewList } = useSWR<Report5sEvaluationOverview[]>(
        auditType && isUnevaluatedList
            ? `${API_URL.GET_REPORT_PRODUCTION_EVALUATION}?auditTypeId=${auditType.id}${
                  selectedVsmId ? `&vsmId=${selectedVsmId}` : ''
              }`
            : null,
        fetcher,
        {
            onSuccess: (res: Report5sEvaluationOverview[]) =>
                res.length > 1 &&
                res.sort((a, b) => (a.daysFromLastEvaluation > b.daysFromLastEvaluation ? -1 : 1)),
        }
    );

    const { data: reportZoneOverviewList } = useSWR<Report5sEvaluationOverview[]>(
        auditType && isUnevaluatedList
            ? `${API_URL.GET_REPORT_ZONE_EVALUATION}?auditTypeId=${auditType.id}${
                  selectedVsmId ? `&vsmId=${selectedVsmId}` : ''
              }${selectedProductionId ? `&selectedProductionId=${selectedProductionId}` : ''}`
            : null,
        fetcher,
        {
            onSuccess: (res: Report5sEvaluationOverview[]) =>
                res.length > 1 &&
                res.sort((a, b) => (a.daysFromLastEvaluation > b.daysFromLastEvaluation ? -1 : 1)),
        }
    );

    const parseUrlParamsToFilterParams = (params: string): FilterParams => {
        const paramsArr = params.split('&');
        let _vsmIds: number[] = [];
        let _productionIds: number[] = [];
        let _zoneIds: number[] = [];
        paramsArr.forEach((param) => {
            const paramArr = param.split('=');
            switch (paramArr[0]) {
                case 'vsmIdArr':
                    _vsmIds = [..._vsmIds, Number(paramArr[1])];
                    break;
                case 'productionIdArr':
                    _productionIds = [..._productionIds, Number(paramArr[1])];
                    break;
                case 'zoneIdArr':
                    _zoneIds = [..._zoneIds, Number(paramArr[1])];
                    break;
            }
        });

        return {
            vsmIds: _vsmIds,
            productionIds: _productionIds,
            zoneIds: _zoneIds,
        };
    };

    // Volá se při změně filtru. Nastaví URL parametry. Čímž dojde k refreshy.
    const filterChanged = (filterParams: FilterParams) => {
        let urlQuery = '';

        // vsm
        filterParams.vsmIds.forEach((item) => {
            urlQuery += `&vsmIdArr=${item}`;
        });
        // productions
        filterParams.productionIds.forEach((item) => {
            urlQuery += `&productionIdArr=${item}`;
        });
        // zones
        filterParams.zoneIds.forEach((item) => {
            urlQuery += `&zoneIdArr=${item}`;
        });

        window.location.search = urlQuery;
    };
    const [filterParams, setFilterParams] = useState<FilterParams>(
        parseUrlParamsToFilterParams(location.search)
    );

    const [filteredProductionList, setFilteredProductionList] = useState<Production[]>([]);
    const [filteredZoneList, setFilteredZoneList] = useState<Zone[]>([]);

    const { data: vsmList } = useSWR<VsmApiResponse>(
        auditType
            ? `${API_URL.VSM_LIST_FILTERED_BY_AUDIT_TYPE}?auditTypeArr=${auditType.id}`
            : null,
        fetcher
    );
    const { data: productionList } = useSWR<ProductionApiResponse>(
        auditType
            ? `${API_URL.PRODUCTION_LIST_FILTERED_BY_AUDIT_TYPE}?auditTypeArr=${auditType.id}`
            : null,
        fetcher,
        {
            onSuccess: (res: Production[]) => {
                let tmpFilteredProdList = res;
                if (filterParams.vsmIds.length !== 0) {
                    tmpFilteredProdList = res.filter(
                        (prod: Production) =>
                            filterParams.vsmIds.some((id: number) => id === prod.parentVsmId) ??
                            prod
                    );
                }
                setFilteredProductionList(tmpFilteredProdList);
            },
        }
    );
    const { data: zoneList } = useSWR<ZoneApiResponse>(
        auditType
            ? `${API_URL.ZONES_LIST_FILTERED_BY_AUDIT_TYPE}?auditTypeArr=${auditType.id}`
            : null,
        fetcher,
        {
            onSuccess: (res: Zone[]) => {
                let tmpFilteredZoneList = res;
                if (filterParams.vsmIds.length !== 0) {
                    tmpFilteredZoneList = res.filter(
                        (zone: Zone) =>
                            filterParams.productionIds.some(
                                (id: number) => id === zone.parentProductionId
                            ) ?? zone
                    );
                }
                setFilteredZoneList(tmpFilteredZoneList);
            },
        }
    );
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    if (
        !auditTypeData ||
        !vsmList ||
        !productionList ||
        !zoneList ||
        loadingAudits ||
        loadingAuditTypes ||
        !data ||
        auditType === undefined
    )
        return <Loader />;
    return (
        <Grid
            container
            className={classNames('fullScreen-wrapper', {
                'fullScreen-wrapper--active': true,
            })}
            style={{
                padding: 0,
                margin: '0 auto',
            }}
        >
            <ReportHeader title={auditType?.name} />
            {/* left panel */}
            <Grid item xs={2}>
                <Grid item xs={12}>
                    {!isUnevaluatedList && data && (
                        <Report5SFilter
                            data={data}
                            vsmList={vsmList}
                            productionList={filteredProductionList}
                            zoneList={filteredZoneList}
                            setIsUnevaluatedList={setIsUnevaluatedList}
                            onFilterChange={filterChanged}
                            defaultFilterParams={filterParams}
                        />
                    )}
                    {isUnevaluatedList && reportVsmOverviewList && (
                        /* unevaluated filter of VSM */
                        <Report5SUnevaluatedFilter
                            vsmList={vsmList}
                            setSelectedVsmId={setSelectedVsmId}
                            selectedVsmId={selectedVsmId}
                            setIsUnevaluatedList={setIsUnevaluatedList}
                            reportVsmOverviewList={reportVsmOverviewList}
                        />
                    )}
                </Grid>
            </Grid>
            {isUnevaluatedList &&
                auditType &&
                reportProductionOverviewList &&
                reportZoneOverviewList && (
                    <Grid
                        item
                        md={10}
                        className={classes.flexContainer}
                        style={{
                            borderLeft: '1px solid #000',
                            borderTop: 'none',
                            borderBottom: 'none',
                        }}
                    >
                        <Report5SUnevaluatedList
                            reportProductionOverviewList={reportProductionOverviewList}
                            reportZoneOverviewList={reportZoneOverviewList}
                            setSelectedProductionId={setSelectedProductionId}
                            selectedProductionId={selectedProductionId}
                        />
                    </Grid>
                )}
            {!isUnevaluatedList && (
                <>
                    {/* mid panel */}
                    <Grid
                        item
                        md={5}
                        xs={10}
                        className={classes.flexContainer}
                        style={{
                            border: '1px solid #000',
                            borderTop: 'none',
                            borderBottom: 'none',
                        }}
                    >
                        {/* Current eval - chart */}
                        <Grid
                            item
                            xs={12}
                            className={classes.flexItemStretch}
                            style={{ borderBottom: '1px solid #000', maxHeight: '55vh' }}
                        >
                            {data && <Report5SCurrentEvalChart reportL3Data={data} />}
                        </Grid>
                        {/* Trend eval */}
                        <Grid item xs={12} style={{ maxHeight: '40vh' }}>
                            {data && <Report5STrendEval data={data} />}
                        </Grid>
                    </Grid>
                    {isMobileView && (
                        <Grid
                            item
                            xs={2}
                            className={classes.flexContainer}
                            style={{ height: '95vh' }}
                        ></Grid>
                    )}
                    {/* right panel */}
                    <Grid
                        item
                        md={5}
                        xs={10}
                        className={classes.flexContainer}
                        style={{
                            height: '95vh',
                            borderLeft: isMobileView ? '1px solid #000' : 'none',
                            borderTop: isMobileView ? '1px solid #000' : 'none',
                        }}
                    >
                        {/* Topic of the week */}
                        <Grid
                            item
                            xs={12}
                            className={classes.flexItemStretch}
                            style={{
                                borderBottom: '1px solid #000',
                                maxHeight: '55vh',
                                overflow: 'hidden',
                            }}
                        >
                            {data && <Report5STopicOfTheWeek data={data} />}
                        </Grid>
                        {/* Current eval - table */}
                        <Grid item xs={12} style={{ maxHeight: '40vh' }}>
                            {data && auditType && (
                                <Report5SCurrentEval data={data} auditType={auditType} />
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
