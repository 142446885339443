import { Grid, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translationContext';
import { Lang } from '../../types/core/lang.types';
import { Loader } from '../Loader/Loader';

interface Props {
    onClose: () => void;
}

export function LanguageChangerModal({ onClose }: Props) {
    const { t, changeLang, lang, languageList } = useTranslation();
    const [selectedLang, setSelectedLang] = useState<Lang | null>(lang);

    useEffect(() => {
        if (!selectedLang || !languageList) return;
        if (selectedLang === lang) return;
        changeLang(selectedLang);
        onClose();
    }, [selectedLang]);

    if (!languageList || !selectedLang) return <Loader />;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h1" component="h1" color="secondary">
                    {t('language-change.main-headline')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        {t('language-change.select-headline')}
                    </InputLabel>
                    <Select
                        label={t('language-change.select-headline')}
                        value={selectedLang.countryCode}
                        onChange={(e) => {
                            let tmpLang = languageList?.find(
                                (x: Lang) => x.countryCode === e.target.value
                            );
                            if (tmpLang) setSelectedLang(tmpLang);
                        }}
                        style={{ minWidth: 150 }}
                    >
                        {languageList.map((lang: Lang, index: number) => (
                            <MenuItem value={lang.countryCode} key={index}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
