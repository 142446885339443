import { Grid, Input, MenuItem, Select, Typography } from '@material-ui/core';
import { Field, Form, Formik, useFormik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { useTranslation } from '../../contexts/translationContext';
import { cFieldNamesChangeZone, validationSchemaChangeZone } from './schema';
import useSWR from 'swr';
import { Vsm, VsmApiResponse } from '../../types/VsmApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { Loader } from '../../components/Loader/Loader';
import FieldSelect from '../../components/Form/FieldSelect';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { Zone } from '../../types/ZoneApiResponse';

interface Props {
    onSubmit: (values: any) => void;
}

export const TaskActionModalChangeZoneForm = ({ onSubmit }: Props) => {
    const { t } = useTranslation();

    const [vsm, setVsm] = useState<number | null>(null);
    const [production, setProduction] = useState<number | null>(null);
    const [zone, setZone] = useState<number | null>(null);

    const { data: vsmList } = useSWR<VsmApiResponse>(`${API_URL.VSM_LIST}`, fetcher);
    const { data: productionList } = useSWR<ProductionApiResponse>(
        `${API_URL.PRODUCTION_LIST}`,
        fetcher
    );
    const { data: zoneList } = useSWR<Zone[]>(`${API_URL.ZONES_LIST}`, fetcher);

    if (!vsmList) return <Loader />;

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={validationSchemaChangeZone(t)}
                onSubmit={(values) => {
                    onSubmit(values);
                }}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} onChange={(e) => console.log(e)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant={'h2'}>
                                    {t('Tasks.detail.change-zone-modal-headline')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNamesChangeZone.vsmId}
                                    label={t('Tasks.detail.change-zone-vsm')}
                                    fullWidth
                                    component={FieldSelect}
                                    value={vsm}
                                    onChange={(e: any) => {
                                        setVsm(e.target.value);
                                        setProduction(null);
                                        setZone(null);
                                    }}
                                >
                                    {vsmList &&
                                        vsmList.map(({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNamesChangeZone.productionId}
                                    label={t('Tasks.detail.change-zone-production')}
                                    fullWidth
                                    component={FieldSelect}
                                    value={production}
                                    onChange={(e: any) => {
                                        setProduction(e.target.value);
                                        setZone(null);
                                    }}
                                >
                                    {vsm &&
                                        productionList &&
                                        productionList
                                            .filter((x) => x.parentVsmId === vsm)
                                            .map(({ id, name }) => (
                                                <MenuItem key={id} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNamesChangeZone.zoneId}
                                    label={t('Tasks.detail.change-zone-zone')}
                                    fullWidth
                                    component={FieldSelect}
                                    value={zone}
                                    onChange={(e: any) => setZone(e.target.value)}
                                >
                                    {production &&
                                        zoneList &&
                                        zoneList
                                            .filter((x) => x.parentProductionId === production)
                                            .map(({ id, name }) => (
                                                <MenuItem key={id} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                </Field>
                            </Grid>
                            <SubmitButton
                                visible={true}
                                buttonTitle={t('Save')}
                                disabled={(!vsm && !production) || !zone}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};
