import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    id: 'id',
    auditQuestionId: 'auditQuestionId',
    answerText: 'answerText',
    answerScore: 'answerScore',
    answerDescription: 'answerDescription',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.answerText]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.answerScore]: yup.number().required(t(cFormValidationMessages.required)),
        [cFieldNames.answerDescription]: yup.string().nullable(),
    });
};
