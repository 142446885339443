import React from 'react';
import { useAppUser } from '../../hooks/useAppUser';
import { UserRoles } from '../../types/applicationUser.types';
import { LanguageList } from './LanguageList';

export function LanguagePage() {
    const { appUser } = useAppUser();
    const isReadOnly = appUser?.roles.includes(UserRoles.Admin) ? false : true;

    return <LanguageList isReadOnly={isReadOnly} />;
}
