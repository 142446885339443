export interface CreateLocalUser {
    id: any;
    name: string;
    email?: string;
    password: string;
}

export interface LocalUser extends CreateLocalUser {
    id: any;
    name: string;
    email: string;
    password: string;
    [key: string]: string | number | null;
}

export const localUserEmpty: CreateLocalUser = {
    id: '',
    name: '',
    email: '',
    password: '',
};

export type LocalUserApiResponse = Array<LocalUser>;
