import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        heading: {
            fontWeight: 'bold',
        },

        popisNalezu: {
            marginTop: 15,
            marginLeft: 15,
            marginBottom: 0
        }
    })
);
