import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'reactstrap';
import { useStyles } from '../Common/styles';
import '../Common/audit.scss';
import { GembaType } from './AuditLayout_4';
import { useTranslation } from '../../../contexts/translationContext';
import { Content } from '../Common/Layout/Content';
import { Header } from '../Common/Layout/Header';

interface Props {
    setSelectedGemba: any;
    setShowSurvey: any;
}

export const SelectGembaL4 = ({ setSelectedGemba, setShowSurvey }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography variant="h1" className={classes.labelText}>
                    {t('Audit.layout.L4.main-headline')}
                </Typography>
            </Header>
            <Content bottomOffset={0}>
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setSelectedGemba(GembaType.Weekly);
                                }}
                                className={classes.formButton}
                                style={{ marginBottom: '20px', marginTop: '20px' }}
                            >
                                {t('Audit.layout.L4.btn-option-1')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setSelectedGemba(GembaType.Extraordinary);
                                    setShowSurvey(true);
                                }}
                                variant="contained"
                                className={classes.formButton}
                                style={{ marginBottom: '20px', marginTop: '20px' }}
                            >
                                {t('Audit.layout.L4.btn-option-2')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Content>
        </>
    );
};
