import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    netId: 'netId',
    identifier: 'identifier',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.netId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.identifier]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
