import React, { useContext } from 'react';
import { KpiItemMemberLevelAccess } from '../types/KpiMemberType';
import { User } from '../hooks/useAppUser';
import { UserRoles } from '../types/applicationUser.types';

export enum Language {
    EN = 'en-US',
    CS = 'cs-CZ',
}

export type ContextValue = {
    appUser: User | null;
    language: Language | null;
    userAccess: KpiItemMemberLevelAccess | null;
    loadingAccess: boolean;
    changeKpiItemId: (id: number | null) => void;
    changeLanguage: (language: Language) => void;
    getUser: () => void;
    isAccessibleByUserRoleAndAdminPrivilege: (roles?: Array<UserRoles>) => boolean;
};

export type ContextProps = {
    value: ContextValue;
    children: React.ReactElement;
};

export const AppContext = React.createContext<ContextValue | null>(null);

export const AppContextProvider: React.FC<ContextProps> = ({ value, children }) => (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
);

export const useAppContext = () => {
    const context: ContextValue | null = useContext(AppContext);
    if (!context) {
        throw new Error('Refetch Context not found');
    }

    return context;
};
