import { Column } from '../../components/Table/Table';

export const Columns = (t): Column[] => [
    {
        id: 'userName',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-1-table-col-1'),
    },
    {
        id: 'netId',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-1-table-col-2'),
    },
    {
        id: 'auditLevel',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-1-table-col-3'),
    },
];
