import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';
import { useTranslation } from '../../contexts/translationContext';

export const cFieldNames = {
    name: 'name',
    description: 'description',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.name]: yup
            .string()
            .required(t(cFormValidationMessages.required))
            .max(50, t('Název může mít pouze 50 znaků')),
        [cFieldNames.description]: yup.string().nullable(),
    });
};
