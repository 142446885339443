import { makeStyles, Theme } from '@material-ui/core/styles';

export const green = '#00ff00';
export const red = '#ff0000';
export const white = '#ffffff';
export const black = '#000';

export const useStyles = makeStyles((theme: Theme) => ({
    mainHeader: {
        padding: 10,
    },

    flexContainer: {
        display: 'flex',
        alignItems: 'stretch',
        flexFlow: 'column',
        justifyContent: 'spaceAround',
    },
    flexItem: { flexGrow: 0 },
    flexItemStretch: { flexGrow: 4 },
    formControl: {
        minWidth: 300,
        marginBottom: 5,
    },
    heading: {
        fontWeight: 'bold',
    },

    description: {
        marginTop: 15,
        marginLeft: 15,
        marginBottom: 0,
    },
    boxChartSolvedOk: {
        backgroundColor: green,
        color: black,
    },
    boxChartSolvedNotOk: {
        backgroundColor: red,
        color: white,
    },
    carouselMain: {
        paddingTop: 0,
    },
    carouselNavButtonsDef: {
        borderRadius: 0,
        /* '&[aria-label="Next"]': {
            right: '850px',
        }, */
    },
    carouselNavButtonsDefWrapper: {
        height: 'auto',
        width: 'auto',
        top: '55%',
    },
    questionText: {
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 0,
        width: '60vw',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    truncateText: {
        width: '60vw',
        height: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
