import * as yup from 'yup';
import { equals, includes } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    netId: 'netId',
    auditLevel: 'auditLevel',
    parentAuditId: 'parentAuditId',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.parentAuditId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0))
            .required(t(cFormValidationMessages.required)),
        [cFieldNames.netId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.auditLevel]: yup
            .string()
            .test('Card Test', t(cFormValidationMessages.required), (value) =>
                includes(value, ['Level1', 'Level2', 'Level3'])
            ),
    });
};
