import { useState } from 'react';
import { deleteApi } from '../apiUtils';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../contexts/translationContext';

interface Props {
    deleteUrl: string;
    onDelete?: () => void;
    customIdProp?: string;
}

export function useDeleteDialog({ deleteUrl, onDelete, customIdProp }: Props) {
    const { t } = useTranslation();
    const [openAlert, setOpenAlert] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>(0);

    const handleDeleteAttempt = (id?: any) => {
        setItemToDelete(id);
        setOpenAlert(true);
    };

    const handleDeleteItem = async (id?: any) => {
        if (customIdProp) {
            deleteUrl = deleteUrl + `?${customIdProp}=${id}`;
            await deleteApi({
                url: deleteUrl,
                callAfterSuccess: () => NotificationManager.success(t('Notify.item-deleted')),
            });
        } else {
            await deleteApi({
                url: deleteUrl,
                id,
                callAfterSuccess: () => NotificationManager.success(t('Notify.item-deleted')),
            });
        }
        if (onDelete) {
            onDelete();
        }
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
        setItemToDelete(0);
    };

    const handleAlertConfirm = () => {
        setOpenAlert(false);
        handleDeleteItem(itemToDelete);
    };

    return {
        openAlert,
        handleDeleteAttempt,
        handleAlertClose,
        handleAlertConfirm,
    };
}
