import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import { CreateVsms } from '../../types/VsmApiResponse';
import FieldText from '../../components/Form/FieldText';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { useTranslation } from '../../contexts/translationContext';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: CreateVsms | null;
    buttonTitle?: string;
}

export const VsmsForm = ({ onSubmit, data, buttonTitle, userCanEdit, goBackUrl }: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateVsms = {
        id: 0,
        name: '',
        description: '',
    };
    return (
        <Formik
            enableReinitialize
            initialValues={
                isNil(data) ? initialValues : { ...data, description: data.description ?? '' }
            }
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.name}
                                label={t('Master-data.hierarchy.detail.input-1')}
                                fullWidth
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.description}
                                label={t('Master-data.hierarchy.detail.input-2')}
                                fullWidth
                                minRows={3}
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <SubmitButton
                            visible={userCanEdit}
                            goBackUrl={goBackUrl}
                            buttonTitle={buttonTitle}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
