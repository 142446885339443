import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { QuestionOfflineIndicator } from './QuestionOfflineIdicator';
import { useStyles } from '../styles';
import { useTranslation } from '../../../../contexts/translationContext';
import { Content } from '../Layout/Content';
import { Footer } from '../Layout/Footer';
import { useLoading } from '../../../../contexts/loadingContext';

interface Props {
    showOfflineIndicator: boolean;
    onFinish: () => void;
    setOpenAlert: any;
}

export const QuestionSummary = ({ showOfflineIndicator, onFinish, setOpenAlert }: Props) => {
    const { t } = useTranslation();
    const { loading } = useLoading();
    const classes = useStyles();

    return (
        <>
            <Content bottomOffset={30}>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="h2" className={classes.labelText}>
                        {t('Audit.layout.summary.text')}
                    </Typography>
                </Grid>
                {showOfflineIndicator && (
                    <Grid item xs={12} style={{ marginTop: '30px', textAlign: 'center' }}>
                        <QuestionOfflineIndicator />
                    </Grid>
                )}
            </Content>
            <Footer>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        onClick={() => setOpenAlert(true)}
                        variant="contained"
                        className={classes.navButton}
                    >
                        {t('Storno')}
                    </Button>
                    <Button
                        onClick={onFinish}
                        disabled={loading}
                        variant="contained"
                        className={classes.navButton}
                    >
                        {t('Save-data')}
                    </Button>
                </Box>
            </Footer>
        </>
    );
};
