import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useTranslation } from '../../../../contexts/translationContext';

interface Props {
    current: number;
    total: number;
}
export const QuestionCounter = ({ current, total }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Typography variant="h2" className={classes.labelText}>
            {`${t('Audit.layout.label-question')} ${current} / ${total}`}
        </Typography>
    );
};
