import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { appPaths } from '../../paths';
import { PageWrapper } from '../../containers/PageWrapper';
import StickyHeadTable from '../../components/Table/Table';
import { AlertDialog } from '../../components/AlertDialog';
import { ZoneLeaderRouteParams } from './ZoneLeaderNewEdit';
import { ZoneLeaderApiResponse } from '../../types/ZoneLeaderApiResponse';
import { Columns } from './Columns';
import { UserRoles } from '../../types/applicationUser.types';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

export const ZoneLeaderDetail = () => {
    const { id, vsmsId, productionId } = useParams<ZoneLeaderRouteParams>();
    const { t } = useTranslation();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const history = useHistory();
    const { data, error, mutate } = useSWR<ZoneLeaderApiResponse>(
        `${API_URL.ZONE_LEADER_LIST}?parentId=${numericId}`,
        fetcher
    );

    const onDelete = () => {
        mutate();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.ZONE_LEADER_DELETE,
            onDelete,
        });

    const tableItemRedirect = ({ id: itemId }) =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${numericId}/leader/edit/${itemId}`
        );
    const handleAddItem = () =>
        history.push(
            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${productionId}/zone/${numericId}/leader/new/0`
        );

    if (!data) return <Loader />;

    return (
        <PageWrapper error={error}>
            <StickyHeadTable
                title={t('Master-data.hierarchy.detail.production.zone.table-2-headline')}
                rows={data}
                columns={Columns(t)}
                handleDetailItem={tableItemRedirect}
                handleDeleteItem={handleDeleteAttempt}
                handleAddItem={handleAddItem}
                addItemAndDeletePermissions={[UserRoles.Admin]}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t(
                    'Master-data.hierarchy.detail.production.zone.leader.message-delete-confirm'
                )}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
};
