import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { PageWrapper } from '../../containers/PageWrapper';
import { AuditRouterParams } from '../Audit';
import { Report } from '../../types/ReportsTypes';
import { API_URL } from '../../apiTypes';
import { ReportsColumnsContainer } from '../../containers/Reports';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ReportHeader } from '../../components/ReportHeader/ReportHeader';

export interface ReportsRouterParams extends AuditRouterParams {
    auditId: string;
}

type Props = {};

export const Reports: FC<Props> = ({}) => {
    const { productionId, auditId } = useParams<ReportsRouterParams>();
    const { data, error } = useSWR<Report>(
        `${API_URL.PRODUCTION_REPORT}?productionId=${productionId}&auditTypeId=${auditId}`,
        {
            refreshInterval: 120000, // 2mins
        }
    );

    if (!data) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                style={{ minHeight: '100vh', height: '100%' }}
            >
                <Grid item>
                    <CircularProgress color="secondary" size={80} />
                </Grid>
            </Grid>
        );
    }
    return (
        <PageWrapper loading={!data} error={error} id={'target'}>
            <ReportHeader title={`${data.auditTypeName} - ${data.productionName}`} disableBorder />
            <div
                className={classNames('fullScreen-wrapper', {
                    'fullScreen-wrapper--active': true,
                })}
            >
                <ReportsColumnsContainer report={data} fullScreen={true} />
            </div>
        </PageWrapper>
    );
};
/*
<div className={'d-flex align-items-center'}>
                    <IconButton onClick={handle.enter}>
                        <FullscreenIcon/>
                    </IconButton>
                    <Typography
                        variant={'caption'}
                        className={'bold'}
                        style={{color: 'rgba(0, 0, 0, 0.54)'}}
                    >
                        {' '}
                        Přejít na celou obrazovku
                    </Typography>
                </div>
 */

/*

 */
