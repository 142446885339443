import React, { useEffect, useRef } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { validationSchema } from './schema';
import { useStyles } from '../../pages/AuditSurvey/Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ClearIcon from '@material-ui/icons/Clear';
import { appPaths } from '../../paths';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import { useTranslation } from '../../contexts/translationContext';
import { useAuditBtnPos } from '../../hooks/useAuditBtnPos';
import { Content } from '../../pages/AuditSurvey/Common/Layout/Content';
import { Footer } from '../../pages/AuditSurvey/Common/Layout/Footer';

interface Props {
    handleShowFinish: any;
    handleTakePhoto: any;
    takingPicture: boolean;
    setTakingPicture: any;
    imageCapture: any;
    setImageCapture: any;
    boolParam1: boolean;
    setCommentRequiredError?: any;
    commentRequiredError?: boolean;
    setPhotoRequiredError?: any;
    photoRequiredError?: boolean;
    setOpenAlert: any;
}

const initialValues: any = {
    comment: '',
};

export const QuestionFormL3SummaryForm = ({
    handleTakePhoto,
    handleShowFinish,
    takingPicture,
    setTakingPicture,
    imageCapture,
    setImageCapture,
    boolParam1,
    setCommentRequiredError,
    commentRequiredError,
    photoRequiredError,
    setOpenAlert,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    // reload kamery - po vypnutí browseru telefonu a znovu zapnutí je občas černá obrazovka
    const reloadCamera = () => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={handleShowFinish}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Content bottomOffset={60}>
                        {boolParam1 && (
                            <>
                                {!takingPicture && (
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography
                                                variant="h2"
                                                className={classes.labelText}
                                                style={{ color: '#fff', marginTop: '10px' }}
                                            >
                                                {t('Audit.layout.L3.topic-of-the-week')}
                                            </Typography>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                lg={12}
                                                style={{ marginTop: '10px' }}
                                            >
                                                <Field
                                                    name={'comment'}
                                                    label={t('Audit.layout.input-comment')}
                                                    placeholder={t('Audit.layout.input-comment')}
                                                    rows={3}
                                                    onChange={(c: any) =>
                                                        c.target.value.length > 0
                                                            ? setCommentRequiredError(false)
                                                            : setCommentRequiredError(true)
                                                    }
                                                    component={'textarea'}
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {commentRequiredError && !takingPicture && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">
                                                    {t('Audit.validation.comment-required')}
                                                </Alert>
                                            </Grid>
                                        )}
                                        {photoRequiredError && !takingPicture && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">
                                                    {t('Audit.validation.photo-required')}
                                                </Alert>
                                            </Grid>
                                        )}
                                    </>
                                )}
                                <Grid item xs={12}>
                                    {!takingPicture && (
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Button
                                                onClick={() => setTakingPicture(true)}
                                                variant="contained"
                                                className={classes.formButton}
                                                style={{ color: '#fff' }}
                                            >
                                                {t('Audit.layout.btn-create-photo')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {takingPicture && (
                                        <>
                                            <Camera
                                                onTakePhoto={(dataUri) => {
                                                    handleTakePhoto(dataUri);
                                                }}
                                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                isImageMirror={false}
                                            />
                                            <Button
                                                onClick={() => reloadCamera()}
                                                variant="contained"
                                                className={classes.formButton}
                                                style={{ margin: 5, width: 'fit-content' }}
                                            >
                                                {t('Audit.layout.btn-reload-camera')}
                                            </Button>
                                        </>
                                    )}
                                    <div
                                        style={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            whiteSpace: 'nowrap',
                                            overflowX: 'auto',
                                        }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div>
                                                {imageCapture.map((image: any, index: number) => (
                                                    <div className={classes.image} key={index}>
                                                        <ClearIcon
                                                            className={classes.btnDelete}
                                                            onClick={() => {
                                                                setImageCapture([
                                                                    ...imageCapture.slice(0, index),
                                                                    ...imageCapture.slice(
                                                                        index + 1,
                                                                        imageCapture.length
                                                                    ),
                                                                ]);
                                                            }}
                                                        />
                                                        <img
                                                            className={classes.imgPreview}
                                                            src={image}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </>
                        )}
                    </Content>
                    <Footer>
                        <Box sx={{ display: 'flex' }}>
                            {!takingPicture && (
                                <>
                                    <Button
                                        onClick={() => setOpenAlert(true)}
                                        variant="contained"
                                        className={classes.navButton}
                                    >
                                        {t('Storno')}
                                    </Button>
                                    <Button
                                        variant="outline"
                                        className={classes.navButton}
                                        type="submit"
                                    >
                                        {t('Continue')}
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Footer>
                </Form>
            )}
        </Formik>
    );
};
