import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../apiUtils';
import { ErrorPage } from '../../components/Snackbar';
import { API_URL } from '../../apiTypes';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { useHistory } from 'react-router';
import { appPaths } from '../../paths';
import { Button } from '@material-ui/core';
import { AlertDialog } from '../../components/AlertDialog';
import StickyHeadTable from '../../components/Table/Table';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { Columns } from './Columns';
import { Language, LanguageApiResponse } from './types';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    isReadOnly: boolean;
}
export function LanguageList({ isReadOnly }: Props) {
    const stepNames = useStepNames();
    const { t } = useTranslation();
    const history = useHistory();
    const [languageList, setLanguageList] = useState<any[]>();
    const { data, error, mutate } = useSWR<LanguageApiResponse>(
        `${API_URL.LANGUAGE_LIST}`,
        fetcher
    );
    const onDelete = () => {
        mutate();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.LANGUAGE_DELETE,
            onDelete,
            customIdProp: 'countryCode',
        });

    const handleAddItem = () => history.push(`${appPaths.LANGUAGE}/new`);
    const handleEditItem = ({ id }) => history.push(`${appPaths.LANGUAGE}/${id}`);

    useEffect(() => {
        if (!data) return;
        let tmpLanguageList: Language[] = [];
        data.map((lang: Language) => {
            tmpLanguageList = [
                ...tmpLanguageList,
                { id: lang.countryCode, countryCode: lang.countryCode, name: lang.name },
            ];
        });
        setLanguageList(tmpLanguageList);
    }, [data]);

    if (error) return <ErrorPage error={error} />;
    if (!languageList) return <Loader />;

    return (
        <PageWrapper loading={!data}>
            <HeaderPage
                stepName={t('Languages.main-headline')}
                heading={t('Languages.main-headline')}
                buttonSection={
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={isReadOnly ? undefined : () => handleAddItem()}
                    >
                        {t('Languages.add-language')}
                    </Button>
                }
            />

            <StickyHeadTable
                columns={Columns(t)}
                rows={languageList}
                handleDeleteItem={isReadOnly ? undefined : handleDeleteAttempt}
                handleEditItem={isReadOnly ? undefined : handleEditItem}
                handleAddItem={isReadOnly ? undefined : () => handleAddItem()}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t('Languages.message-delete-confirm')}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
}
