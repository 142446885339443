import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldText from '../../components/Form/FieldText';
import { commentTaskEmpty } from '../../pages/Tasks/types';
import { cFieldNamesSetComment, validationSchemaComment } from './schema';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    onSubmit: (values: any) => void;
    onClose?: (values: any) => void;
    data?: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const TaskCommentModalForm = ({ onSubmit, onClose, data }: Props) => {
    const { t } = useTranslation();

    return (
        <Box sx={style}>
            <Formik
                enableReinitialize
                initialValues={commentTaskEmpty}
                validationSchema={validationSchemaComment(t)}
                onSubmit={(values) => {
                    onSubmit(values);
                }}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant={'h2'}>
                                    {t('Tasks.detail.comment-modal-headline')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    required
                                    name={cFieldNamesSetComment.comment}
                                    label={t('Tasks.detail.comment-modal-input')}
                                    fullWidth
                                    minRows={3}
                                    multiline
                                    component={FieldText}
                                />
                            </Grid>
                            <SubmitButton visible={true} buttonTitle={t('Save')} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
