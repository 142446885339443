import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface Props {
    title: string;
    disableBorder?: boolean;
}

export const ReportHeader = ({ title, disableBorder }: Props) => {
    const style = useMemo(() => {
        let _style: any = {
            height: '5vh',
        };

        if (!disableBorder) _style = { ..._style, border: '1px solid #000' };

        return _style;
    }, []);

    return (
        <Grid item xs={12} style={style} className="report-toolbar">
            <Typography variant="h1" style={{ padding: 5, fontSize: 34 }}>
                {title}
                <img className={'logo'} src="./logo-toolbar.svg" />
            </Typography>
        </Grid>
    );
};
