export interface CreatePermission {
    id: number;
    netId: string;
    identifier: string;
    [key: string]: string | number | null;
}

export interface Permission extends CreatePermission {
    description: string;
    userName: string;
    [key: string]: string | number | null;
}

export type PermissionsApiResponse = Array<Permission>;

export const permissionEmpty: CreatePermission = {
    id: 0,
    netId: '',
    identifier: '',
};
