import { useState } from 'react';
import { isNilOrEmpty } from 'ramda-extension';
import { propOr } from 'ramda';
import { User } from './useAppUser';
import { getItemApi } from '../apiUtils';
import { API_URL } from '../apiTypes';

interface Props<T> {
    item: T | null;
    handleChange: (item: string, value: string) => void;
    key: string;
}

export function useUserSearch<T>({}: Props<T>) {
    const [user, setUser] = useState<User | null>(null);
    const [changedByAutocomplete, setChangedByAutocomplete] = useState(false);

    const getUserName = async (stringParam) => {
        const result = await getItemApi<User>({
            url: API_URL.USER_SEARCH_BY_ID,
            params: { id: stringParam ?? '' },
        });
        if (result) {
            setUser(result);
        }
    };

    const onAutocompleteChange = (event, value) => {
        if (!isNilOrEmpty(propOr('', 'netId', value))) {
            getUserName(value.netId);

            setChangedByAutocomplete(true);
        }
        setChangedByAutocomplete(true);
    };

    return {
        user,
        onAutocompleteChange,
        setUser,
    };
}
