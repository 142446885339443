import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import { useTranslation } from '../contexts/translationContext';

interface ButtonProps {
    title?: string;
    color?: any;
    variant?: any;
}
export interface DialogProps {
    open: boolean;
    title: string;
    content?: string;
    handleClose: (e: any) => void;
    handleConfirm: (e: any) => void;
    children?: JSX.Element | JSX.Element[] | string;
    hideButtons?: boolean;
    btn1Color?: any;
    btn2Color?: any;
    btn1?: ButtonProps;
    btn2?: ButtonProps;
}

export function AlertDialog({
    open,
    title,
    content,
    handleClose,
    handleConfirm,
    children,
    hideButtons,
    btn1,
    btn2,
}: DialogProps) {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth>
            <Box px={2} py={1}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box py={1}>{content ? content : children}</Box>
                </DialogContent>
                {!hideButtons && (
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color={btn1?.color ? btn1?.color : 'primary'}
                            variant={btn1?.variant ? btn1?.variant : 'outlined'}
                        >
                            {btn1?.title ? btn1?.title : t('Close')}
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            color={btn2?.color ? btn2?.color : 'secondary'}
                            variant={btn2?.variant ? btn2?.variant : 'contained'}
                        >
                            {btn2?.title ? btn2?.title : t('Confirm')}
                        </Button>
                    </DialogActions>
                )}
            </Box>
        </Dialog>
    );
}
