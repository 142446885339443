import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { ReportAttachments } from '../../types/ReportsTypes';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import { API_URL } from '../../apiTypes';
import { useStyles } from './CarouselReport.styles';
import { useTranslation } from '../../contexts/translationContext';
import { useParams } from 'react-router';
import { Lang } from '../../types/core/lang.types';
import DoneIcon from '@material-ui/icons/Done';
interface Props {
    attachments: ReportAttachments;
    fullScreen: boolean;
}

interface ReportParams {
    countryCode: string;
}

export const CarouselReport = ({ attachments, fullScreen }: Props) => {
    const classes = useStyles();
    const { t, languageList, lang, changeLang } = useTranslation();
    const { countryCode } = useParams<ReportParams>();

    useEffect(() => {
        if (lang?.countryCode === countryCode) return;
        if (languageList) {
            let urlLang = languageList.find(
                (language: Lang) => language.countryCode === countryCode
            );
            if (urlLang) changeLang(urlLang);
        }
    }, []);

    return (
        <Carousel
            className="Example"
            autoPlay
            interval={10000}
            animation={'slide'}
            indicators={true}
            timeout={500}
            cycleNavigation
            stopAutoPlayOnHover={false}
            next={(now, previous) =>
                console.log(
                    `Next User Callback: Now displaying child${now}. Previously displayed child${previous}`
                )
            }
            prev={(now, previous) =>
                console.log(
                    `Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`
                )
            }
            onChange={(now, previous) =>
                console.log(
                    `OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`
                )
            }
            // fullHeightHover={false}
            // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
            // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
            // indicatorContainerProps={{style: {margin: "20px"}}}
            // NextIcon='next'
        >
            {attachments.map((a, index) => (
                <Grid container item spacing={2} style={{ width: '100%' }} key={index}>
                    <Grid item md={12}>
                        <Paper variant="outlined" elevation={10}>
                            <Box mt={1} mb={1} mx={2}>
                                <Grid container justifyContent="center" direction="column">
                                    <Grid item container md={12} spacing={2}>
                                        <Grid item md={6}>
                                            <Typography>
                                                {t('Report.info-top.label.zone')} {a.zone}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            {t('Report.info-top.label.evaluated-by')} {a.authorName}
                                        </Grid>
                                    </Grid>
                                    <Box mt={2} />
                                    <Grid container item md={12} spacing={2}>
                                        <Grid item md={6}>
                                            <Typography>
                                                {t('Report.info-top.label.level')} {a.level}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            {t('Report.info-top.label.date')}{' '}
                                            {moment(a.created).format('DD.MM.YYYY HH:mm')}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={12}>
                        <Paper variant="outlined" elevation={10}>
                            <Box p={1}>
                                <div>
                                    <div
                                        style={{
                                            height: fullScreen ? '450px' : '350px',
                                            background: `url("${API_URL.CHECK_LIST_ATTACHMENT_GET}?id=${a.attachmentId}") center center / contain no-repeat`,
                                            // backgroundRepeat: 'no-repeat',
                                            // backgroundPosition: 'center center',
                                            // backgroundSize: 'contain',
                                        }}
                                    />
                                    {a.actionSolvedDate !== null && (
                                        <div>
                                            <DoneIcon
                                                style={{
                                                    color: '#35dc1f',
                                                    position: 'absolute',
                                                    fontSize: '8em',
                                                    top: '50%',
                                                    left: '70%',
                                                    zIndex: 1,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={12}>
                        <Paper variant="outlined" elevation={10}>
                            <Typography variant="h2" className={classes.popisNalezu}>
                                {a.questionText}
                            </Typography>
                            <Box pb={2} pl={2}>
                                {a.answer}
                            </Box>
                            <Typography variant="h2" className={classes.popisNalezu}>
                                {t('Report.info-bottom.label.description')}{' '}
                            </Typography>
                            <Box pb={2} pl={2}>
                                {a.comment}
                            </Box>
                            <Box p={2}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {a.actionSolvedDate !== null && a.solverName ? (
                                            <Typography>
                                                <span className={classes.heading}>
                                                    {t(
                                                        'Report.info-bottom.label.solver-name-finished'
                                                    )}
                                                </span>
                                                <span>
                                                    <br></br>
                                                    {a.solverName}
                                                </span>
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                <span className={classes.heading}>
                                                    {t('Report.info-bottom.label.solver-name')}
                                                </span>
                                                <span>
                                                    <br></br>
                                                    {a.solverName}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {a.actionSolvedDate !== null ? (
                                            <Typography>
                                                <span className={classes.heading}>
                                                    {t('Report.info-bottom.label.solved-date')}
                                                </span>
                                                <span>
                                                    {moment(a.actionSolvedDate, moment.ISO_8601)
                                                        .local()
                                                        .format('DD.MM.yyyy')}
                                                </span>
                                            </Typography>
                                        ) : a.plannedDate ? (
                                            moment(a.plannedDate, moment.ISO_8601).isBefore(
                                                Date()
                                            ) ? (
                                                <Typography>
                                                    <span className={classes.heading}>
                                                        {t(
                                                            'Report.info-bottom.label.solved-date-planned'
                                                        )}{' '}
                                                    </span>
                                                    <span style={{ color: '#FF0000' }}>
                                                        {moment(a.plannedDate, moment.ISO_8601)
                                                            .local()
                                                            .format('DD.MM.yyyy')}
                                                    </span>
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    <span className={classes.heading}>
                                                        {t(
                                                            'Report.info-bottom.label.solved-date-planned'
                                                        )}{' '}
                                                    </span>
                                                    {moment(a.plannedDate, moment.ISO_8601)
                                                        .local()
                                                        .format('DD.MM.yyyy')}
                                                </Typography>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                            {a.plannedActionDescription && !a.actionSolvedDescription && (
                                <Box p={2}>
                                    <Typography className={classes.heading}>
                                        {t('Report.info-bottom.label.solution-description-planned')}{' '}
                                    </Typography>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: a.plannedActionDescription,
                                        }}
                                    />
                                </Box>
                            )}
                            {a.actionSolvedDescription && (
                                <Box p={2}>
                                    <Typography className={classes.heading}>
                                        {t('Report.info-bottom.label.solution-description')}{' '}
                                    </Typography>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: a.actionSolvedDescription,
                                        }}
                                    />
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            ))}
        </Carousel>
    );
};
/*
<LazyLoadImage
                                    width={'100%'}
                                    alt={"asd"}
                                    src={`${API_URL.CHECK_LIST_ATTACHMENT_GET}?id=${a.attachmentId}`}
                                    style={{maxHeight: fullScreen ? '650px' : '430px'}}
                                />
 */
