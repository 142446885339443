import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../../containers/PageWrapper';
import { API_URL } from '../../../apiTypes';
import { fetcher } from '../../../apiUtils';
import useSWR from 'swr';
import { Button } from 'reactstrap';
import { Zone } from '../../../types/ZoneApiResponse';
import { User, useAppUser } from '../../../hooks/useAppUser';
import { Audit } from '../../../types/AuditApiResponse';
import { appPaths } from '../../../paths';
import { Vsm } from '../../../types/VsmApiResponse';
import { Production } from '../../../types/ProductionApiResponse';
import { AuditTypeSurvey, LevelVal } from '../../../types/AuditTypeApiResponse';
import { AuditLayoutId, getAuditById } from '../../../types/AuditMobile';
import { useStyles } from '../Common/styles';
import '../Common/audit.scss';
import { useTranslation } from '../../../contexts/translationContext';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';
import { Footer } from '../Common/Layout/Footer';
import { Loader } from '../../../components/Loader/Loader';
import { Field, Form, Formik } from 'formik';
import { cFieldNames, validationSchema } from './schema';
import { UserSearchField } from '../../../components/Form/UserSearchField';
import { SubmitButton } from '../../../components/Form/SubmitButton';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';
import { AutocompleteAsync } from '../../../components/AutocompleteAsync';

interface Props {
    auditType: AuditTypeSurvey;
    hideLevel?: boolean;
    enableUserSelector?: boolean;
}

export const AuditLayout_2 = ({ auditType, hideLevel, enableUserSelector }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { appUser } = useAppUser();

    const [userNetId, setUserNetId] = useState<string | null>(appUser ? appUser.netId : null);

    const [selectedAudit, setSelectedAudit] = useState<any>();
    const [selectedVsm, setSelectedVsm] = useState<number>();
    const [selectedProduction, setSelectedProduction] = useState<number>();
    const [selectedZone, setSelectedZone] = useState<number>();

    const [filteredVsmList, setFilteredVsmList] = useState<Vsm[]>();
    const [filteredProductionList, setFilteredProductionList] = useState<Production[]>();
    const [filteredZoneList, setFilteredZoneList] = useState<Zone[]>();

    const [observedPersonRequired, setObservedPersonRequired] =
        useState<AuditTypeObservedPersonForm | null>(null);

    // Seznam auditů pro daného usera
    const { data: auditsForUser, isValidating: loadingAudits } = useSWR<Audit[]>(
        userNetId
            ? `${API_URL.AUDIT_LIST_FOR_USER}?auditorNetId=${userNetId}&auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    // Seznam VSM
    const { data: vsmList, isValidating: loadingVsm } = useSWR<Vsm[]>(
        auditsForUser ? `${API_URL.VSM_LIST}` : null,
        fetcher,
        {
            onSuccess: (res) => {
                if (res && auditsForUser) {
                    let allowedVsm: Vsm[] = [];
                    auditsForUser.map((audit: any) => {
                        let tmpVsm = res.find((vsm: Vsm) => audit.vsm.id === vsm.id);
                        const index = allowedVsm.findIndex((vsm: Vsm) => vsm.id === tmpVsm?.id);
                        if (index === -1 && tmpVsm) {
                            allowedVsm = [...allowedVsm, tmpVsm];
                        }
                    });
                    if (allowedVsm?.length === 1) {
                        setSelectedVsm(res[0].id);
                    }
                    setFilteredVsmList(allowedVsm);
                }
            },
        }
    );

    // Seznam produkcí
    const { data: productionList, isValidating: loadingProduction } = useSWR<Production[]>(
        selectedVsm ? `${API_URL.PRODUCTION_LIST}?parentId=${selectedVsm}` : null,
        fetcher,
        {
            onSuccess: (res) => {
                if (res && auditsForUser) {
                    let allowedProduction: Production[] = [];
                    auditsForUser.map((audit: any) => {
                        let tmpProduction = res.find(
                            (production: Production) => audit.production.id === production.id
                        );
                        const index = allowedProduction.findIndex(
                            (production: Production) => production.id === tmpProduction?.id
                        );
                        if (index === -1 && tmpProduction) {
                            allowedProduction = [...allowedProduction, tmpProduction];
                        }
                    });
                    if (allowedProduction?.length === 1) {
                        setSelectedProduction(res[0].id);
                    }
                    setFilteredProductionList(allowedProduction);
                }
            },
        }
    );

    // Seznam zón
    const { data: zoneList, isValidating: loadingZone } = useSWR<Zone[]>(
        selectedProduction ? `${API_URL.ZONES_LIST}?parentId=${selectedProduction}` : null,
        fetcher,
        {
            onSuccess: (res) => {
                if (res && auditsForUser) {
                    let allowedZone: Zone[] = [];
                    auditsForUser.map((audit: any) => {
                        let tmpZone = res.find((zone: Zone) => audit.zone.id === zone.id);
                        const index = allowedZone.findIndex(
                            (zone: Zone) => zone.id === tmpZone?.id
                        );
                        if (index === -1 && tmpZone) {
                            allowedZone = [...allowedZone, tmpZone];
                        }
                    });
                    if (allowedZone?.length === 1) {
                        setSelectedZone(res[0].id);
                    }
                    setFilteredZoneList(allowedZone);
                }
            },
        }
    );

    const { data: observedActivitiesInternal, isValidating: loadingObservedActivitiesInternal } =
        useSWR<string[]>(`${API_URL.COMMON_OBSERVED_ACTIVITIES_INTERNAL_LIST}`, fetcher);
    const { data: observedActivitiesExternal, isValidating: loadingObservedActivitiesExternal } =
        useSWR<string[]>(`${API_URL.COMMON_OBSERVED_ACTIVITIES_EXTERNAL_LIST}`, fetcher);

    // Když máme zónu, můžeme automaticky vybrat první audit
    useEffect(() => {
        if (!selectedVsm || !selectedZone || !auditsForUser) return;
        setSelectedAudit(auditsForUser.find((audit: any) => audit.zone.id === selectedZone));
    }, [auditsForUser && selectedZone]);

    // Když se změní VSM, pročistíme produkci, zónu a audit
    useEffect(() => {
        setSelectedProduction(undefined);
        setSelectedZone(undefined);
        setSelectedAudit(undefined);
    }, [selectedVsm]);

    // Když se změní produkce, pročistíme zónu a audit
    useEffect(() => {
        setSelectedZone(undefined);
        setSelectedAudit(undefined);
    }, [selectedProduction]);

    useEffect(() => {
        if (!selectedAudit) return;
        setObservedPersonRequired({
            type: null,
            observedPersonRequired: selectedAudit.auditType.observedPersonRequired,
            observedPersonNet: null,
        });
    }, [selectedAudit]);

    // Spustí hodnocení
    const startEvaluation = () => {
        if (observedPersonRequired && observedPersonRequired.observedPersonRequired === true) {
            if (!observedPersonRequired.type) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.observed-person-required-observedPersonRequired'),
                });
                return;
            }

            if (
                observedPersonRequired.type === 'observedPersonNet' &&
                !observedPersonRequired.observedPersonNet
            ) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedPersonNetRequired'),
                });
                return;
            }

            if (
                observedPersonRequired.type === 'observedPersonExternal' &&
                !observedPersonRequired.observedPersonExternal
            ) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedPersonExternalRequired'),
                });
                return;
            }

            if (!observedPersonRequired.observedActivity) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedActivityRequired'),
                });
                return;
            }
        }

        history.push(`${appPaths.AUDIT_ZONE}/${selectedZone}/questions/`, {
            state: {
                zone: zoneList?.filter((x: Zone) => x.id === selectedZone),
                selectedAudit: selectedAudit,
                layoutId: getAuditById(auditType.layoutId),
                userNetId: userNetId,
                observedPersonRequired: observedPersonRequired,
            },
        });
    };

    if (loadingZone || loadingAudits || loadingVsm) return <Loader />;
    return (
        <Box>
            <Header>
                <Typography align="center" variant="h1" className={classes.heading}>
                    {auditType?.name}
                </Typography>
            </Header>
            <Content bottomOffset={30}>
                {!userNetId ? (
                    <Grid
                        container
                        alignContent={'center'}
                        alignItems={'center'}
                        style={{ textAlign: 'center', marginTop: 15 }}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={{ netId: null }}
                                validationSchema={validationSchema(t)}
                                onSubmit={(values) => setUserNetId(values.netId)}
                            >
                                {({ handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Typography
                                                    align="center"
                                                    className={classes.labelText}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {t('Audit.select-user')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    name={cFieldNames.netId}
                                                    component={UserSearchField}
                                                    className={classes.formSelect}
                                                    disableLabel
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="outline"
                                                    className={classes.navButton}
                                                    type="submit"
                                                    style={{ width: '200px' }}
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        alignContent={'center'}
                        alignItems={'center'}
                        style={{ textAlign: 'center' }}
                    >
                        <Grid item xs={12}>
                            <Typography align="center" className={classes.labelText}>
                                {t('Audit.input-select-1')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                labelId="label"
                                id="select"
                                value={selectedVsm ? selectedVsm : ''}
                                className={classes.formSelect}
                                onChange={(e) => setSelectedVsm(e.target.value as any)}
                            >
                                {filteredVsmList &&
                                    filteredVsmList.map((vsm: Vsm) => (
                                        <MenuItem key={vsm.id} value={vsm.id}>
                                            {vsm.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        {selectedVsm && filteredProductionList && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.labelText}>
                                        {t('Audit.input-select-2')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        labelId="label"
                                        id="select"
                                        value={selectedProduction ? selectedProduction : ''}
                                        className={classes.formSelect}
                                        onChange={(e) =>
                                            setSelectedProduction(e.target.value as any)
                                        }
                                    >
                                        {filteredProductionList.map((production: Production) => (
                                            <MenuItem key={production.id} value={production.id}>
                                                {production.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        )}
                        {selectedProduction && filteredZoneList && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.labelText}>
                                        {t('Audit.input-select-3')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        labelId="label"
                                        id="select"
                                        value={selectedZone ? selectedZone : ''}
                                        className={classes.formSelect}
                                        onChange={(e) => setSelectedZone(e.target.value as any)}
                                    >
                                        {filteredZoneList.map((zone: Zone) => (
                                            <MenuItem key={zone.id} value={zone.id}>
                                                {zone.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        )}
                        {selectedZone && !hideLevel && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.labelText}>
                                        {t('Audit.input-select-4')}
                                    </Typography>
                                </Grid>
                                {selectedAudit && (
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outline"
                                            className={classes.infoButton}
                                            style={{ marginTop: '0px', textTransform: 'none' }}
                                        >
                                            {LevelVal[selectedAudit?.auditLevel]}
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                )}
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    style={{ textAlign: 'center' }}
                >
                    {observedPersonRequired &&
                        observedPersonRequired.observedPersonRequired !== null && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.labelText}>
                                        {t('Audit.input-select-observedPersonRequiredType')}
                                    </Typography>
                                    <Select
                                        value={observedPersonRequired.type}
                                        onChange={(e) => {
                                            setObservedPersonRequired({
                                                ...observedPersonRequired,
                                                type: e.target.value as
                                                    | 'observedPersonNet'
                                                    | 'observedPersonExternal'
                                                    | null,
                                                errorMessage: undefined,
                                            });
                                        }}
                                        className={classes.formSelect}
                                    >
                                        <MenuItem value={'observedPersonNet'} key={0}>
                                            {t('Audit.input-select-observedPersonNet-option')}
                                        </MenuItem>
                                        <MenuItem value={'observedPersonExternal'} key={1}>
                                            {t('Audit.input-select-observedPersonExternal-option')}
                                        </MenuItem>
                                    </Select>
                                </Grid>

                                {/* pokud je typ observedPersonNet */}
                                {observedPersonRequired.type === 'observedPersonNet' && (
                                    <Grid item xs={12}>
                                        <Typography align="center" className={classes.labelText}>
                                            {t('Audit.input-select-observedPersonNet')}
                                        </Typography>
                                        <AutocompleteAsync
                                            listUrl={API_URL.USER_SEARCH_BY_NAME}
                                            paramName="name"
                                            getOptionLabel={(option: User) => option.name ?? ''}
                                            getOptionSelected={(option: User, value: User) =>
                                                option.netId === value.netId
                                            }
                                            savedValue={observedPersonRequired.observedPersonNet}
                                            onChange={(event, value) =>
                                                setObservedPersonRequired({
                                                    ...observedPersonRequired,
                                                    observedPersonNet: value,
                                                    observedPersonExternal: undefined,
                                                    errorMessage: undefined,
                                                })
                                            }
                                            className={classes.formSelect}
                                        />
                                    </Grid>
                                )}

                                {/* pokud je typ observedPersonExternal */}
                                {observedPersonRequired.type === 'observedPersonExternal' && (
                                    <Grid item xs={12}>
                                        <Typography align="center" className={classes.labelText}>
                                            {t('Audit.input-select-observedPersonExternal')}
                                        </Typography>
                                        <TextField
                                            type={'text'}
                                            variant="outlined"
                                            value={observedPersonRequired.observedPersonExternal}
                                            onChange={(e: any) => {
                                                setObservedPersonRequired({
                                                    ...observedPersonRequired,
                                                    observedPersonExternal: e.target
                                                        .value as string,
                                                    observedPersonNet: null,
                                                    errorMessage: undefined,
                                                });
                                            }}
                                            className={classes.formSelect}
                                        />
                                    </Grid>
                                )}

                                {/* observedActivities se zobrazují vždy, když je vybrán typ */}
                                {observedPersonRequired.type &&
                                    observedActivitiesExternal &&
                                    observedActivitiesInternal && (
                                        <Grid item xs={12}>
                                            <Typography
                                                align="center"
                                                className={classes.labelText}
                                            >
                                                {t('Audit.input-select-observedActivities')}
                                            </Typography>
                                            <Select
                                                value={observedPersonRequired.observedActivity}
                                                onChange={(e) => {
                                                    setObservedPersonRequired({
                                                        ...observedPersonRequired,
                                                        observedActivity: e.target.value as string,
                                                        errorMessage: undefined,
                                                    });
                                                }}
                                                className={classes.formSelect}
                                            >
                                                {(observedPersonRequired.type ===
                                                'observedPersonExternal'
                                                    ? observedActivitiesExternal
                                                    : observedActivitiesInternal
                                                ).map((activity: string, index: number) => (
                                                    <MenuItem value={activity} key={index}>
                                                        {activity}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    )}
                                {observedPersonRequired.errorMessage && (
                                    <Grid item xs={12}>
                                        <span className={classes.warningText}>
                                            {observedPersonRequired.errorMessage}
                                        </span>
                                    </Grid>
                                )}
                            </>
                        )}
                </Grid>
            </Content>

            <Footer>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        variant="outline"
                        className={classes.navButton}
                        onClick={() => history.goBack()}
                    >
                        {t('Back')}
                    </Button>
                    {userNetId && (
                        <Button
                            variant="outline"
                            className={classes.navButton}
                            onClick={() => startEvaluation()}
                            disabled={!selectedVsm || !selectedProduction || !selectedZone}
                            style={{ padding: 0 }}
                        >
                            {t('Audit.action-start-asses')}
                        </Button>
                    )}
                </Box>
            </Footer>
        </Box>
    );
};
