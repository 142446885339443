import React, { useState } from 'react';
import { PageWrapper } from '../../containers/PageWrapper';
import useSWR from 'swr';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { Box, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ReportSelectValues } from '../../form/report-select-5S/schema';
import { AuditType, AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { ReportSelect5SForm } from '../../form/report-select-5S';
import { AuditLayoutId } from '../../types/AuditMobile';
import { useTranslation } from '../../contexts/translationContext';

export const Report5SSelect = () => {
    const history = useHistory();
    const { lang } = useTranslation();
    const [audits5S, setAudits5s] = useState<AuditType[]>();
    const { data: audits, isValidating: loadingAudits } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher,
        {
            onSuccess: (auditTypes: AuditType[]) => {
                let tmpAudits5S = auditTypes.filter(
                    (auditType: AuditType) => auditType.layoutId === AuditLayoutId.Layout3
                );
                setAudits5s(tmpAudits5S);
            },
        }
    );

    const onSubmit = (values: ReportSelectValues) => {
        if (lang) history.push(`/reports/audit/5S/${values.auditTypeId}/${lang.countryCode}`);
    };
    return (
        <PageWrapper loading={loadingAudits || !audits5S}>
            <Grid
                item
                container
                xs={12}
                md={12}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                style={{ paddingTop: '10%' }}
            >
                <Grid item md={3}>
                    <Paper>
                        <Box p={3}>
                            <ReportSelect5SForm
                                userCanEdit
                                auditTypes={audits5S}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
