import React from 'react';
import { Button } from 'reactstrap';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from '../Common/styles';
import { useHistory } from 'react-router';
import { QuestionOfflineIndicator } from '../Common/Questions/QuestionOfflineIdicator';
import { appPaths } from '../../../paths';
import { Answer } from '../../../types/ChecklistAnswerApiResponse';
import { useEffect, useState } from 'react';
import { QuestionFormL3SummaryForm } from '../../../form/questionsSurveyL3';
import { AuditQuestionAnswer } from '../../../types/AuditQuestionAnswerApiResponse';
import { QuestionFormL3SummaryTable } from '../../../form/questionsSurveyL3/QuestionSurveyL3SummaryTable';
import { useTranslation } from '../../../contexts/translationContext';
import { Content } from '../Common/Layout/Content';
import { Footer } from '../Common/Layout/Footer';
import { useLoading } from '../../../contexts/loadingContext';

interface Props {
    showOfflineIndicator: boolean;
    onFinish: () => void;
    answers: Answer[];
    takingPicture: boolean;
    setTakingPicture: any;
    handleCreateAttachments: any;
    onSubmit: any;
    boolParam1: boolean;
    setOpenAlert: any;
}

export const QuestionSummaryL3 = ({
    showOfflineIndicator,
    onFinish,
    answers,
    takingPicture,
    setTakingPicture,
    handleCreateAttachments,
    onSubmit,
    boolParam1,
    setOpenAlert,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { loading } = useLoading();
    const [commentRequiredError, setCommentRequiredError] = useState<boolean>();
    const [photoRequiredError, setPhotoRequiredError] = useState<boolean>();
    const [showFinish, setShowFinish] = useState<boolean>(false);
    const [imageCapture, setImageCapture] = useState<any[]>([]);
    const [answersWithSummary, setAnswersWithSummary] = useState<any[]>([]);
    const handleTakePhoto = (dataUri) => {
        setImageCapture([dataUri, ...imageCapture]);
        setTakingPicture(false);
    };

    useEffect(() => {
        if (imageCapture.length > 0) setPhotoRequiredError(false);
    }, [imageCapture]);

    const handleShowFinish = (formData) => {
        let isError = false;
        // Fotografie je povinná
        if (boolParam1 && imageCapture.length < 1) {
            isError = true;
            setPhotoRequiredError(true);
        }

        // Komentář je povinný
        if (boolParam1 && formData.comment === '') {
            isError = true;
            setCommentRequiredError(true);
        }

        if (isError) return;

        // Validace je ok. Nastavíme přílohy, potvrdíme.
        handleCreateAttachments(imageCapture, true);
        setImageCapture([]);
        setTakingPicture(false);
        setShowFinish(true);
        let tmpAnswer = {} as AuditQuestionAnswer;
        onSubmit(tmpAnswer, formData.comment, true);
        if (formData.comment) formData.comment = '';
    };

    // summary řádek do přehledové tabulky
    useEffect(() => {
        if (!answers) return;
        let answersScore: number = 0;
        answers.map((a: Answer) => {
            if (a.answerScore && a.questionText !== '__WEEKLY_TOPIC__')
                answersScore += a.answerScore;
        });
        setAnswersWithSummary([
            ...answers,
            { questionText: t('Audit.layout.L3.table-score-summary'), answerScore: answersScore },
        ]);
    }, [answers]);

    /* useEffect(() => {
        if (!showOfflineIndicator && showFinish) onFinish();
    }, [showFinish]); */

    return (
        <>
            {!showFinish && (
                <>
                    {!takingPicture && <QuestionFormL3SummaryTable data={answersWithSummary} />}
                    <QuestionFormL3SummaryForm
                        handleShowFinish={handleShowFinish}
                        handleTakePhoto={handleTakePhoto}
                        setTakingPicture={setTakingPicture}
                        takingPicture={takingPicture}
                        imageCapture={imageCapture}
                        setImageCapture={setImageCapture}
                        boolParam1={boolParam1}
                        setCommentRequiredError={setCommentRequiredError}
                        commentRequiredError={commentRequiredError}
                        setPhotoRequiredError={setPhotoRequiredError}
                        photoRequiredError={photoRequiredError}
                        setOpenAlert={setOpenAlert}
                    />
                </>
            )}
            {showFinish && (
                <>
                    <Content bottomOffset={60}>
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="h2" className={classes.labelText}>
                                {t('Audit.layout.summary.text')}
                            </Typography>
                        </Grid>
                        {showOfflineIndicator && (
                            <Grid item xs={12} style={{ marginTop: '30px', textAlign: 'center' }}>
                                <QuestionOfflineIndicator />
                            </Grid>
                        )}
                    </Content>
                    <Footer>
                        <Box sx={{ display: 'flex' }}>
                            <Button
                                onClick={() => setOpenAlert(true)}
                                variant="contained"
                                className={classes.navButton}
                            >
                                {t('Storno')}
                            </Button>
                            <Button
                                onClick={onFinish}
                                disabled={loading}
                                variant="contained"
                                className={classes.navButton}
                            >
                                {t('Save-data')}
                            </Button>
                        </Box>
                    </Footer>
                </>
            )}
        </>
    );
};
