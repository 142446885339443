import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';
import { useLoading } from '../../contexts/loadingContext';

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                {...props}
                color="primary"
                size={'8rem'}
                thickness={8}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    style={{ color: '#fff' }}
                    component="div"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

interface Props {
    totalValue: number;
    actualValue: number;
    onFinish: any;
}

export default function CircularStatic({ totalValue, actualValue, onFinish }: Props) {
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);
    const { stopLoading } = useLoading();

    useEffect(() => {
        if (totalValue === actualValue) {
            onFinish();
            NotificationManager.success(t('Successfully-uploaded'));
            stopLoading();
        }
        if (progress < 100) {
            setProgress(Math.floor((actualValue / totalValue) * 100));
        }
    }, [actualValue]);

    return <CircularProgressWithLabel value={progress} />;
}
