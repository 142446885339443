import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import useSWR from 'swr';
import { fetcher } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { AppInfo } from '../../types/AppInfo';
import { PageWrapper } from '../../containers/PageWrapper';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../contexts/translationContext';

export const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '4rem',
    },
    bold: {
        fontWeight: 600,
    },
    container: {
        width: '350px',
    },
}));

interface Props {
    title: string;
    data?: string;
}

const InfoPageRow = ({ title, data }: Props) => {
    const classes = useStyles();

    return (
        <Grid container item spacing={3} className={classes.container}>
            <Grid item xs={5}>
                <Typography variant={'body1'}>{title}</Typography>
                <Box mr={3} />
            </Grid>
            <Grid item xs={7}>
                <Typography variant={'body1'} color={'secondary'} className={classes.bold}>
                    {data}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const InfoPage = () => {
    const { data, isValidating, error } = useSWR<AppInfo>(`${API_URL.COMMON_APP_INFO}`, fetcher);
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <PageWrapper loading={isValidating} error={error}>
            <Grid
                container
                direction={'column'}
                justifyContent={'flex-start'}
                alignItems={'center'}
            >
                <Grid item>
                    <Box mt={5} />
                    <Typography variant={'h1'} className={classes.heading}>
                        {data?.appName}
                    </Typography>
                    <Box mb={3} />
                </Grid>
                <Grid item>
                    <Typography variant={'h3'}>{data?.author}</Typography>
                </Grid>
                <Grid item container direction={'row'} spacing={4} justifyContent={'center'}>
                    <Grid item>
                        <Typography variant={'body1'}>{data?.authorContactEmail}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'body1'}>{data?.authorContactMobile}</Typography>
                        <Box mb={5} />
                    </Grid>
                </Grid>
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Box mt={5} />
                    <Paper>
                        <Box px={3} py={2}>
                            <Grid container spacing={2} direction={'column'}>
                                <InfoPageRow
                                    title={t('System-info.panel-row-1')}
                                    data={data?.version}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-2')}
                                    data={data?.versionDate}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-3')}
                                    data={data?.database}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-4')}
                                    data={data?.databaseServerVersion}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-5')}
                                    data={data?.environmentName}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-6')}
                                    data={data?.machineName}
                                />
                                <InfoPageRow
                                    title={t('System-info.panel-row-7')}
                                    data={data?.appPool}
                                />
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
