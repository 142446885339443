import React from 'react';
import { Column } from '../../components/Table/Table';
import { TextField } from '@material-ui/core';
import { domainToASCII } from 'url';

export const QuestionsColumns = (t, handleChangeOrdering): Column[] => [
    {
        id: 'level',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-2-table-col-4'),
    },
    {
        id: 'ordering',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-2-table-ordering'),
        renderCell: (data: any) => {
            return (
                <TextField
                    key={data.id}
                    type={'number'}
                    variant="outlined"
                    defaultValue={data.ordering}
                    onBlur={(e: any) =>
                        handleChangeOrdering(
                            data.id,
                            e.target.value === '' ? undefined : e.target.value
                        )
                    }
                    style={{ width: 70, height: 50 }}
                    InputProps={{ inputProps: { min: 0 } }}
                    required
                />
            );
        },
    },
    {
        id: 'questionText',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-2-table-col-1'),
    },
    {
        id: 'correctAnswer',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-2-table-col-2'),
    },
    {
        id: 'typeName',
        label: t('Master-data.hierarchy.detail.production.zone.audit.tab-2-table-col-3'),
    },
];
