import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import useSWR from 'swr';
import { fetcher, putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { Loader } from '../../components/Loader/Loader';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    taskId: number;
    defaultValue: string | null;
    auditTypeDangerousFindings: boolean;
    isDisabled?: boolean;
    onSuccessChange: () => void;
}

export const SafetyImpact = ({
    taskId,
    defaultValue,
    auditTypeDangerousFindings,
    isDisabled,
    onSuccessChange,
}: Props) => {
    const { t } = useTranslation();
    const [selectedSafetyImpact, setSelectedSafetyImpact] = useState(
        defaultValue ? defaultValue : '0'
    );

    const { data: safetyImpacts, isValidating: safetyImpactsValidating } = useSWR<string[]>(
        `${API_URL.COMMON_SAFETY_IMPACTS_LIST}`,
        fetcher
    );

    const submit = async (value: string) => {
        let query = `${API_URL.USER_TASK_UPDATE_SAFETY_IMPACT}?id=${taskId}`;
        if (value !== '0') query += `&impact=${value}`;
        const response = await putApi({
            url: query,
            data: {},
        });
        if (response !== undefined) {
            NotificationManager.success(t('Notify.item-updated'));
            onSuccessChange();
        }
    };

    const handleSafetyImpactChange = (value: string) => {
        setSelectedSafetyImpact(value);
        submit(value);
    };

    if (!safetyImpacts || safetyImpactsValidating) return <Loader />;
    return (
        <>
            <Select
                value={selectedSafetyImpact}
                onChange={(e) => {
                    const _val = e.target.value as string;
                    if (auditTypeDangerousFindings && _val === '0') {
                        NotificationManager.error(
                            t('Tasks.detail.notify.safety-impact-can-not-be-null')
                        );
                        return;
                    }

                    handleSafetyImpactChange(_val);
                }}
                disabled={isDisabled}
                fullWidth
                variant="outlined"
            >
                <MenuItem value={'0'} key={0}>
                    -
                </MenuItem>
                {safetyImpacts.map((safetyImpact: string, index: number) => (
                    <MenuItem value={safetyImpact} key={index + 1}>
                        {safetyImpact}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
