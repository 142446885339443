import { useEffect, useState } from 'react';
import axios from 'axios';

export interface UseDataApi {
    data: any;
    isLoading: boolean;
    isError: boolean;
}
export const useDataApi = (
    initialUrl,
    params,
    initialData,
    autoFetch = false
): [UseDataApi, (nextUrl: string) => void] => {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const fetchData = async (nextUrl) => {
        setIsError(false);
        setIsLoading(true);

        try {
            const result = await axios(nextUrl);
            setData(result.data);
        } catch (error) {
            setIsError(true);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (autoFetch) fetchData(initialUrl);
    }, [autoFetch]);

    return [{ data, isLoading, isError }, fetchData];
};
