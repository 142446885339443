import React, { useEffect, useState } from 'react';
import { Box, Modal, Theme, useMediaQuery } from '@material-ui/core';
import { LanguageChangerModal } from './LanguageChangerModal';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export function LanguageChanger({ isOpen, onClose }: Props) {
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return (
        <>
            <Modal open={isOpen} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '25%',
                        left: isMobileView ? '15%' : '40%',
                        right: isMobileView ? '15%' : '40%',
                        minWidth: isMobileView ? '30vw' : '20vw',
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <LanguageChangerModal onClose={onClose} />
                </Box>
            </Modal>
        </>
    );
}
