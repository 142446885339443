import React from 'react';

interface Props {
    children: any;
    bottomOffset: number;
    textAlign?: 'left' | 'center' | 'right';
}

export const Content = ({ children, bottomOffset, textAlign }: Props) => {
    return (
        <div
            style={{
                backgroundColor: 'black',
                //backgroundColor: 'blue',
                padding: `10px 10px ${bottomOffset}px 10px`,
                textAlign: textAlign || 'left',
            }}
        >
            {children}
        </div>
    );
};
