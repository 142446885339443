import React, { useEffect, useState } from 'react';
import { Box, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { API_URL } from '../../../apiTypes';
import { fetcher } from '../../../apiUtils';
import useSWR from 'swr';
import { Button } from 'reactstrap';
import { Zone } from '../../../types/ZoneApiResponse';
import { User, useAppUser } from '../../../hooks/useAppUser';
import { Audit } from '../../../types/AuditApiResponse';
import { useStyles } from '../Common/styles';
import { appPaths } from '../../../paths';
import { AuditTypeSurvey, LevelVal } from '../../../types/AuditTypeApiResponse';
import { AuditLayoutId } from '../../../types/AuditMobile';
import '../Common/audit.scss';
import { useTranslation } from '../../../contexts/translationContext';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';
import { Footer } from '../Common/Layout/Footer';
import { Loader } from '../../../components/Loader/Loader';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';
import { AutocompleteAsync } from '../../../components/AutocompleteAsync';
import { Field, Form, Formik } from 'formik';
import { cFieldNames, validationSchema } from './schema';
import { UserSearchField } from '../../../components/Form/UserSearchField';

interface Props {
    auditType: AuditTypeSurvey;
}

/**
 * Layout 1
 * @param param0
 * @returns
 */
export const AuditLayout_1 = ({ auditType }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { appUser } = useAppUser();

    // States
    const [userNetId, setUserNetId] = useState<string | null>(appUser ? appUser.netId : null);

    const [selectedAudit, setSelectedAudit] = useState<any>();
    const [observedPersonRequired, setObservedPersonRequired] =
        useState<AuditTypeObservedPersonForm | null>(null);

    // Načte náhodnou zónu
    const { data: zone, isValidating: loadingZone } = useSWR<Zone>(
        userNetId
            ? `${API_URL.GET_RANDOM_ZONE}?auditorNetId=${userNetId}&auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    // Načte audity pro uživatele
    const { data: auditsForUser, isValidating: loadingAudits } = useSWR<Audit[]>(
        userNetId
            ? `${API_URL.AUDIT_LIST_FOR_USER}?auditorNetId=${userNetId}&auditTypeId=${auditType.id}`
            : null,
        fetcher
    );

    const { data: observedActivitiesInternal, isValidating: loadingObservedActivitiesInternal } =
        useSWR<string[]>(`${API_URL.COMMON_OBSERVED_ACTIVITIES_INTERNAL_LIST}`, fetcher);
    const { data: observedActivitiesExternal, isValidating: loadingObservedActivitiesExternal } =
        useSWR<string[]>(`${API_URL.COMMON_OBSERVED_ACTIVITIES_EXTERNAL_LIST}`, fetcher);

    // Jakmile máme audity a zónu, vybereme první aduit podle zóny
    useEffect(() => {
        if (!zone || !auditsForUser) return;
        setSelectedAudit(auditsForUser.find((audit: any) => audit.zone.id === zone.id));
    }, [auditsForUser && zone]);

    useEffect(() => {
        if (!selectedAudit) return;
        setObservedPersonRequired({
            type: null,
            observedPersonRequired: selectedAudit.auditType.observedPersonRequired,
            observedPersonNet: null,
        });
    }, [selectedAudit]);

    // Spustí hodnocení
    const startEvaluation = () => {
        if (!zone) return;
        if (observedPersonRequired && observedPersonRequired.observedPersonRequired === true) {
            if (!observedPersonRequired.type) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.observed-person-required-observedPersonRequired'),
                });
                return;
            }

            if (
                observedPersonRequired.type === 'observedPersonNet' &&
                !observedPersonRequired.observedPersonNet
            ) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedPersonNetRequired'),
                });
                return;
            }

            if (
                observedPersonRequired.type === 'observedPersonExternal' &&
                !observedPersonRequired.observedPersonExternal
            ) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedPersonExternalRequired'),
                });
                return;
            }

            if (!observedPersonRequired.observedActivity) {
                setObservedPersonRequired({
                    ...observedPersonRequired,
                    errorMessage: t('Audit.input-select-observedActivityRequired'),
                });
                return;
            }
        }

        history.push(`${appPaths.AUDIT_ZONE}/${zone.id}/questions/`, {
            state: {
                zone: zone,
                selectedAudit: selectedAudit,
                layoutId: AuditLayoutId.Layout1,
                observedPersonRequired: observedPersonRequired,
            },
        });
    };

    if (
        userNetId &&
        (loadingZone ||
            loadingAudits ||
            !selectedAudit ||
            !observedPersonRequired ||
            loadingObservedActivitiesExternal ||
            loadingObservedActivitiesInternal)
    )
        return <Loader />;

    return (
        <Box>
            <Header>
                <Typography align="center" variant="h1" className={classes.heading}>
                    {selectedAudit?.auditType?.name}
                </Typography>
            </Header>

            <Content bottomOffset={30}>
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    style={{ textAlign: 'center' }}
                >
                    {!userNetId ? (
                        <Grid item xs={12}>
                            <Formik
                                initialValues={{ netId: null }}
                                validationSchema={validationSchema(t)}
                                onSubmit={(values) => setUserNetId(values.netId)}
                            >
                                {({ handleChange, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Typography
                                                    align="center"
                                                    className={classes.labelText}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {t('Audit.select-user')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Field
                                                    name={cFieldNames.netId}
                                                    component={UserSearchField}
                                                    className={classes.formSelect}
                                                    disableLabel
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="outline"
                                                    className={classes.navButton}
                                                    type="submit"
                                                    style={{ width: '200px' }}
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Typography align="center" className={classes.labelText}>
                                    {t('Audit.input-select-1')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outline" className={classes.infoButton}>
                                    {selectedAudit?.vsm?.name}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" className={classes.labelText}>
                                    {t('Audit.input-select-2')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outline" className={classes.infoButton}>
                                    {selectedAudit?.production?.name}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" className={classes.labelText}>
                                    {t('Audit.input-select-3')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outline" className={classes.infoButton}>
                                    {zone?.name}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" className={classes.labelText}>
                                    {t('Audit.input-select-4')}
                                </Typography>
                            </Grid>
                            {selectedAudit && (
                                <Grid item xs={12}>
                                    <Button variant="outline" className={classes.infoButton}>
                                        {LevelVal[selectedAudit?.auditLevel]}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}

                    {observedPersonRequired &&
                        observedPersonRequired.observedPersonRequired !== null && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.labelText}>
                                        {t('Audit.input-select-observedPersonRequiredType')}
                                    </Typography>
                                    <Select
                                        value={observedPersonRequired.type}
                                        onChange={(e) => {
                                            setObservedPersonRequired({
                                                ...observedPersonRequired,
                                                type: e.target.value as
                                                    | 'observedPersonNet'
                                                    | 'observedPersonExternal'
                                                    | null,
                                                errorMessage: undefined,
                                            });
                                        }}
                                        className={classes.formSelect}
                                    >
                                        <MenuItem value={'observedPersonNet'} key={0}>
                                            {t('Audit.input-select-observedPersonNet-option')}
                                        </MenuItem>
                                        <MenuItem value={'observedPersonExternal'} key={1}>
                                            {t('Audit.input-select-observedPersonExternal-option')}
                                        </MenuItem>
                                    </Select>
                                </Grid>

                                {/* pokud je typ observedPersonNet */}
                                {observedPersonRequired.type === 'observedPersonNet' && (
                                    <Grid item xs={12}>
                                        <Typography align="center" className={classes.labelText}>
                                            {t('Audit.input-select-observedPersonNet')}
                                        </Typography>
                                        <AutocompleteAsync
                                            listUrl={API_URL.USER_SEARCH_BY_NAME}
                                            paramName="name"
                                            getOptionLabel={(option: User) => option.name ?? ''}
                                            getOptionSelected={(option: User, value: User) =>
                                                option.netId === value.netId
                                            }
                                            savedValue={observedPersonRequired.observedPersonNet}
                                            onChange={(event, value) =>
                                                setObservedPersonRequired({
                                                    ...observedPersonRequired,
                                                    observedPersonNet: value,
                                                    observedPersonExternal: undefined,
                                                    errorMessage: undefined,
                                                })
                                            }
                                            className={classes.formSelect}
                                        />
                                    </Grid>
                                )}

                                {/* pokud je typ observedPersonExternal */}
                                {observedPersonRequired.type === 'observedPersonExternal' && (
                                    <Grid item xs={12}>
                                        <Typography align="center" className={classes.labelText}>
                                            {t('Audit.input-select-observedPersonExternal')}
                                        </Typography>
                                        <TextField
                                            type={'text'}
                                            variant="outlined"
                                            value={observedPersonRequired.observedPersonExternal}
                                            onChange={(e: any) => {
                                                setObservedPersonRequired({
                                                    ...observedPersonRequired,
                                                    observedPersonExternal: e.target
                                                        .value as string,
                                                    observedPersonNet: null,
                                                    errorMessage: undefined,
                                                });
                                            }}
                                            className={classes.formSelect}
                                        />
                                    </Grid>
                                )}

                                {/* observedActivities se zobrazují vždy, když je vybrán typ */}
                                {observedPersonRequired.type &&
                                    observedActivitiesExternal &&
                                    observedActivitiesInternal && (
                                        <Grid item xs={12}>
                                            <Typography
                                                align="center"
                                                className={classes.labelText}
                                            >
                                                {t('Audit.input-select-observedActivities')}
                                            </Typography>
                                            <Select
                                                value={observedPersonRequired.observedActivity}
                                                onChange={(e) => {
                                                    setObservedPersonRequired({
                                                        ...observedPersonRequired,
                                                        observedActivity: e.target.value as string,
                                                        errorMessage: undefined,
                                                    });
                                                }}
                                                className={classes.formSelect}
                                            >
                                                {(observedPersonRequired.type ===
                                                'observedPersonExternal'
                                                    ? observedActivitiesExternal
                                                    : observedActivitiesInternal
                                                ).map((activity: string, index: number) => (
                                                    <MenuItem value={activity} key={index}>
                                                        {activity}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    )}
                                {observedPersonRequired.errorMessage && (
                                    <Grid item xs={12}>
                                        <span className={classes.warningText}>
                                            {observedPersonRequired.errorMessage}
                                        </span>
                                    </Grid>
                                )}
                            </>
                        )}
                </Grid>
            </Content>

            <Footer>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        variant="outline"
                        className={classes.navButton}
                        onClick={() => history.goBack()}
                    >
                        {t('Back')}
                    </Button>
                    {userNetId && (
                        <Button
                            variant="outline"
                            className={classes.navButton}
                            onClick={() => startEvaluation()}
                            style={{ padding: 0 }}
                        >
                            {t('Audit.action-start-asses')}
                        </Button>
                    )}
                </Box>
            </Footer>
        </Box>
    );
};
