import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router';

export interface Step {
    name: string;
    pathToCutCount: number;
}

interface Props {
    pageName: string;
    steps: Array<Step>;
}

export function BreadcrumbsWithHistory({ pageName, steps }: Props) {
    const history = useHistory();

    return (
        <Breadcrumbs>
            {steps.map((step, index) => {
                const currentPath = window.location.pathname;
                const currentPathArray = currentPath.split('/');
                const lastElementIndex = currentPathArray.length - step.pathToCutCount;
                currentPathArray.splice(lastElementIndex);
                const link = currentPathArray.join('/');

                return (
                    <Link
                        key={index}
                        component="button"
                        color="inherit"
                        onClick={() => {
                            history.push(link);
                        }}
                        underline="none"
                    >
                        <Typography>{step.name}</Typography>
                    </Link>
                );
            })}
            <Typography color="secondary">{pageName}</Typography>
        </Breadcrumbs>
    );
}
