import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { equalsZero } from 'ramda-extension';
import { AuditRouterParams } from '../Audit';
import { CreateZone } from '../../types/ZoneApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { AuditorForm } from '../../form/auditor';
import { Auditor } from '../../types/AuditorApiResponse';
import { PageWrapper } from '../../containers/PageWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
export interface AuditorRouterParams extends AuditRouterParams {
    auditId: string;
}

export const AuditorNewEdit = () => {
    const editRoles = [UserRoles.Admin];
    const { t } = useTranslation();
    const { id, vsmsId, productionId, zoneId, auditId } = useParams<AuditorRouterParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const numericAuditId = Number(auditId);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const {
        data: auditDetail,
        mutate: mutateAudit,
        isValidating,
    } = useSWR<Auditor>(`${API_URL.AUDITOR_ITEM}?id=${numericId}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateZone>({
                    url: API_URL.AUDITOR_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(
                            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${zoneId}/audit/${numericAuditId}`
                        );
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDITOR_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutateAudit();
        }
    }, [numericId]);

    if (isValidating) return <Loader />;
    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${auditId}`;
    return (
        <PageWrapper>
            <HeaderPage
                heading={t(
                    'Master-data.hierarchy.detail.production.zone.audit.auditor.main-headline-new'
                )}
                stepName={t(
                    'Master-data.hierarchy.detail.production.zone.audit.auditor.main-headline-new'
                )}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 11 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 9 },
                    {
                        name: t('Master-data.hierarchy.detail.production.main-headline'),
                        pathToCutCount: 7,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.main-headline'),
                        pathToCutCount: 2,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.audit.main-headline'),
                        pathToCutCount: 3,
                    },
                ]}
                buttonSectionPermission={editRoles}
                noPaper
                noMb
            />

            <Grid item container xs={5}>
                <Paper>
                    <Box p={3}>
                        <AuditorForm
                            userCanEdit={userCanEdit}
                            onSubmit={submit}
                            buttonTitle={t('Save')}
                            data={auditDetail}
                            auditId={numericAuditId}
                            goBackUrl={goBackUrl}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
