import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { isNil } from 'ramda';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { UserSearchField } from '../../components/Form/UserSearchField';
import {
    cFieldNamesAssign,
    cFieldNamesFinish,
    cFieldNamesSetPlannedDate,
    validationSchemaAssign,
    validationSchemaFinish,
} from './schema';
import FieldText from '../../components/Form/FieldText';
import {
    assignTaskEmpty,
    finishTaskEmpty,
    reopenTaskEmpty,
    setPlannedDateEmpty,
} from '../../pages/Tasks/types';

import moment from 'moment';
import { useTranslation } from '../../contexts/translationContext';
import DateFnsUtils from '@date-io/date-fns';

import enLocale from 'date-fns/locale/en-US';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import csLocale from 'date-fns/locale/cs';
import { Language } from '../../contexts/LanguageContext';
import { TaskActionModalChangeZoneForm } from './TaskActionModalChangeZoneForm';
interface Props {
    action: string;
    onSubmit: (values: any) => void;
    onClose?: (values: any) => void;
    data?: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const TaskDetailModalForm = ({ action, onSubmit, onClose, data }: Props) => {
    const { t, lang } = useTranslation();
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Box sx={style}>
            {action === 'ChangeZone' && <TaskActionModalChangeZoneForm onSubmit={onSubmit} />}
            {action === 'Assign' && (
                <Formik
                    enableReinitialize
                    initialValues={isNil(data) ? assignTaskEmpty : data}
                    validationSchema={validationSchemaAssign(t)}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant={'h2'}>
                                        {t('Tasks.detail.assign-modal-headline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNamesAssign.netId}
                                        component={UserSearchField}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNamesAssign.comment}
                                        label={t('Tasks.detail.comment-modal-input')}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        component={FieldText}
                                    />
                                </Grid>
                                <SubmitButton visible={true} buttonTitle={t('Save')} />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
            {action === 'Finish' && (
                <Formik
                    enableReinitialize
                    initialValues={isNil(data) ? finishTaskEmpty : data}
                    validationSchema={validationSchemaFinish(t)}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant={'h2'}>
                                        {t('Tasks.detail.finish-modal-headline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        required
                                        name={cFieldNamesFinish.comment}
                                        label={t('Tasks.detail.finish-modal-input-1')}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        component={FieldText}
                                    />
                                </Grid>
                                <SubmitButton visible={true} buttonTitle={t('Save')} />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}

            {action === 'SetPlannedDate' && (
                <Formik
                    enableReinitialize
                    initialValues={isNil(data) ? setPlannedDateEmpty : data}
                    onSubmit={(values) => {
                        values.date = moment(selectedDate).format('YYYY-MM-DD');
                        onSubmit(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant={'h2'}>
                                        {t('Tasks.detail.planned-modal-headline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={
                                            lang?.countryCode === Language.CS ? csLocale : enLocale
                                        }
                                    >
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label={t('Tasks.detail.planned-modal-input-1')}
                                            format={
                                                lang?.countryCode === Language.CS
                                                    ? 'dd.MM.yyyy'
                                                    : 'dd/MM/yyyy'
                                            }
                                            name={cFieldNamesSetPlannedDate.date}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            cancelLabel={t('Cancel')}
                                            okLabel={t('Save')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNamesSetPlannedDate.comment}
                                        label={t('Tasks.detail.planned-modal-input-2')}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        component={FieldText}
                                    />
                                </Grid>
                                <SubmitButton visible={true} buttonTitle={t('Save')} />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
            {action === 'Reopen' && (
                <Formik
                    enableReinitialize
                    initialValues={isNil(data) ? reopenTaskEmpty : data}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant={'h2'}>
                                        {t('Tasks.detail.reopen-modal-headline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNamesSetPlannedDate.comment}
                                        label={t('Tasks.detail.reopen-modal-input-1')}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        component={FieldText}
                                    />
                                </Grid>
                                <SubmitButton visible={true} buttonTitle={t('Save')} />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
            {action === 'Take' && (
                <Formik
                    enableReinitialize
                    initialValues={isNil(data) ? reopenTaskEmpty : data}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant={'h2'}>
                                        {t('Tasks.detail.take-modal-headline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNamesSetPlannedDate.comment}
                                        label={t('Tasks.detail.take-modal-input-1')}
                                        fullWidth
                                        minRows={3}
                                        multiline
                                        component={FieldText}
                                    />
                                </Grid>
                                <SubmitButton visible={true} buttonTitle={t('Save')} />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
        </Box>
    );
};
