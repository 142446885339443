import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AuditQuestionApiResponse } from '../../types/AuditQuestionApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import useSWR from 'swr';
import './questionHierarchy.styles.scss';
import { Audit } from '../../types/AuditApiResponse';
import { Production } from '../../types/ProductionApiResponse';
import { Zone } from '../../types/ZoneApiResponse';
import { Vsm } from '../../types/VsmApiResponse';

export const QuestionHierarchyPrint = () => {
    const { pathname } = useLocation();

    const routePrefixArr = pathname.split('/');
    const auditId = Number(routePrefixArr[3]);

    const { data: audit } = useSWR<Audit>(`${API_URL.AUDIT_ITEM}?id=${auditId}`, fetcher);
    const { data: zone } = useSWR<Zone>(
        audit ? `${API_URL.ZONES_ITEM}?id=${audit.parentZoneId}` : null,
        fetcher
    );
    const { data: production } = useSWR<Production>(
        zone ? `${API_URL.PRODUCTION_ITEM}?id=${zone.parentProductionId}` : null,
        fetcher
    );
    const { data: vsm } = useSWR<Vsm>(
        production ? `${API_URL.VSM_ITEM}?id=${production.parentVsmId}` : null,
        fetcher
    );

    const { data: auditQuestions } = useSWR<AuditQuestionApiResponse>(
        `${API_URL.AUDIT_QUESTION_LIST}?parentId=${auditId}`,
        fetcher
    );

    useEffect(
        function dataLoaded() {
            if (!auditQuestions || !audit || !zone || !production || !vsm) return;
            print();
        },
        [auditQuestions, audit, zone, production, vsm]
    );

    if (!auditQuestions || !audit || !zone || !production || !vsm) return <>Loading...</>;
    return (
        <div className="print">
            <div className="header">
                <div className="title">Checklist</div>
                <div>Audit: {audit.auditTypeName}</div>
                <div>VSM: {vsm.name}</div>
                <div>Production: {production.name}</div>
                <div>Zone: {zone.name}</div>
            </div>
            <ul className="questions">
                {auditQuestions.map((question, i) => {
                    return (
                        <li key={i}>
                            <div className={`question${i % 2 === 0 ? ' strip' : ''}`}>
                                <span className="question-text">{question.questionText}</span>
                                <span className="question-level">{question.level}</span>
                            </div>
                            <div className="answer-space"></div>
                        </li>
                    );
                })}
            </ul>
            <table className="signature">
                <tr>
                    <td>Datum: </td>
                    <td className="second"></td>
                </tr>
                <tr>
                    <td>Auditor: </td>
                    <td className="second"></td>
                </tr>
                <tr>
                    <td>Podpis: </td>
                    <td className="second"></td>
                </tr>
            </table>
        </div>
    );
};
