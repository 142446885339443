import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    countryCode: 'countryCode',
    name: 'name',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.countryCode]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.name]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
