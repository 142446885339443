import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { equalsZero } from 'ramda-extension';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { ZoneLeaderForm } from '../../form/zoneLeader';
import { CreateZoneLeader, ZoneLeader } from '../../types/ZoneLeaderApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { PageWrapper } from '../../containers/PageWrapper';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

export interface ZoneLeaderRouteParams {
    productionId: string;
    zoneId: string;
    vsmsId: string;
    id: string;
}
export const ZoneLeaderNewEdit = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const { id, vsmsId, productionId, zoneId } = useParams<ZoneLeaderRouteParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const numericZoneId = Number(zoneId);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const {
        data,
        error,
        mutate: mutateDetail,
        isValidating,
    } = useSWR<ZoneLeader>(`${API_URL.ZONE_LEADER_ITEM}?id=${numericId}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateZoneLeader>({
                    url: API_URL.ZONE_LEADER_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(
                            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${zoneId}`
                        );
                    },
                }),
            () =>
                putApi({
                    url: API_URL.ZONE_LEADER_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}`;

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutateDetail();
        }
    }, [numericId]);

    return (
        <PageWrapper error={error}>
            <HeaderPage
                stepName={t(
                    'Master-data.hierarchy.detail.production.zone.leader.main-headline-new'
                )}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 9 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 7 },
                    {
                        name: t('Master-data.hierarchy.detail.production.main-headline'),
                        pathToCutCount: 5,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.main-headline'),
                        pathToCutCount: 3,
                    },
                ]}
                noPaper
                noMb
            />

            <Grid container>
                <Grid item xs={4}>
                    <Paper>
                        <Box p={3}>
                            <ZoneLeaderForm
                                userCanEdit={userCanEdit}
                                onSubmit={submit}
                                data={data}
                                zoneId={numericZoneId}
                                goBackUrl={goBackUrl}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
