import { ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { LinkType, MenuItem } from '../../menu';
import { always, cond, equals, T } from 'ramda';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    item: MenuItem;
    index: number;
}

export const isActive = (url: string, pathname: string) => {
    if (url === pathname) {
        return true;
    }
};
export const MenuItemLink = ({ item, index }: Props) => {
    const { pathname } = useLocation();
    const { t, lang } = useTranslation();
    return (
        <Link
            key={index}
            to={cond([
                [
                    equals(LinkType.External),
                    () =>
                        always({
                            pathname: item.isReport
                                ? item.path + '/' + lang?.countryCode
                                : item.path,
                        }),
                ],
                [T, always(item.isReport ? item.path + '/' + lang?.countryCode : item.path)],
                // @ts-ignore
            ])(item.type)}
            style={{ textDecoration: 'none' }}
            target={equals(LinkType.External, item.type) ? '_blank' : ''}
        >
            <ListItem key={item.path} button selected={isActive(item.path, pathname)}>
                <ListItemIcon>
                    {item.icon && (
                        <item.icon
                            color={isActive(item.path, pathname) ? 'secondary' : 'primary'}
                        />
                    )}
                </ListItemIcon>
                <Typography
                    color={isActive(item.path, pathname) ? 'secondary' : 'primary'}
                    variant="subtitle1"
                >
                    {t(item.title)}
                </Typography>
            </ListItem>
        </Link>
    );
};
