import React from 'react';
import { PageWrapper } from '../../containers/PageWrapper';
import useSWR from 'swr';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { ReportSelectForm } from '../../form/report-select';
import { Box, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ReportSelectValues } from '../../form/report-select/schema';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { useTranslation } from '../../contexts/translationContext';

export const ReportSelect = () => {
    const history = useHistory();
    const { lang } = useTranslation();
    const { data: productions, isValidating: loadingProductions } = useSWR<ProductionApiResponse>(
        `${API_URL.PRODUCTION_LIST}`,
        fetcher
    );
    const { data: audits, isValidating: loadingAudits } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher
    );

    const onSubmit = (values: ReportSelectValues) => {
        if (lang)
            history.push(
                `/reports/production/${values.productionId}/audit/${values.auditId}/${lang.countryCode}`
            );
    };
    return (
        <PageWrapper loading={loadingAudits || loadingProductions}>
            <Grid
                container
                xs={12}
                md={12}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                style={{ paddingTop: '10%' }}
            >
                <Grid item md={3}>
                    <Paper>
                        <Box p={3}>
                            <ReportSelectForm
                                userCanEdit
                                productions={productions}
                                audits={audits}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
