import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { CreateVsms } from '../../types/VsmApiResponse';
import { putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { ZoneForm } from '../../form/zone';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { Zone } from '../../types/ZoneApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';
interface Props {
    productionId: number;
    id: number;
    data: Zone | null;
    productions: ProductionApiResponse | null;
    mutate: () => void;
    editPermission?: Array<UserRoles>;
    goBackUrl: string;
}
export const ZoneDetailFormContainer = ({
    productions,
    data,
    productionId,
    mutate,
    editPermission,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const canEdit = isAccessibleByUserRoleAndAdminPrivilege(editPermission);
    const editProduction = async (values: CreateVsms) => {
        if (!canEdit) return;
        await putApi({
            url: API_URL.ZONES_UPDATE,
            data: values,
            callAfterSuccess: () => {
                mutate();
                NotificationManager.success(t('Notify.item-updated'));
            },
        });
    };

    return (
        <Paper>
            <Box p={3}>
                <ZoneForm
                    onSubmit={editProduction}
                    productions={productions}
                    data={data}
                    buttonTitle="Editovat"
                    productionId={productionId}
                    userCanEdit={canEdit}
                    goBackUrl={goBackUrl}
                />
            </Box>
        </Paper>
    );
};
