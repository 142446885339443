import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Grid, Paper, useMediaQuery, Theme } from '@material-ui/core';
import { API_URL } from '../../apiTypes';
import WarningIcon from '@material-ui/icons/Warning';

interface Props {
    attachments: any[];
    fullScreen: boolean;
    isDangerous?: boolean;
}

export const CarouselBase = ({ attachments, fullScreen, isDangerous }: Props) => {
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <>
            <Carousel
                className="Example"
                autoPlay
                interval={10000}
                animation={'slide'}
                indicators={attachments.length > 1}
                timeout={500}
                cycleNavigation
                stopAutoPlayOnHover={false}
            >
                {attachments.map((a, index) => (
                    <Grid container item spacing={1} style={{ width: '100%' }} key={index}>
                        <Grid item md={12} style={{ width: isMobileView ? '90vw' : undefined }}>
                            <Paper variant="outlined" elevation={10}>
                                <Box p={1}>
                                    <div
                                        style={{
                                            maxHeight: '750px',
                                            minHeight: '550px',
                                            background: `url("${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_GET}?id=${a.id}")`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            backgroundSize: 'contain',
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        {isDangerous && (
                            <Grid item md={12}>
                                <Paper variant="outlined" elevation={10}>
                                    <Box p={1}>
                                        <div>
                                            <div>
                                                <WarningIcon
                                                    style={{
                                                        color: 'red',
                                                        position: 'absolute',
                                                        fontSize: '8em',
                                                        bottom: '15%',
                                                        left: '75%',
                                                        zIndex: 1,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Carousel>
            <div></div>
        </>
    );
};
