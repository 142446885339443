import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { equalsZero } from 'ramda-extension';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { ProductionRouteParams } from '../Production';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { ZoneForm } from '../../form/zone';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { CreateZone } from '../../types/ZoneApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';

export interface ZoneRouteParams extends ProductionRouteParams {
    productionId: string;
}
export const ZoneNewEdit = () => {
    const editRoles = [UserRoles.Admin];
    const { t } = useTranslation();
    const stepNames = useStepNames();
    const { id, vsmsId, productionId } = useParams<ZoneRouteParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const { data } = useSWR<ProductionApiResponse>(`${API_URL.PRODUCTION_LIST}`, fetcher);

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateZone>({
                    url: API_URL.ZONES_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(
                            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}`
                        );
                    },
                }),
            () =>
                putApi({
                    url: API_URL.ZONES_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);
    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}`;
    return (
        <>
            <HeaderPage
                stepName={stepNames.NEW_ZONE}
                breadCrumbSteps={[
                    { name: stepNames.MASTERDATA, pathToCutCount: 7 },
                    { name: stepNames.VSMS, pathToCutCount: 5 },
                    { name: stepNames.PRODUCTION, pathToCutCount: 3 },
                ]}
                noPaper
                noMb
            />

            <Grid item container xs={5}>
                <Paper>
                    <Box p={3}>
                        <ZoneForm
                            onSubmit={submit}
                            productions={data}
                            buttonTitle="Ulozit"
                            productionId={numericProductionId}
                            userCanEdit={userCanEdit}
                            goBackUrl={goBackUrl}
                        />
                    </Box>
                </Paper>
            </Grid>
        </>
    );
};
