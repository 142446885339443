import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { AuditQuestion } from '../../types/AuditQuestionApiResponse';
import { QuestionsForm } from '../../form/questions';
import { AuditQuestionTypeApiResponse } from '../../types/AuditQuestionTypeApiResponse';
import { PageWrapper } from '../../containers/PageWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import StickyHeadTable from '../../components/Table/Table';
import { Columns } from './Columns';
import { AuditQuestionAnswerApiResponse } from '../../types/AuditQuestionAnswerApiResponse';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { AuditRouterParams } from '../Audit/AuditDetail';
import { AlertDialog } from '../../components/AlertDialog';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
export interface QuestionRouterParams extends AuditRouterParams {
    auditId: string;
}

export const AuditQuestionNewEdit = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const { id, vsmsId, productionId, zoneId, auditId } = useParams<QuestionRouterParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const numericZoneId = Number(zoneId);
    const numericAuditId = Number(auditId);
    const questionPath = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${auditId}/question/${id}/answer`;
    const history = useHistory();
    const [selectedTypeLayout, setSelectedTypeLayout] = useState<number>();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);
    const { data: audits, error: auditsError } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_LIST}`,
        fetcher
    );

    const { data: questionTypes, error: errorQuestionsType } = useSWR<AuditQuestionTypeApiResponse>(
        `${API_URL.AUDIT_QUESTION_TYPE_LIST}`,
        fetcher
    );

    const { data: auditTypes, error: errorAuditType } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher
    );

    const {
        data: questionDetail,
        error: questionError,
        mutate: mutateQuestion,
        isValidating,
    } = useSWR<AuditQuestion>(
        numericId !== 0 ? `${API_URL.AUDIT_QUESTION_ITEM}?id=${numericId}` : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        }
    );
    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}/audit/${auditId}`;
    const { data: answersList, mutate: answersMutate } = useSWR<AuditQuestionAnswerApiResponse>(
        questionDetail && numericId !== 0
            ? `${API_URL.AUDIT_QUESTION_ANSWER_LIST}?parentId=${questionDetail?.id}`
            : null,
        fetcher
    );

    const onDelete = () => {
        answersMutate();
    };

    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDIT_QUESTION_ANSWER_DELETE,
            onDelete,
        });
    const handleAddAnswer = () => history.push(`${questionPath}/new/0`);
    const handleEditAnswer = ({ id }) => history.push(`${questionPath}/edit/${id}`);

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi({
                    url: API_URL.AUDIT_QUESTION_CREATE,
                    data: values,
                    callAfterSuccess: (res: any) => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(
                            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${zoneId}/audit/${numericAuditId}/question/${res.data.id}`
                        );
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDIT_QUESTION_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    useEffect(() => {
        if (!audits || !auditId || !auditTypes) return;
        let audit = audits.find((x: any) => x.id === numericAuditId);
        let auditType = auditTypes.find((x: any) => x.id === audit?.auditTypeId);
        setSelectedTypeLayout(auditType?.layoutId);
    }, [audits, auditTypes]);

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutateQuestion();
        }
    }, [numericId]);

    if (!audits || !questionTypes || isValidating) return <Loader />;
    return (
        <PageWrapper error={errorQuestionsType || auditsError || questionError}>
            <HeaderPage
                stepName={
                    numericId === 0
                        ? t(
                              'Master-data.hierarchy.detail.production.zone.audit.question.main-headline-new'
                          )
                        : t(
                              'Master-data.hierarchy.detail.production.zone.audit.question.main-headline-edit'
                          )
                }
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 11 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 9 },
                    {
                        name: t('Master-data.hierarchy.detail.production.main-headline'),
                        pathToCutCount: 7,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.main-headline'),
                        pathToCutCount: 2,
                    },
                    {
                        name: t('Master-data.hierarchy.detail.production.zone.audit.main-headline'),
                        pathToCutCount: 2,
                    },
                ]}
                noPaper
                noMb
                buttonSectionPermission={editRoles}
            />

            <Grid container spacing={2}>
                <Grid item xs={6} sm={5} md={6}>
                    <Paper>
                        <Box p={3}>
                            <QuestionsForm
                                onSubmit={submit}
                                buttonTitle={t('Save')}
                                auditId={numericAuditId}
                                audits={audits}
                                questionTypes={questionTypes}
                                data={questionDetail}
                                userCanEdit={userCanEdit}
                                goBackUrl={goBackUrl}
                                selectedTypeLayout={selectedTypeLayout}
                            />
                        </Box>
                    </Paper>
                </Grid>
                {numericId !== 0 && selectedTypeLayout === 3 && (
                    <>
                        <Grid item xs={6} sm={7} md={6}>
                            <StickyHeadTable
                                title={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.table-headline'
                                )}
                                rows={answersList}
                                columns={Columns(t)}
                                handleEditItem={handleEditAnswer}
                                handleDeleteItem={handleDeleteAttempt}
                                handleAddItem={handleAddAnswer}
                                addItemAndDeletePermissions={[UserRoles.Admin]}
                            />
                        </Grid>
                        <AlertDialog
                            open={openAlert}
                            title={t('Delete')}
                            content={t(
                                'Master-data.hierarchy.detail.production.zone.audit.question.answer.message-delete-confirm'
                            )}
                            handleClose={handleAlertClose}
                            handleConfirm={handleAlertConfirm}
                        />
                    </>
                )}
            </Grid>
        </PageWrapper>
    );
};
