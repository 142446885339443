import React from 'react';
import { isNil } from 'ramda';
import { cFieldNames, initialValues, validationSchema } from './schema';
import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { IForm } from '../../types/IForm';
import FieldSelect from '../../components/Form/FieldSelect';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { useTranslation } from '../../contexts/translationContext';

export interface Props extends IForm {
    data?: any | null;
    onSubmit: (e: any) => void;
    productions: ProductionApiResponse | undefined;
    audits: AuditTypeApiResponse | undefined;
}

export const ReportSelectForm = ({
    data,
    userCanEdit,
    onSubmit,
    productions,
    goBackUrl,
    audits,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                userCanEdit && onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.productionId}
                                label={t('Report.select.production')}
                                fullWidth
                                component={FieldSelect}
                            >
                                {productions &&
                                    productions.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.auditId}
                                label={t('Report.select.audit')}
                                fullWidth
                                component={FieldSelect}
                            >
                                {audits &&
                                    audits.map((a) => (
                                        <MenuItem key={a.id} value={a.id}>
                                            {`${a.name}`}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <SubmitButton
                            buttonTitle={t('Show')}
                            visible={userCanEdit}
                            goBackUrl={goBackUrl}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
