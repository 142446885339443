import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import React from 'react';
import { cFieldNames, validationSchema } from './schema';
import { Language, languageInputEmpty } from '../../pages/Language/types';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldText from '../../components/Form/FieldText';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: Language | null;
    buttonTitle: string;
    isNew?: boolean;
    t: any;
}

export const LanguageForm = ({
    onSubmit,
    data,
    buttonTitle,
    userCanEdit,
    goBackUrl,
    isNew,
    t,
}: Props) => (
    <Formik
        enableReinitialize
        initialValues={isNil(data) ? languageInputEmpty : data}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
            onSubmit(values);
        }}
    >
        {({ handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.name}
                            label={t('Languages.detail.input-1')}
                            component={FieldText}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.countryCode}
                            label={t('Languages.detail.input-2')}
                            component={FieldText}
                            disabled={!isNew}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}></Grid>
                    <SubmitButton
                        visible={userCanEdit}
                        buttonTitle={buttonTitle}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
            </Form>
        )}
    </Formik>
);
