import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { equalsZero } from 'ramda-extension';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { CreateZone, ZoneApiResponse } from '../../types/ZoneApiResponse';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { AuditRouterParams } from './AuditDetail';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { PageWrapper } from '../../containers/PageWrapper';
import { AuditForm } from '../../form/audits';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

export const AuditNewEdit = () => {
    const editRoles = [UserRoles.Admin];
    const { t } = useTranslation();
    const stepNames = useStepNames();
    const { id, vsmsId, productionId, zoneId } = useParams<AuditRouterParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const numericProductionId = Number(productionId);
    const numericZoneId = Number(zoneId);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const { data: zones, error: errorZone } = useSWR<ZoneApiResponse>(
        `${API_URL.ZONES_LIST}`,
        fetcher
    );

    const { data: auditTypes, error: errorAuditType } = useSWR<AuditTypeApiResponse>(
        `${API_URL.AUDIT_TYPE_LIST}`,
        fetcher
    );

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateZone>({
                    url: API_URL.AUDIT_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(
                            `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}/production/${numericProductionId}/zone/${zoneId}`
                        );
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDIT_UPDATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-updated'));
                    },
                })
        )(numericId);

    if (!zones || !auditTypes) return <Loader />;
    const goBackUrl = `${appPaths.MASTER_DATA_VSMS}/${vsmsId}/production/${productionId}/zone/${zoneId}`;
    return (
        <PageWrapper loading={!zones || !auditTypes} error={errorZone || errorAuditType}>
            <HeaderPage
                stepName={stepNames.NEW_AUDIT}
                breadCrumbSteps={[
                    { name: stepNames.MASTERDATA, pathToCutCount: 9 },
                    { name: stepNames.VSMS, pathToCutCount: 7 },
                    { name: stepNames.PRODUCTION, pathToCutCount: 5 },
                    { name: stepNames.ZONE, pathToCutCount: 3 },
                ]}
                noPaper
                noMb
            />

            <Grid item container xs={5} sm={12}>
                <Paper>
                    <Box p={3}>
                        <AuditForm
                            onSubmit={submit}
                            buttonTitle={t('Save')}
                            zoneId={numericZoneId}
                            data={null}
                            zones={zones}
                            auditTypes={auditTypes}
                            goBackUrl={goBackUrl}
                            userCanEdit={userCanEdit}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
