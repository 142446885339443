import React from 'react';
import useSWR from 'swr';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';
import { Columns } from './Columns';
import { PermissionsApiResponse } from './types';
import { fetcher } from '../../apiUtils';
import { ErrorPage } from '../../components/Snackbar';
import { AlertDialog } from '../../components/AlertDialog';
import StickyHeadTable from '../../components/Table/Table';
import { API_URL } from '../../apiTypes';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { useDetailRoutes } from '../../hooks/useDetailRoutes';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

export function PermissionsList() {
    const { t } = useTranslation();
    const stepNames = useStepNames();
    const { data, error, mutate } = useSWR<PermissionsApiResponse>(
        `${API_URL.PERMISSION_LIST}`,
        fetcher
    );

    const onDelete = () => {
        mutate();
    };

    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.PERMISSION_DELETE,
            onDelete,
        });
    const { handleAddItem } = useDetailRoutes('/', 'permissions');

    if (error) return <ErrorPage error={error} />;
    if (!data) return <Loader />;

    return (
        <PageWrapper loading={!data}>
            <HeaderPage
                stepName={t('Permissions.main-headline')}
                heading={t('Permissions.main-headline')}
                buttonSection={
                    <Button color="primary" variant="contained" onClick={handleAddItem}>
                        {t('Permissions.add-permission')}
                    </Button>
                }
            />
            <StickyHeadTable
                columns={Columns(t)}
                rows={data}
                handleDeleteItem={handleDeleteAttempt}
                handleAddItem={handleAddItem}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t('Permissions.message-delete-confirm')}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
}
