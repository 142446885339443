import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Answer, AnswerValue } from '../../types/ChecklistAnswerApiResponse';
import { PageWrapper } from '../PageWrapper';
import { useStyles } from './TaskDetailInfo.styles';
import { AnswerAttachment } from '../../types/ChecklistAnswerAttachmentApiResponse';
import { CarouselBase } from '../../components/Carousel/index';
import moment from 'moment';
import { Task } from '../../pages/Tasks/types';
import { useTranslation } from '../../contexts/translationContext';
import { SafetyImpact } from './SafetyImpact';
import { useAuthContext } from '../../contexts/AuthContext';
interface Props {
    data?: Task | null;
    answerData?: Answer | null;
    answerAttachmentData?: AnswerAttachment[] | null;
    onSafetyImpactChange: () => void;
}

export const TaskDetailInfo = ({
    data,
    answerData,
    answerAttachmentData,
    onSafetyImpactChange,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appUser } = useAuthContext();

    return (
        <>
            <Paper>
                <PageWrapper loading={!data && !answerData && !answerAttachmentData}>
                    <Grid container direction={'row'}>
                        <Box px={3} py={2} style={{ overflow: 'auto', width: '100%' }}>
                            <Grid container spacing={2} direction={'column'}>
                                <Grid className={classes.infoPanel} item md={12}>
                                    {data?.parentAnswer.isDangerous && (
                                        <Typography style={{ marginBottom: 10 }}>
                                            <span className={classes.dangerousFinding}>
                                                {t('Tasks.detail.panel.dangerous-finding')}
                                            </span>
                                        </Typography>
                                    )}
                                    <Typography variant={'h1'}>
                                        {t('Tasks.detail.panel.headline')}
                                    </Typography>
                                    {data?.actionSolvedDate && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-1')}
                                            </span>
                                            {moment(data?.actionSolvedDate).format('DD.MM.YYYY')}
                                        </Typography>
                                    )}
                                    {data?.actionSolvedDescription && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-2')}{' '}
                                            </span>
                                            {data?.actionSolvedDescription}
                                        </Typography>
                                    )}
                                    {data?.plannedDate && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-3')}{' '}
                                            </span>
                                            {moment(data?.plannedDate).format('DD.MM.YYYY')}
                                        </Typography>
                                    )}
                                    {data?.vsmName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.vsm-name')}{' '}
                                            </span>
                                            {data?.vsmName}
                                        </Typography>
                                    )}
                                    {data?.productionName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.production-name')}{' '}
                                            </span>
                                            {data?.productionName}
                                        </Typography>
                                    )}
                                    {data?.zoneName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.zone-name')}{' '}
                                            </span>
                                            {data?.zoneName}
                                        </Typography>
                                    )}
                                    {data?.zoneBuilding && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.zone-building')}{' '}
                                            </span>
                                            {data?.zoneBuilding}
                                        </Typography>
                                    )}
                                    {data?.zoneFloor && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.zone-floor')}{' '}
                                            </span>
                                            {data?.zoneFloor}
                                        </Typography>
                                    )}
                                    {data?.solverName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-4')}{' '}
                                            </span>
                                            {data?.solverName}
                                        </Typography>
                                    )}
                                    {data?.plannedActionDescription && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-5')}{' '}
                                            </span>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: data?.plannedActionDescription,
                                                }}
                                            />
                                        </Typography>
                                    )}
                                    {data?.auditType !== 'Gemba' &&
                                        answerData?.questionText &&
                                        answerData?.questionText !== 'None' && (
                                            <Typography>
                                                <span className={classes.headline}>
                                                    {t('Tasks.detail.panel.row-6')}{' '}
                                                </span>
                                                {answerData?.questionText}
                                            </Typography>
                                        )}
                                    {data?.auditType !== 'Gemba' &&
                                        answerData?.answer &&
                                        answerData?.questionText !== 'None' && (
                                            <Typography>
                                                <span className={classes.headline}>
                                                    {t('Tasks.detail.panel.row-7')}{' '}
                                                </span>
                                                {answerData?.answer === AnswerValue.YES
                                                    ? 'Ano'
                                                    : 'Ne'}
                                            </Typography>
                                        )}
                                    {answerData?.comment && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-8')}{' '}
                                            </span>
                                            {answerData?.comment}
                                        </Typography>
                                    )}
                                    {answerData?.questionTypeName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-9')}{' '}
                                            </span>
                                            {answerData?.questionTypeName}
                                        </Typography>
                                    )}
                                    {data?.auditType && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-10')}{' '}
                                            </span>
                                            {data.auditType}
                                        </Typography>
                                    )}

                                    {data?.observedActivity && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.observedActivity')}{' '}
                                            </span>
                                            {data.observedActivity}
                                        </Typography>
                                    )}

                                    {data?.observedPersonExternal && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.observedPersonExternal')}{' '}
                                            </span>
                                            {data.observedPersonExternal}
                                        </Typography>
                                    )}

                                    {data?.observedPersonName && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.observedPersonName')}{' '}
                                            </span>
                                            {data.observedPersonName}
                                        </Typography>
                                    )}

                                    {data?.state && (
                                        <Typography>
                                            <span className={classes.headline}>
                                                {t('Tasks.detail.panel.row-11')}{' '}
                                            </span>
                                            {data.state}
                                        </Typography>
                                    )}
                                    {data && (
                                        <>
                                            <Typography>
                                                <span className={classes.headline}>
                                                    {t('Tasks.detail.panel.row-12')}{' '}
                                                </span>
                                            </Typography>
                                            <SafetyImpact
                                                taskId={data.id}
                                                defaultValue={data.safetyImpact}
                                                auditTypeDangerousFindings={
                                                    data?.auditTypeDangerousFindings
                                                }
                                                onSuccessChange={onSafetyImpactChange}
                                                isDisabled={appUser === null}
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item md={12}>
                                    {answerAttachmentData && (
                                        <CarouselBase
                                            attachments={answerAttachmentData}
                                            fullScreen={false}
                                            isDangerous={data?.parentAnswer.isDangerous}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </PageWrapper>
            </Paper>
        </>
    );
};
