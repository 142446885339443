import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style.scss';
import { App } from './App';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    '8c0fead1d3dfb94578b960a7230c18a2Tz04MDQ0MSxFPTE3MzQwMjA1NDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
