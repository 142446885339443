import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import { ifElse } from 'ramda';
import useSWR from 'swr';
import { equalsZero } from 'ramda-extension';
import { useHistory } from 'react-router-dom';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { RouteParams } from '../Permissions/Permission';
import { ProductionForm } from '../../form/production';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { CreateVsms, VsmApiResponse } from '../../types/VsmApiResponse';
import { appPaths } from '../../paths';
import { PageWrapper } from '../../containers/PageWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
export interface ProductionRouteParams extends RouteParams {
    vsmsId: string;
}

export const ProductionNew = () => {
    const editRoles = [UserRoles.Admin];
    const { t } = useTranslation();
    const { id, vsmsId } = useParams<ProductionRouteParams>();
    const numericId = Number(id);
    const numericVsmsId = Number(vsmsId);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const { data, error } = useSWR<VsmApiResponse>(`${API_URL.VSM_LIST}`, fetcher);

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateVsms>({
                    url: API_URL.PRODUCTION_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(`${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}`);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.PRODUCTION_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);
    const levelUpRedirect = `${appPaths.MASTER_DATA_VSMS}/${numericVsmsId}`;
    return (
        <PageWrapper error={error}>
            <HeaderPage
                stepName={t('Master-data.hierarchy.detail.production.main-headline-new')}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 5 },
                    { name: t('Master-data.hierarchy.detail.main-headline'), pathToCutCount: 3 },
                ]}
                noPaper
                noMb
            />

            <Grid item container xs={5}>
                <Paper>
                    <Box p={3}>
                        <ProductionForm
                            vsms={data}
                            vsmsId={numericVsmsId}
                            onSubmit={submit}
                            buttonTitle={t('Save')}
                            userCanEdit={userCanEdit}
                            goBackUrl={levelUpRedirect}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
