import React, { useEffect, useState } from 'react';

interface Props {
    lastElementRef: any;
}

export const useAuditBtnPos = ({ lastElementRef }: Props) => {
    const [buttonsContainerStyle, setButtonsContainerStyle] = useState<any>({});
    const [calcButtonsPos, setCalcButtonsPos] = useState<boolean>(false);

    // Zkontroluje pozici posledního tlačítko oproti výšce obrazovky.
    // Pokud je pozice níže než výška obrazovky, nechceme nastavovat absolutní pozici buttonu
    // useEffect(() => {
    //     if (!lastElementRef || (!lastElementRef.current && calcButtonsPos)) return;
    //     if (lastElementRef.current.offsetTop < window.innerHeight)
    //         setButtonsContainerStyle({
    //             position: 'absolute',
    //             bottom: '0',
    //             left: '0',
    //             margin: '0',
    //         });

    //     setCalcButtonsPos(false);
    // }, [calcButtonsPos]);

    return { buttonsContainerStyle, setButtonsContainerStyle, setCalcButtonsPos };
};
