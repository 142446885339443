import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import React from 'react';
import { cFieldNames, validationSchema, validationSchemaEdit } from './schema';
import { Role } from '../../hooks/useAppUser';
import { LocalUser, localUserEmpty } from '../../pages/LocalUser/types';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldText from '../../components/Form/FieldText';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: LocalUser | null;
    buttonTitle: string;
    roles?: Array<Role> | null;
    isNew?: boolean;
    t: any;
}

export const LocalUserForm = ({
    onSubmit,
    data,
    buttonTitle,
    roles,
    userCanEdit,
    goBackUrl,
    isNew,
    t,
}: Props) => (
    <Formik
        enableReinitialize
        initialValues={isNil(data) ? localUserEmpty : data}
        validationSchema={isNew ? validationSchema(t) : validationSchemaEdit(t)}
        onSubmit={(values) => {
            onSubmit(values);
        }}
    >
        {({ handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.id}
                            label={t('Local-users.detail.input-1')}
                            component={FieldText}
                            disabled={!isNew}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.name}
                            label={t('Local-users.detail.input-2')}
                            component={FieldText}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.email}
                            label={t('Local-users.detail.input-3')}
                            component={FieldText}
                        />
                    </Grid>
                    {isNew && (
                        <>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    fullWidth
                                    name={cFieldNames.password}
                                    type="password"
                                    label={t('Local-users.detail.input-4')}
                                    component={FieldText}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    fullWidth
                                    name={cFieldNames.password1}
                                    label={t('Local-users.detail.input-5')}
                                    type="password"
                                    component={FieldText}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} md={12} lg={12}></Grid>
                    <SubmitButton
                        visible={userCanEdit}
                        buttonTitle={buttonTitle}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
            </Form>
        )}
    </Formik>
);
