import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { useStyles } from './styles';
import { Production } from '../../types/ProductionApiResponse';
import { Vsm } from '../../types/VsmApiResponse';
import { AuditType } from '../../types/AuditTypeApiResponse';
import { API_URL } from '../../apiTypes';
import useSWR from 'swr';
import { Audit } from '../../types/AuditApiResponse';
import { fetcher } from '../../apiUtils';
import { User } from '../../hooks/useAppUser';
import { Solver } from '../Tasks/types';
import { Filter } from 'ramda';
import { Zone } from '../../types/ZoneApiResponse';

interface Props {
    auditTypeList: AuditType[];
    vsmList: Vsm[];
    productionList: Production[];
    solverList: Solver[];
    zoneList: Zone[];
    defaultFilterParams: FilterParams;
    onFilterChange: (FilterParams) => void;
}

export interface FilterParams {
    auditTypeIds: number[];
    vsmIds: number[];
    productionIds: number[];
    zoneIds: number[];
    gembaGroupIds: number[];
    solverIds: string[];
    selectedOpenOnly: boolean;
    dangerousFinding: boolean;
}

export const ReportTasksFilter = ({
    auditTypeList,
    vsmList,
    productionList,
    solverList,
    zoneList,
    defaultFilterParams,
    onFilterChange,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // Lokální hodnoty filtru. Je nutné držet lokální kvůli kombinace select onChange onClose
    const [filterParams, setFilterParams] = useState<FilterParams>(defaultFilterParams);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minWidth: 450,
            },
        },
    };

    const handleChangeAuditType = (value: number[]) => {
        setFilterParams({ ...filterParams, auditTypeIds: value });
    };

    const handleChangeVsm = (value: number[]) => {
        // profiltrovat produkce
        let filteredProdIds: number[] = [];
        filterParams.productionIds.map((prodId: number) => {
            let prod = productionList.find((prod: Production) => prod.id === prodId);
            if (prod && value.includes(prod.parentVsmId))
                filteredProdIds = [...filteredProdIds, prodId];
        });
        setFilterParams({ ...filterParams, vsmIds: value, productionIds: filteredProdIds });
    };

    const handleChangeProduction = (value: number[]) => {
        setFilterParams({ ...filterParams, productionIds: value });
    };

    const handleChangeZone = (value: number[]) => {
        setFilterParams({ ...filterParams, zoneIds: value });
    };

    const handleChangeSolver = (value: string[]) => {
        setFilterParams({ ...filterParams, solverIds: value });
    };

    const handleSelectedOpenOnly = (value: boolean) => {
        onFilterChange({ ...filterParams, selectedOpenOnly: value });
    };

    const handleDangerousFinding = (value: boolean) => {
        onFilterChange({ ...filterParams, dangerousFinding: value });
    };

    // Při zavření selectu, chceme poslat filterData do nadřazené komponenty. Do té doby pracujeme s filtrem jen lokálně.
    const handleSelectClose = () => {
        onFilterChange(filterParams);
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="audit-type-label">{t('Report.task.filter-1')}</InputLabel>
                    <Select
                        labelId="audit-type-label"
                        multiple
                        value={filterParams.auditTypeIds}
                        onChange={(e) => handleChangeAuditType(e.target.value as number[])}
                        onClose={handleSelectClose}
                        input={<Input />}
                        renderValue={(selected) =>
                            (selected as number[])
                                .map(
                                    (item: number) =>
                                        auditTypeList.find((x: AuditType) => x.id === item)?.name
                                )
                                .join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {auditTypeList.map((auditType: AuditType) => (
                            <MenuItem key={auditType.id} value={auditType.id}>
                                <Checkbox
                                    checked={filterParams.auditTypeIds.some(
                                        (id: number) => id === auditType.id
                                    )}
                                />
                                <ListItemText primary={auditType.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="vsm-label">{t('Report.task.filter-2')}</InputLabel>
                    <Select
                        labelId="vsm-label"
                        multiple
                        value={filterParams.vsmIds}
                        onChange={(e) => handleChangeVsm(e.target.value as number[])}
                        onClose={handleSelectClose}
                        input={<Input />}
                        renderValue={(selected) =>
                            (selected as number[])
                                .map(
                                    (item: number) => vsmList.find((x: Vsm) => x.id === item)?.name
                                )
                                .join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {vsmList.map((vsm: Vsm) => (
                            <MenuItem key={vsm.id} value={vsm.id}>
                                <Checkbox
                                    checked={filterParams.vsmIds.some(
                                        (id: number) => id === vsm.id
                                    )}
                                />
                                <ListItemText primary={vsm.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="production-label">{t('Report.task.filter-3')}</InputLabel>
                    <Select
                        labelId="production-label"
                        multiple
                        value={filterParams.productionIds}
                        onChange={(e) => handleChangeProduction(e.target.value as number[])}
                        onClose={handleSelectClose}
                        input={<Input />}
                        renderValue={(selected) =>
                            (selected as number[])
                                .map(
                                    (item: number) =>
                                        productionList.find((x: AuditType) => x.id === item)?.name
                                )
                                .join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {productionList.map((production: Production) => (
                            <MenuItem key={production.id} value={production.id}>
                                <Checkbox
                                    checked={filterParams.productionIds.some(
                                        (id: number) => id === production.id
                                    )}
                                />
                                <ListItemText primary={production.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="zone-label">{t('Report.task.filter-zone')}</InputLabel>
                    <Select
                        labelId="zone-label"
                        multiple
                        value={filterParams.zoneIds}
                        onChange={(e) => handleChangeZone(e.target.value as number[])}
                        onClose={handleSelectClose}
                        input={<Input />}
                        renderValue={(selected) =>
                            (selected as number[])
                                .map(
                                    (item: number) =>
                                        zoneList.find((x: Zone) => x.id === item)?.name
                                )
                                .join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {zoneList.map((zone: Zone) => (
                            <MenuItem key={zone.id} value={zone.id}>
                                <Checkbox
                                    checked={filterParams.zoneIds.some(
                                        (id: number) => id === zone.id
                                    )}
                                />
                                <ListItemText primary={zone.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="solver-label">{t('Report.task.filter-4')}</InputLabel>
                    <Select
                        labelId="solver-label"
                        multiple
                        value={filterParams.solverIds}
                        onChange={(e) => handleChangeSolver(e.target.value as string[])}
                        onClose={handleSelectClose}
                        input={<Input />}
                        renderValue={(selected) =>
                            (selected as string[])
                                .map(
                                    (item: string) =>
                                        solverList.find((x: Solver) => x.netId === item)?.netId
                                )
                                .join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {solverList.map((solver: Solver) => (
                            <MenuItem key={solver.netId} value={solver.netId}>
                                <Checkbox
                                    checked={filterParams.solverIds.some(
                                        (id: string) => id === solver.netId
                                    )}
                                />
                                <ListItemText primary={solver.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterParams.selectedOpenOnly}
                            onChange={(e) => handleSelectedOpenOnly(e.target.checked)}
                            name="openOnly"
                        />
                    }
                    label={t('Report.task.filter-5')}
                    labelPlacement="start"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterParams.dangerousFinding}
                            onChange={(e) => handleDangerousFinding(e.target.checked)}
                            name="openOnly"
                        />
                    }
                    label={t('Report.task.filter-6')}
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    );
};
