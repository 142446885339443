import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButtons: {
            margin: 3,
        },
        uploadGrid: {
            marginTop: 15,
        },
        historyTable: {
            marginTop: 30,
        },
    })
);
