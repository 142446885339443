import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { isNil } from 'ramda';
import { BreadcrumbsWithHistory, Step } from '../../components/Breadcrumbs/BreadcrumbsWithHistory';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';

interface Props {
    stepName: string;
    breadCrumbSteps?: Array<Step>;
    buttonSection?: any;
    noPaper?: boolean;
    noPy?: boolean;
    noMb?: boolean;
    heading?: string | null;
    buttonSectionPermission?: Array<UserRoles>;
    marginBottom?: number;
}

export const HeaderPage = ({
    heading,
    stepName,
    breadCrumbSteps,
    buttonSection,
    noPaper,
    noPy,
    noMb,
    buttonSectionPermission,
    marginBottom,
}: Props) => {
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const core = (
        <Box
            mb={!noMb ? 1 : 0}
            py={!noPy ? 3 : 0}
            px={isNil(noPaper) ? 3 : 1}
            style={{ marginBottom: marginBottom !== undefined ? marginBottom : 8 }}
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid
                    item
                    container
                    direction="column"
                    sm={6}
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Typography variant="h1" color="secondary" component="h1">
                            {heading || stepName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box mt={1}>
                            {breadCrumbSteps && (
                                <BreadcrumbsWithHistory
                                    pageName={stepName}
                                    steps={breadCrumbSteps}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    {isAccessibleByUserRoleAndAdminPrivilege(buttonSectionPermission) &&
                        buttonSection}
                </Grid>
            </Grid>
        </Box>
    );
    if (noPaper) {
        return core;
    }
    return <Paper>{core}</Paper>;
};
