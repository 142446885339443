import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import FieldSelect from '../../components/Form/FieldSelect';
import { Zone } from '../../types/ZoneApiResponse';
import { Audit, CreateAudit } from '../../types/AuditApiResponse';
import { AuditType } from '../../types/AuditTypeApiResponse';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldCheckBox from '../../components/Form/FieldCheckBox';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translationContext';
import FieldText from '../../components/Form/FieldText';
import { AuditLayoutId } from '../../types/AuditMobile';

export interface IAuditForm extends IForm {
    data: Audit | null;
    zones?: Zone[] | null;
    zoneId: number;
    auditTypes?: AuditType[] | null;
}
interface Props extends IAuditForm {
    onSubmit: (values: any) => void;
    buttonTitle: string;
}

export const AuditForm = ({
    onSubmit,
    data,
    buttonTitle,
    zones,
    zoneId,
    auditTypes,
    userCanEdit,
    goBackUrl,
}: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateAudit = {
        id: 0,
        parentZoneId: zoneId,
        auditTypeId: 0,
        boolParam1: null,
        intParam1: null,
        reminder: false,
        reminderFrequency: 0,
    };
    const [selectedTypeLayout, setSelectedTypeLayout] = useState<number>();
    useEffect(() => {
        if (!data || !auditTypes) return;
        let tmpAuditType = auditTypes.find((x: any) => x.id === data.auditTypeId);
        if (tmpAuditType) {
            setSelectedTypeLayout(tmpAuditType?.layoutId);
        }
    }, [data]);

    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                if (
                    selectedTypeLayout !== AuditLayoutId.Layout3 &&
                    selectedTypeLayout !== AuditLayoutId.Layout4
                )
                    values.boolParam1 = null;
                onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.parentZoneId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.input-1'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled
                            >
                                {zones &&
                                    zones.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.auditTypeId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.input-2'
                                )}
                                fullWidth
                                component={FieldSelect}
                                disabled={!userCanEdit}
                                onChange={(e) => {
                                    if (e.target.value && auditTypes) {
                                        if (e.target.value && auditTypes) {
                                            let tmpAuditType = auditTypes.find(
                                                (x: any) => x.id === e.target.value
                                            );
                                            if (tmpAuditType) {
                                                setSelectedTypeLayout(tmpAuditType?.layoutId);
                                            }
                                        }
                                    }
                                }}
                            >
                                {auditTypes &&
                                    auditTypes.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        {selectedTypeLayout === AuditLayoutId.Layout3 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNames.boolParam1}
                                    label={t(
                                        'Master-data.hierarchy.detail.production.zone.audit.input-3'
                                    )}
                                    component={FieldCheckBox}
                                    disabled={!userCanEdit}
                                />
                            </Grid>
                        )}
                        {selectedTypeLayout === AuditLayoutId.Layout4 && (
                            <>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNames.intParam1}
                                        label={t(
                                            'Master-data.hierarchy.detail.production.zone.audit.input-4'
                                        )}
                                        fullWidth
                                        type="number"
                                        component={FieldText}
                                        disabled={!userCanEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNames.boolParam1}
                                        label={t(
                                            'Master-data.hierarchy.detail.production.zone.audit.input-5'
                                        )}
                                        component={FieldCheckBox}
                                        disabled={!userCanEdit}
                                    />
                                </Grid>
                            </>
                        )}
                        {/* reminder */}
                        <>
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNames.reminder}
                                    label={t(
                                        'Master-data.hierarchy.detail.production.zone.audit.input-6'
                                    )}
                                    component={FieldCheckBox}
                                    disabled={!userCanEdit}
                                    onChange={(e) => {
                                        if (e.target.checked) return;
                                        setFieldValue('reminderFrequency', 0);
                                    }}
                                />
                            </Grid>
                            {values.reminder && (
                                <Grid item xs={12} md={12} lg={12}>
                                    <Field
                                        name={cFieldNames.reminderFrequency}
                                        label={t(
                                            'Master-data.hierarchy.detail.production.zone.audit.input-7'
                                        )}
                                        fullWidth
                                        type="number"
                                        component={FieldText}
                                        disabled={!userCanEdit}
                                    />
                                </Grid>
                            )}
                        </>
                        <SubmitButton visible={userCanEdit} goBackUrl={goBackUrl} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
