import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        marginRight: 0,
    },
    searchButton: {
        minWidth: '30%',
        marginBottom: 5,
        padding: '0 20px',
        float: 'right',
    },
}));
