import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import React from 'react';
import { cFieldNames, validationSchema } from './schema';
import { Role } from '../../hooks/useAppUser';
import { Language, languageInputEmpty, LanguageText } from '../../pages/Language/types';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldText from '../../components/Form/FieldText';

interface Props extends IForm {
    onSubmit: (values: any) => void;
    data?: LanguageText | null;
    buttonTitle: string;
    t: any;
}

export const LanguageTextForm = ({
    onSubmit,
    data,
    buttonTitle,
    userCanEdit,
    goBackUrl,
    t,
}: Props) => (
    <Formik
        enableReinitialize
        initialValues={isNil(data) ? languageInputEmpty : data}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
            onSubmit(values);
        }}
    >
        {({ handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.languageCountryCode}
                            label={t('Languages.detail.edit.input-1')}
                            component={FieldText}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.referenceText}
                            label={t('Languages.detail.edit.input-2')}
                            component={FieldText}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            required
                            name={cFieldNames.translatedText}
                            label={t('Languages.detail.edit.input-3')}
                            component={FieldText}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field
                            fullWidth
                            name={cFieldNames.comment}
                            label={t('Languages.detail.edit.input-4')}
                            component={FieldText}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}></Grid>
                    <SubmitButton
                        visible={userCanEdit}
                        buttonTitle={buttonTitle}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
            </Form>
        )}
    </Formik>
);
