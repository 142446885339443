import { LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import useSWR from 'swr';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { Loader } from '../../components/Loader/Loader';
import { ErrorPage } from '../../components/Snackbar';
import { PageWrapper } from '../../containers/PageWrapper';
import { useTranslation } from '../../contexts/translationContext';
import { useAppUser } from '../../hooks/useAppUser';
import { UserRoles } from '../../types/applicationUser.types';
import { LocalUserList } from './LocalUserList';

export function LocalUserPage() {
    const { appUser } = useAppUser();
    const { t } = useTranslation();
    const isReadOnly = appUser?.roles.includes(UserRoles.Admin) ? false : true;
    const { data: featureIsEnabled, error } = useSWR<boolean>(
        `${API_URL.LOCAL_USER_IS_ENABLED}`,
        fetcher
    );

    if (error) return <ErrorPage error={error} />;
    if (featureIsEnabled === undefined || featureIsEnabled === null) return <Loader />;

    return (
        <PageWrapper loading={!featureIsEnabled}>
            {featureIsEnabled && <LocalUserList isReadOnly={isReadOnly} />}
            {!featureIsEnabled && <Typography>{t('Local-users.feature-not-enabled')}</Typography>}
        </PageWrapper>
    );
}
