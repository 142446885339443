import React, { useCallback } from 'react';
import { QuestionsColumns } from '../../pages/Audit/QuestionsColumns';
import StickyHeadTable from '../../components/Table/Table';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { API_URL } from '../../apiTypes';
import { AlertDialog } from '../../components/AlertDialog';
import { AuditRouterParams } from '../../pages/Audit';
import { AuditQuestion } from '../../types/AuditQuestionApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { useTranslation } from '../../contexts/translationContext';
import { putApi } from '../../apiUtils';
import { NotificationManager } from 'react-notifications';

interface Props extends AuditRouterParams {
    data?: AuditQuestion[];
    mutateQuestion: () => void;
    auditMutate: () => void;
    mutateAudits: () => void;
    handleAddItem: () => void;
    handleEditItem: (item: AuditQuestion) => void;
    editPermission?: Array<UserRoles>;
}
export const AuditTableQuestionsContainer = ({
    data,
    auditMutate,
    mutateQuestion,
    handleAddItem,
    handleEditItem,
    mutateAudits,
    editPermission,
}: Props) => {
    const { t } = useTranslation();
    const onDelete = () => {
        auditMutate();
        mutateQuestion();
        mutateAudits();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDIT_QUESTION_DELETE,
            onDelete,
        });

    const handleChangeOrdering = useCallback(async (id: number, value: number | undefined) => {
        await putApi({
            url: `${API_URL.AUDIT_QUESTION_UPDATE_ORDERING}?id=${id}&ordering=${
                value === undefined ? 0 : value
            }`,
            callAfterSuccess: () => {
                mutateQuestion();
                NotificationManager.success(t('Notify.item-updated'));
            },
        });
    }, []);

    return (
        <>
            <StickyHeadTable
                rows={data}
                columns={QuestionsColumns(t, handleChangeOrdering)}
                handleDeleteItem={handleDeleteAttempt}
                handleAddItem={handleAddItem}
                handleEditItem={handleEditItem}
                addItemAndDeletePermissions={editPermission}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t(
                    'Master-data.hierarchy.detail.production.zone.audit.tab-2.message-delete-confirm'
                )}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </>
    );
};
