import { FieldInputProps } from 'formik';
import React, { useEffect } from 'react';
import { identity, isEmpty, isNil, not, or } from 'ramda';
import { FormikErrors } from 'formik/dist/types';
import { API_URL } from '../../../apiTypes';
import { User } from '../../../hooks/useAppUser';
import { AutocompleteAsync } from '../../AutocompleteAsync';
import { useUserSearch } from '../../../hooks/useUserSearch';
import { useTranslation } from '../../../contexts/translationContext';

interface FieldTextInterface {
    field: FieldInputProps<any>;
    form: {
        touched: any;
        errors: any;
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => Promise<FormikErrors<any>> | Promise<void>;
    };
    fullWidth: boolean | undefined;
    type: string | undefined;
    className?: any;
    disableLabel?: boolean;
}

export const UserSearchField = ({
    field,
    form: { touched, errors, setFieldValue },
    fullWidth,
    type,
    className,
    disableLabel,
    ...props
}: FieldTextInterface) => {
    const { t } = useTranslation();
    const { user, onAutocompleteChange, setUser } = useUserSearch({
        item: {},
        handleChange: identity,
        key: 'netId',
    });

    useEffect(() => {
        onAutocompleteChange(null, { netId: field.value });
    }, [field.value]);

    const preAutocomplete = (event, value) => {
        if (or(isNil(value), isEmpty(value))) {
            setFieldValue(field.name, null, true);
            setUser(null);
        } else {
            setFieldValue(field.name, value.netId, true);
        }
    };
    useEffect(() => {
        if (not(isNil(user))) {
            setFieldValue(field.name, user?.netId, true);
        } else {
            setFieldValue(field.name, null, true);
        }
    }, [user]);
    return (
        <AutocompleteAsync
            label={!disableLabel ? t('Username') : undefined}
            listUrl={API_URL.USER_SEARCH_BY_NAME}
            paramName="name"
            getOptionLabel={(option: User) => option.name ?? ''}
            getOptionSelected={(option: User, value: User) => option.netId === value.netId}
            savedValue={user}
            onChange={(event, value) => preAutocomplete(event, value)}
            className={className}
        />
    );
};
