import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { Box, Grid, Paper } from '@material-ui/core';
import { PageWrapper } from '../../containers/PageWrapper';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { RouteParams } from '../Permissions/Permission';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { equalsZero } from 'ramda-extension';
import { AuditTypeLayoutForm } from '../../form/audit-type-layout';
import { UserRoles } from '../../types/applicationUser.types';
import { ifElse } from 'ramda';
import { appPaths } from '../../paths';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { AuditTypeLayout } from '../../types/AuditTypeLayoutApiResponse';
import { NotificationManager } from 'react-notifications';

export const AuditTypeLayoutNewEdit = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const { id } = useParams<RouteParams>();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const canEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const numericId = Number(id);
    const history = useHistory();

    const { data, error, mutate, isValidating } = useSWR<AuditTypeLayout>(
        `${API_URL.AUDIT_TYPE_LAYOUT_ITEM}?id=${numericId}`,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        }
    );

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutate();
        }
    }, [numericId]);

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi({
                    url: API_URL.AUDIT_TYPE_LAYOUT_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(`${appPaths.AUDIT_TYPE_LAYOUT}`);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDIT_TYPE_LAYOUT_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);
    const goBackUrl = `${appPaths.AUDIT_TYPE_LAYOUT}`;
    return (
        <PageWrapper loading={isValidating} error={error}>
            <HeaderPage
                stepName={t('Master-data.audit-types-layouts.detail.main-headline')}
                breadCrumbSteps={[
                    {
                        name: t('Master-data.audit-types-layouts.main-headline'),
                        pathToCutCount: 1,
                    },
                ]}
                noPaper
                noMb
            />
            <Grid item container xs={12} sm={12} md={6}>
                <Paper>
                    <Box p={3}>
                        <AuditTypeLayoutForm
                            onSubmit={submit}
                            data={data}
                            userCanEdit={canEdit}
                            goBackUrl={goBackUrl}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
