import React, { createContext, useContext, useState } from 'react';
import { Loader } from '../components/Loader/Loader';

interface ILoadingContext {
    loading: boolean;
    startLoading: () => void;
    stopLoading: () => void;
    setLoading: (b: boolean) => void;
}
const LoadingContext = createContext<ILoadingContext>({
    startLoading(): void {
        undefined;
    },
    stopLoading(): void {
        undefined;
    },
    loading: false,
    setLoading: () => {
        undefined;
    },
});

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const LoadingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <LoadingContext.Provider
            value={{
                loading,
                setLoading,
                startLoading: () => setLoading(true),
                stopLoading: () => setLoading(false),
            }}
        >
            {loading && <Loader />}
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    return useContext<ILoadingContext>(LoadingContext);
};
