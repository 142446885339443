import React from 'react';
import useSWR from 'swr';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetcher } from '../../apiUtils';
import { ErrorPage } from '../../components/Snackbar';
import { API_URL } from '../../apiTypes';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { useHistory } from 'react-router';
import { appPaths } from '../../paths';
import { Button } from '@material-ui/core';
import { AlertDialog } from '../../components/AlertDialog';
import StickyHeadTable from '../../components/Table/Table';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { Columns } from './Columns';
import { LocalUserApiResponse } from './types';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

interface Props {
    isReadOnly: boolean;
}
export function LocalUserList({ isReadOnly }: Props) {
    const stepNames = useStepNames();
    const { t } = useTranslation();
    const history = useHistory();
    const { data, error, mutate } = useSWR<LocalUserApiResponse>(
        `${API_URL.LOCAL_USER_LIST}`,
        fetcher
    );
    const onDelete = () => {
        mutate();
    };
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.LOCAL_USER_DELETE,
            onDelete,
        });

    const handleAddItem = ({ id }) => history.push(`${appPaths.LOCAL_USER}/${id}`);

    if (error) return <ErrorPage error={error} />;
    if (!data) return <Loader />;

    return (
        <PageWrapper loading={!data}>
            <HeaderPage
                stepName={t('Local-users.main-headline')}
                heading={t('Local-users.main-headline')}
                buttonSection={
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={isReadOnly ? undefined : () => handleAddItem({ id: 0 })}
                    >
                        {t('Local-users.add-user')}
                    </Button>
                }
            />

            <StickyHeadTable
                columns={Columns(t)}
                rows={data}
                handleDeleteItem={isReadOnly ? undefined : handleDeleteAttempt}
                handleEditItem={isReadOnly ? undefined : handleAddItem}
                handleAddItem={isReadOnly ? undefined : () => handleAddItem({ id: 0 })}
            />
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t('Local-users.message-delete-confirm')}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
}
