import { useHistory, useParams } from 'react-router';
import { ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { RouteParams } from '../Permissions/Permission';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { useAuthContext } from '../../contexts/AuthContext';
import { LocalUserForm } from '../../form/localUser';
import { LocalUser } from '../LocalUser/types';
import { LocalUserPasswordChangeForm } from '../../form/localUser/LocalUserPasswordChangeForm';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

export const LocalUserNewEdit = () => {
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
    const history = useHistory();
    const numericId = Number(id);
    const apiUrl = `${API_URL.LOCAL_USER_ITEM}?id=${id}`;
    const [isNew, setIsNew] = useState<boolean>(false);
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;

    const { data, error, mutate, isValidating } = useSWR<LocalUser>(apiUrl, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutate();
        }
        if (numericId === 0) setIsNew(true);
    }, [numericId]);

    const onSubmit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<LocalUser>({
                    url:
                        API_URL.LOCAL_USER_CREATE +
                        `?id=${values.id}&name=${values.name}&email=${
                            values.email
                        }&password=${encodeURIComponent(values.password)}`,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(appPaths.LOCAL_USER);
                    },
                }),
            () =>
                putApi({
                    url:
                        API_URL.LOCAL_USER_UPDATE +
                        `?id=${values.id}&name=${values.name}&email=${values.email}`,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    const onSubmitPwd = (password: string) => {
        putApi({
            url:
                API_URL.LOCAL_USER_UPDATE_PASSWORD +
                `?id=${id}&password=${encodeURIComponent(password)}`,
            callAfterSuccess: () => NotificationManager.success(t('Notify.request-successful')),
        });
    };

    const goBackUrl = `${appPaths.LOCAL_USER}`;
    return (
        <PageWrapper error={error} loading={isValidating}>
            <HeaderPage
                heading={
                    isNew
                        ? t('Local-users.detail.main-headline-new')
                        : t('Local-users.detail.main-headline-edit')
                }
                stepName={
                    isNew
                        ? t('Local-users.detail.main-headline-new')
                        : t('Local-users.detail.main-headline-edit')
                }
                breadCrumbSteps={[{ name: t('Local-users.main-headline'), pathToCutCount: 1 }]}
                noPaper
                noPy
            />

            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Paper>
                        <Box p={3}>
                            <LocalUserForm
                                isNew={isNew}
                                onSubmit={onSubmit}
                                data={data}
                                goBackUrl={goBackUrl}
                                buttonTitle={t('Save')}
                                t={t}
                            />
                        </Box>
                    </Paper>
                </Grid>
                {!isNew && (
                    <Grid item xs={5}>
                        <Paper>
                            <Box p={3}>
                                <LocalUserPasswordChangeForm onSubmit={onSubmitPwd} />
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </PageWrapper>
    );
};
