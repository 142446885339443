import { Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import {
    AuditQuestionAnswer,
    CreateAuditQuestionAnswer,
} from '../../types/AuditQuestionAnswerApiResponse';
import FieldText from '../../components/Form/FieldText';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import React from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useStyles } from './styles';
import { useTranslation } from '../../contexts/translationContext';
interface Props extends IForm {
    onSubmit: (values: any) => void;
    buttonTitle: string;
    data?: AuditQuestionAnswer | undefined;
    auditQuestionId: number;
}

export const QuestionAnswersForm = ({
    onSubmit,
    userCanEdit,
    goBackUrl,
    data,
    auditQuestionId,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    const initialValues: CreateAuditQuestionAnswer = {
        id: 0,
        auditQuestionId: auditQuestionId,
        answerText: '',
        answerScore: 0,
        answerDescription: '',
    };

    useEffect(() => {
        if (!data) return;
        if (data.answerDescription) {
            const html = data.answerDescription;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, [data]);

    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values: CreateAuditQuestionAnswer) => {
                if (userCanEdit) {
                    if (editorState) {
                        if (editorState.getCurrentContent().getPlainText().length > 0) {
                            values.answerDescription = draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                            );
                        } else {
                            values.answerDescription = null;
                        }
                    }
                    onSubmit(values);
                }
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.answerText}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.answer.input-1'
                                )}
                                fullWidth
                                minRows={3}
                                component={FieldText}
                                multiline
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.answerScore}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.answer.input-2'
                                )}
                                fullWidth
                                type={'number'}
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName={classes.editorClassName}
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'fontFamily',
                                        'list',
                                        'textAlign',
                                        'colorPicker',
                                    ],
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                }}
                            />
                        </Grid>
                        <SubmitButton goBackUrl={goBackUrl} visible={userCanEdit} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
