import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        success: {
            color: theme.palette.success.main,
        },
        failure: {
            color: theme.palette.error.main,
        },
        warning: {
            color: theme.palette.warning.main,
        },
    })
);
