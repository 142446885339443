import React from 'react';
import AppDrawer from './components/AppDrawer';
import appRoutes, { offContextRoutes } from './appRoutes';
import { Route, Switch } from 'react-router-dom';
import './desktop-content.scss';

export function DesktopContent() {
    return (
        <>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <AppDrawer>
                <Switch>
                    {appRoutes.map(({ path, component }, index) => (
                        <Route exact key={index} path={path} component={component} />
                    ))}
                </Switch>
            </AppDrawer>
            <Switch>
                {offContextRoutes.map((e, index) => (
                    <Route exact path={e.path} key={index} component={e.component} />
                ))}
            </Switch>
        </>
    );
}
