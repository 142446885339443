import { LinkType, menuItems } from '../../menu';
import { cond, equals, T } from 'ramda';
import { MenuItemLink } from './LinkItem';
import { ListItemCollapsible } from '../AppDrawer/ListItemCollapsible';
import { List } from '@material-ui/core';
import React from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../Loader/Loader';

export const MenuItems = () => {
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();

    if (
        isAccessibleByUserRoleAndAdminPrivilege === undefined ||
        isAccessibleByUserRoleAndAdminPrivilege === null
    )
        return <Loader />;
    return (
        <List disablePadding>
            {menuItems
                /* .filter((i) => isAccessibleByUserRoleAndAdminPrivilege(i.restrictedAccess)) */
                .map((m, index) =>
                    cond([
                        [
                            equals(LinkType.External),
                            () => <MenuItemLink key={index} item={m} index={index} />,
                        ],
                        [
                            equals(LinkType.Sub),
                            () => <ListItemCollapsible menuItem={m} key={index} />,
                        ],
                        [T, () => <MenuItemLink item={m} key={index} index={index} />],
                        // @ts-ignore
                    ])(m.type ?? '')
                )}
        </List>
    );
};
