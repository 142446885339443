import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    parentZoneId: 'parentZoneId',
    auditTypeId: 'auditTypeId',
    boolParam1: 'boolParam1',
    intParam1: 'intParam1',
    reminder: 'reminder',
    reminderFrequency: 'reminderFrequency',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.auditTypeId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0)),
        [cFieldNames.parentZoneId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0)),
    });
};
