import { Field, Form, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, questionAnswersOptions, validationSchema } from './schema';
import FieldSelect from '../../components/Form/FieldSelect';
import { AuditTypeApiResponse } from '../../types/AuditTypeApiResponse';
import { AuditQuestion, CreateAuditQuestion } from '../../types/AuditQuestionApiResponse';
import { AuditQuestionTypeApiResponse } from '../../types/AuditQuestionTypeApiResponse';
import FieldText from '../../components/Form/FieldText';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import FieldCheckBox from '../../components/Form/FieldCheckBox';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import React, { Component } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useStyles } from './styles';
import { useTranslation } from '../../contexts/translationContext';

export interface IQuestionsForm extends IForm {
    data?: AuditQuestion | null;
    audits?: AuditTypeApiResponse | null;
    auditId: number;
    questionTypes?: AuditQuestionTypeApiResponse | null;
    selectedTypeLayout?: number;
}

interface Props extends IQuestionsForm {
    onSubmit: (values: any) => void;
    buttonTitle: string;
}

export const QuestionsForm = ({
    onSubmit,
    data,
    auditId,
    audits,
    questionTypes,
    userCanEdit,
    goBackUrl,
    selectedTypeLayout,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    useEffect(() => {
        if (!data) return;
        if (data.description) {
            const html = data.description;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, [data]);
    const initialValues: CreateAuditQuestion = {
        id: 0,
        level: '',
        ordering: 0,
        questionText: '',
        correctAnswer: '',
        photoRequiredForWrongAnswer: false,
        parentAuditId: auditId,
        typeId: '',
        description: '',
        intParam1: null, 
    };
    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                if (userCanEdit) {
                    if (selectedTypeLayout === 3) {
                        values.correctAnswer = 'None';
                        if (values.intParam1 !== null && values.intParam1 === '') {
                            values.intParam1 = null;
                        }
                    }
                    if (editorState)
                        values.description = draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                        );

                    onSubmit(values);
                }
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.parentAuditId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.input-1'
                                )}
                                fullWidth
                                minRows={4}
                                component={FieldSelect}
                                disabled
                            >
                                {audits &&
                                    audits.map(({ id, auditTypeName, parentZoneName }) => (
                                        <MenuItem key={id} value={id}>
                                            {`${auditTypeName} - ${parentZoneName}`}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.level}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.input-2'
                                )}
                                fullWidth
                                minRows={4}
                                component={FieldSelect}
                                disabled={!userCanEdit}
                            >
                                <MenuItem key="Level1" value="Level1">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.question.input-2-option-1'
                                    )}
                                </MenuItem>
                                <MenuItem key="Level2" value="Level2">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.question.input-2-option-2'
                                    )}
                                </MenuItem>
                                <MenuItem key="Level3" value="Level3">
                                    {t(
                                        'Master-data.hierarchy.detail.production.zone.audit.question.input-2-option-3'
                                    )}
                                </MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.typeId}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.input-3'
                                )}
                                fullWidth
                                minRows={4}
                                component={FieldSelect}
                                disabled={!userCanEdit}
                            >
                                {questionTypes &&
                                    questionTypes.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.questionText}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.input-4'
                                )}
                                fullWidth
                                minRows={4}
                                component={FieldText}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        {selectedTypeLayout === 3 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNames.intParam1}
                                    required
                                    label={t(
                                        'Master-data.hierarchy.detail.production.zone.audit.question.input-5'
                                    )}
                                    type="number"
                                    fullWidth
                                    minRows={4}
                                    component={FieldText}
                                    disabled={!userCanEdit}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                // wrapperClassName="wrapperClassName"
                                editorClassName={classes.editorClassName}
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'fontFamily',
                                        'list',
                                        'textAlign',
                                        'colorPicker',
                                    ],
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                }}
                            />
                        </Grid>
                        {selectedTypeLayout !== 3 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Field
                                    name={cFieldNames.correctAnswer}
                                    label={t(
                                        'Master-data.hierarchy.detail.production.zone.audit.question.input-6'
                                    )}
                                    fullWidth
                                    minRows={4}
                                    component={FieldSelect}
                                    disabled={!userCanEdit}
                                >
                                    {questionAnswersOptions &&
                                        questionAnswersOptions.map(({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                </Field>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <Field
                                name={cFieldNames.photoRequiredForWrongAnswer}
                                label={t(
                                    'Master-data.hierarchy.detail.production.zone.audit.question.input-7'
                                )}
                                component={FieldCheckBox}
                                disabled={!userCanEdit}
                            />
                        </Grid>
                        <SubmitButton goBackUrl={goBackUrl} visible={userCanEdit} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
