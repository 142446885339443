export enum KpiItemMemberLevelAccess {
    MEMBER = 'Member',
    OWNER = 'Owner',
}

export interface KpiItemMember {
    id: number;
    netId: string;
    access: KpiItemMemberLevelAccess;
    [key: string]: string | number;
}

export type KpiItemMembersApiResponse = Array<KpiItemMember>;

export const getKpiItemMemberEmpty = (kpiItemId: number): KpiItemMember => ({
    id: 0,
    netId: '',
    access: KpiItemMemberLevelAccess.MEMBER,
    parentItemId: kpiItemId,
});
