export interface Login {
    username: string;
    password: string;
    persist: boolean;
    [key: string]: string | number | boolean | null;
}

export const loginEmpty: Login = {
    username: '',
    password: '',
    persist: false,
};
