import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { isNilOrEmpty } from 'ramda-extension';
import { not } from 'ramda';
import { useStyles } from './Table.styles';
import { formatCellValue } from './formatCellValue';
import { ItemWithId } from '../../apiTypes';
import { UserRoles } from '../../types/applicationUser.types';
import { Toolbar, Typography } from '@material-ui/core';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLanguageContext } from '../../contexts/LanguageContext';
import { Loader } from '../Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';

export type CellValue = string | number | boolean | null;
export enum ColumnFormat {
    NUMBER = 'number',
    DATE = 'date',
    DATETIME = 'datetime',
    ERROR = 'error',
    HTML_TEXT = 'html_text',
}

export interface Column {
    id: string;
    label: string;
    format?: ColumnFormat;
    minWidth?: number;
    align?: 'right';
    renderCell?: (data: any) => void;
}

interface Props<T> {
    rows?: Array<T>;
    columns: Array<Column>;
    handleDeleteItem?: (id: any) => void;
    handleEditItem?: (item: T) => void;
    handleAddItem?: () => void;
    handleErrorIconClick?: (item: T) => void;
    handleDetailItem?: (item: T) => void;
    handleDownloadItem?: (item: T) => void;
    addItemAndDeletePermissions?: Array<UserRoles>;
    title?: string;
}

export default function StickyHeadTable<T extends ItemWithId>({
    rows,
    columns,
    handleDeleteItem,
    handleEditItem,
    handleAddItem,
    handleErrorIconClick,
    handleDetailItem,
    handleDownloadItem,
    addItemAndDeletePermissions,
    title,
}: Props<T>) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    const { language } = useLanguageContext();

    if (
        isAccessibleByUserRoleAndAdminPrivilege === undefined ||
        isAccessibleByUserRoleAndAdminPrivilege === null
    )
        return <Loader />;
    return (
        <Paper className={classes.root}>
            {title && (
                <Toolbar>
                    <Typography variant="h6" id="tableTitle" color="secondary" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            )}
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {(handleEditItem || handleDeleteItem || handleDownloadItem) && (
                                <TableCell className={classes.actions} />
                            )}
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            {handleDetailItem && <TableCell className={classes.detail} />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {not(isNilOrEmpty(rows)) &&
                            rows &&
                            rows.map((row, key) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                    {(handleEditItem || handleDeleteItem || handleDownloadItem) && (
                                        <TableCell className={classes.actions}>
                                            {handleEditItem &&
                                                isAccessibleByUserRoleAndAdminPrivilege(
                                                    addItemAndDeletePermissions
                                                ) && (
                                                    <Tooltip title={t('Edit')}>
                                                        <IconButton
                                                            size="small"
                                                            color="secondary"
                                                            onClick={() => handleEditItem(row)}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            {handleDeleteItem &&
                                                isAccessibleByUserRoleAndAdminPrivilege(
                                                    addItemAndDeletePermissions
                                                ) && (
                                                    <Tooltip title={t('Delete')}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleDeleteItem(row.id as number)
                                                            }
                                                        >
                                                            <DeleteIcon
                                                                fontSize="small"
                                                                color="secondary"
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            {handleDownloadItem && (
                                                <Tooltip title={t('Download')}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDownloadItem(row)}
                                                    >
                                                        <GetAppIcon
                                                            fontSize="small"
                                                            color="secondary"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    )}
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.renderCell ? (
                                                    <>{column.renderCell(row)}</>
                                                ) : (
                                                    formatCellValue<T>({
                                                        value,
                                                        row,
                                                        language,
                                                        format: column.format,
                                                        actions: { error: handleErrorIconClick },
                                                    })
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                    {handleDetailItem && (
                                        <TableCell className={classes.detail}>
                                            <Tooltip title={t('Detail')}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDetailItem(row)}
                                                >
                                                    <ArrowForwardIosIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        {handleAddItem &&
                            isAccessibleByUserRoleAndAdminPrivilege(
                                addItemAndDeletePermissions
                            ) && (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell align="center" className={classes.addRow}>
                                        <Tooltip title={t('Add')}>
                                            <IconButton size="small" onClick={handleAddItem}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} />
                                    ))}
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
