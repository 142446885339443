import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { Box, Modal } from '@material-ui/core';

interface Props {
    text: string;
    open: boolean;
    onClose?: any;
}

export const DescriptionModal = ({ text, open, onClose }: Props) => {
    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '20%',
                        left: '10%',
                        //right: '15%',
                        width: '80%',
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </Box>
            </Modal>
        </>
    );
};
