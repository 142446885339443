import { useEffect, useState } from 'react';
import { API_URL } from '../apiTypes';
import { getItemApi } from '../apiUtils';

export const useOnlineCheck = () => {
    const [isOnline, setOnline] = useState<Boolean>(true);

    // Kontroluje online status a zkouší provolat api (kvůli připojení na VPN), nastavuje state při změně
    useEffect(() => {
        if (window.navigator.onLine === isOnline) {
            getItemApi({
                url: `${API_URL.IS_AUTHENTICATED}`,
                params: {},
                callAfterSuccess: () => {
                    setOnline(true);
                },
                callAfterError: () => {
                    setOnline(false);
                },
            });
        }
    }, [window.navigator.onLine]);

    return {
        isOnline,
        setOnline,
    };
};
