import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoPanel: {
            '& p': {
                marginTop: 10,
            },
        },
        headline: {
            fontWeight: 'bold',
        },
        dangerousFinding: {
            fontWeight: 'bold',
            color: 'red',
        },
    })
);
