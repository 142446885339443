import React, { FC, useMemo } from 'react';
import { Grid, Paper, Box, Typography } from '@material-ui/core';
import { ReportLevel } from '../../types/ReportsTypes';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
moment.locale('cs');

const useStyles = makeStyles((theme) => ({
    successPaper: {
        borderLeft: `5px solid ${theme.palette.success.main}`,
        background: '#4caf502b',
    },
    dangerPaper: {
        borderLeft: `5px solid ${theme.palette.error.main}`,
        background: '#f4433626',
    },
    missingPaper: {
        borderLeft: `5px solid #62626294`,
        background: 'rgba(145,145,145,0.15)',
    },
    selectedPaper: {
        borderLeft: `5px solid #ea8503`,
        background: 'rgba(234, 133, 3,0.15)',
    },
}));
interface Props {
    item: ReportLevel;
    selectedCheckListId?: number;
    attachmentsCount: number;
    onSelectItem: (id: number) => void;
}

export const ReportItem: FC<Props> = ({
    item,
    onSelectItem,
    selectedCheckListId,
    attachmentsCount,
}) => {
    const classes = useStyles();

    const isSelected: boolean = useMemo(() => {
        return item.checklistId === selectedCheckListId;
    }, [selectedCheckListId]);

    return (
        <Grid item md={12}>
            <Box mt={1} mx={2}>
                <Paper
                    onClick={() => (!item.missingRecord ? onSelectItem(item.checklistId) : null)}
                    style={{ cursor: item.missingRecord ? 'Auto' : 'pointer' }}
                    className={classNames('', {
                        [classes.dangerPaper]: !item.missingRecord && !item.resultOk && !isSelected,
                        [classes.successPaper]: !item.missingRecord && item.resultOk && !isSelected,
                        [classes.missingPaper]:
                            item.missingRecord || (selectedCheckListId && !isSelected),
                        [classes.selectedPaper]: isSelected,
                    })}
                >
                    <Box p={0}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <Typography style={{ fontSize: '0.6rem' }}>
                                    {item.zoneName}{' '}
                                    {attachmentsCount ? `(${attachmentsCount})` : ''}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" style={{ fontSize: '0.6rem' }}>
                                    {item.authorName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontSize: '0.6rem' }}>
                                    {moment(item.date).format('DD.MM.YYYY HH:mm')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Grid>
    );
};
