// @ts-ignore
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FieldInputProps } from 'formik';
import TextField from '../TextField';

interface FieldTextInterface {
    field: FieldInputProps<any>;
    form: {
        touched: any;
        errors: any;
    };
    fullWidth: boolean | undefined;
    type: string | undefined;
}

const FieldText = ({
    field,
    form: { touched, errors },
    fullWidth,
    type,
    ...props
}: FieldTextInterface) => {
    const hasError = () => {
        if (touched[field.name] && errors[field.name]) {
            return true;
        }
    };

    return (
        <FormControl fullWidth={fullWidth}>
            <TextField
                type={type || 'text'}
                helperText={touched[field.name] && errors[field.name] && errors[field.name]}
                error={hasError()}
                variant="outlined"
                {...field}
                {...props}
            />
        </FormControl>
    );
};

export default FieldText;
