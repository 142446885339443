import React, { ReactNode, useState } from 'react';
import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    Drawer,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';
import { MenuItems } from '../Menu';
import { useToggle } from '../../hooks/useToggle';
import classNames from 'classnames';
import { useAuthContext } from '../../contexts/AuthContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from '../../contexts/translationContext';
import { LanguageChanger } from '../LanguageChanger/LanguageChanger';
import { useHistory } from 'react-router';

interface AppDrawerInterface {
    children: ReactNode;
}

const AppDrawer = ({ children }: AppDrawerInterface) => {
    const { t, changeLang, lang } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const [anchorElLogout, setAnchorElLogout] = React.useState(null);
    const [languageOpen, setLanguageOpen] = useState<boolean>(false);

    const handleClickUserMenu = (event) => {
        if (!appUser) {
            history.push('/login');
            return;
        }

        setAnchorElLogout(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElLogout(null);
    };
    const handleConfirmLogout = () => {
        setAnchorElLogout(null);
        if (signOut) signOut();
    };
    const handleOpenLanguageModal = () => {
        setLanguageOpen(true);
        setAnchorElLogout(null);
    };

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { state: collapse, toggle } = useToggle(false);

    const { appUser, signOut } = useAuthContext();

    return (
        <Grid container>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Box>
                            <Grid container alignItems="center">
                                {isMobileView && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="Open drawer"
                                        edge="start"
                                        onClick={() => setIsMobileNavOpen(true)}
                                        className={classes.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                {!isMobileView && (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            edge="start"
                                            onClick={() => {
                                                setIsMobileNavOpen(true);
                                                toggle();
                                            }}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography variant="h1">
                                            {t('Menu.main-headline')}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <div>
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClickUserMenu}
                            >
                                <Typography variant="subtitle1" style={{ color: '#fff' }}>
                                    {appUser ? appUser.name : <> {t('User-menu.login')}</>}
                                </Typography>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorElLogout}
                                keepMounted
                                open={Boolean(anchorElLogout)}
                                onClose={handleCloseUserMenu}
                                style={{ marginTop: '30px' }}
                            >
                                <MenuItem onClick={handleOpenLanguageModal}>
                                    <LanguageIcon style={{ margin: '5px' }} />
                                    {t('User-menu.change-language')}
                                </MenuItem>
                                <MenuItem onClick={handleConfirmLogout}>
                                    <ExitToAppIcon style={{ margin: '5px' }} />
                                    {t('User-menu.logout')}
                                </MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                </Toolbar>
            </AppBar>
            <LanguageChanger isOpen={languageOpen} onClose={() => setLanguageOpen(false)} />
            {isMobileView || collapse ? (
                <Drawer
                    anchor="left"
                    open={isMobileView && isMobileNavOpen}
                    onClose={() => setIsMobileNavOpen(false)}
                >
                    <IconButton
                        onClick={() => setIsMobileNavOpen(false)}
                        className={classes.closeMenuButton}
                    >
                        <CloseIcon />
                    </IconButton>
                    <MenuItems />
                </Drawer>
            ) : (
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        <div>
                            <Box py={1} />
                            <MenuItems />
                        </div>
                    </div>
                </Drawer>
            )}

            <main
                className={classNames('', {
                    [classes.contentUpSm]: !isMobileView,
                    [classes.contentDownSm]: !isMobileView && !collapse,
                    [classes.contentDownSm]: !isMobileView && collapse,
                })}
                style={{ backgroundColor: '#fafafa' }}
            >
                <Toolbar />
                {children}
            </main>
        </Grid>
    );
};

export default AppDrawer;
