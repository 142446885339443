import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../contexts/translationContext';
import { ReportLayout3, WeeklyTopic } from '../../types/Report5STypes';
import moment from 'moment';
import { dateShowFormat } from '../../types/core/date.types';
import Carousel from 'react-material-ui-carousel';
import { API_URL } from '../../apiTypes';
import { useStyles } from './styles';

interface Props {
    data: ReportLayout3;
}
interface PropsRow {
    title: string;
    rowData?: string | number;
}

const TopicRow = ({ title, rowData }: PropsRow) => {
    return (
        <Grid container item spacing={3}>
            <Grid item xs={5}>
                <Typography variant={'body1'}>{title}</Typography>
                <Box mr={3} />
            </Grid>
            <Grid item xs={7}>
                <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    {rowData}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const Report5STopicOfTheWeek = ({ data }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const counterDefaultValue = 30;
    const [counter, setCounter] = useState(counterDefaultValue);

    useEffect(() => {
        let timer1 = setTimeout(() => setCounter(counter - 1), 1000);
        return () => {
            clearInterval(timer1);
        };
    }, [counter]);

    return (
        <>
            <Grid
                container
                className={classes.flexContainer}
                justifyContent={'center'}
                style={{ justifyContent: 'center', height: '100%' }}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.flexItemStretch}
                    style={{
                        height: '100%',
                    }}
                >
                    <Carousel
                        autoPlay={true}
                        interval={30000}
                        animation={'slide'}
                        indicators={false}
                        stopAutoPlayOnHover={false}
                        onChange={() => {
                            setCounter(counterDefaultValue);
                        }}
                        navButtonsAlwaysVisible={true}
                        fullHeightHover={false}
                        navButtonsProps={{
                            className: classes.carouselNavButtonsDef,
                        }}
                        navButtonsWrapperProps={{
                            className: classes.carouselNavButtonsDefWrapper,
                        }}
                        className={classes.carouselMain}
                    >
                        {data.weeklyTopics &&
                            data?.weeklyTopics?.map((weeklyTopic: WeeklyTopic, index: number) => {
                                let topicHeaderNum =
                                    +(index + 1) + ' / ' + data.weeklyTopics.length;
                                return (
                                    <>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <Typography
                                                    variant="h2"
                                                    className={classes.sectionHeadline}
                                                >
                                                    {t('Report.5S.weekly-topic.topic-of-the-week') +
                                                        ' ' +
                                                        topicHeaderNum}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{ padding: '10px 10px 0px 10px' }}
                                            >
                                                <TopicRow
                                                    title={t(
                                                        'Report.5S.weekly-topic.label.production'
                                                    )}
                                                    rowData={weeklyTopic?.vsmName}
                                                />
                                                <TopicRow
                                                    title={t('Report.5S.weekly-topic.label.zone')}
                                                    rowData={weeklyTopic?.zoneName}
                                                />
                                                <TopicRow
                                                    title={t('Report.5S.weekly-topic.label.date')}
                                                    rowData={moment(weeklyTopic?.date).format(
                                                        dateShowFormat
                                                    )}
                                                />
                                                <TopicRow
                                                    title={t(
                                                        'Report.5S.weekly-topic.label.description'
                                                    )}
                                                    rowData={weeklyTopic?.topic}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{ height: '100%' }}>
                                                <Carousel
                                                    autoPlay
                                                    interval={10000}
                                                    animation={'slide'}
                                                    indicators={
                                                        weeklyTopic.photoIdArr
                                                            ? weeklyTopic.photoIdArr.length > 1
                                                            : false
                                                    }
                                                    fullHeightHover={true}
                                                    timeout={500}
                                                    cycleNavigation
                                                    stopAutoPlayOnHover={false}
                                                    indicatorContainerProps={{
                                                        style: {
                                                            marginTop: '-10px',
                                                        },
                                                    }}
                                                >
                                                    {weeklyTopic?.photoIdArr &&
                                                        weeklyTopic?.photoIdArr.map(
                                                            (photoId: number, index: number) => {
                                                                return (
                                                                    <Box key={index} p={1}>
                                                                        <div
                                                                            style={{
                                                                                maxHeight: '300px',
                                                                                minHeight: '200px',
                                                                                background: `url("${API_URL.CHECK_LIST_ATTACHMENT_GET}?id=${photoId}")`,
                                                                                backgroundRepeat:
                                                                                    'no-repeat',
                                                                                backgroundPosition:
                                                                                    'center center',
                                                                                backgroundSize:
                                                                                    'contain',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                );
                                                            }
                                                        )}
                                                </Carousel>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                    </Carousel>
                    <Typography
                        style={{ position: 'absolute', top: '40%', left: '70%', fontSize: '1.5em' }}
                    >
                        {counter}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    className={classes.flexItem}
                    style={{
                        borderTop: '1px solid #000',
                        borderRight: '1px solid #000',
                        minHeight: '15vh',
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.numberSectionHeadline}>
                            {t('Report.5S.weekly-topic.current-week')}
                        </Typography>
                        <Typography variant="h2" className={classes.numberSection}>
                            {data.currentWeek}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
