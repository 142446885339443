import { Button, Grid } from '@material-ui/core';
import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { RouteParams } from '../Permissions/Permission';
import StickyHeadTable from '../../components/Table/Table';
import { Columns } from './Columns';
import { CreateVsms } from '../../types/VsmApiResponse';
import { VsmDetailFormContainer } from '../../containers/VsmDetailForm';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { AlertDialog } from '../../components/AlertDialog';
import { useDetailRoutes } from '../../hooks/useDetailRoutes';
import { appPaths } from '../../paths';
import { ProductionApiResponse } from '../../types/ProductionApiResponse';
import { UserRoles } from '../../types/applicationUser.types';
import { useTranslation } from '../../contexts/translationContext';

export const VsmsDetail = () => {
    const editRoles = [UserRoles.Admin];
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
    const numericId = Number(id);
    const history = useHistory();
    const { data, error, mutate } = useSWR<ProductionApiResponse>(
        `${API_URL.PRODUCTION_LIST}?parentId=${numericId}`,
        fetcher
    );
    const {
        data: vsmDetail,
        error: vsmError,
        mutate: vsmMutate,
    } = useSWR<CreateVsms>(`${API_URL.VSM_ITEM}?id=${numericId}`, fetcher);

    const { handleAddItem } = useDetailRoutes(
        appPaths.MASTER_DATA_VSMS,
        `/${id}/production`,
        'new'
    );

    const onDelete = () => {
        mutate();
    };

    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.PRODUCTION_DELETE,
            onDelete,
        });
    const goBackUrl = `${appPaths.MASTER_DATA}`;

    return (
        <PageWrapper loading={!data || !vsmDetail} error={error || vsmError}>
            <HeaderPage
                heading={vsmDetail?.name}
                stepName={t('Master-data.hierarchy.detail.main-headline')}
                breadCrumbSteps={[{ name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 2 }]}
                buttonSectionPermission={editRoles}
                buttonSection={
                    <Button variant="contained" color="primary" onClick={handleAddItem}>
                        {t('Master-data.hierarchy.detail.add-production')}
                    </Button>
                }
            />
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <VsmDetailFormContainer
                        editPermission={editRoles}
                        data={vsmDetail}
                        mutate={vsmMutate}
                        goBackUrl={goBackUrl}
                    />
                </Grid>
                <Grid item md={8}>
                    <StickyHeadTable
                        rows={data}
                        columns={Columns(t)}
                        handleDetailItem={({ id: itemId }) =>
                            history.push(
                                `${appPaths.MASTER_DATA_VSMS}/${numericId}/production/${itemId}`
                            )
                        }
                        handleDeleteItem={handleDeleteAttempt}
                        handleAddItem={handleAddItem}
                        addItemAndDeletePermissions={editRoles}
                    />
                    <AlertDialog
                        open={openAlert}
                        title={t('Delete')}
                        content={t('Master-data.hierarchy.detail.message-delete-confirm')}
                        handleClose={handleAlertClose}
                        handleConfirm={handleAlertConfirm}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
