import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ifElse } from 'ramda';
import { equalsZero } from 'ramda-extension';
import { QuestionTypeForm } from '../../form/question-type';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { AuditQuestionType } from '../../types/AuditQuestionTypeApiResponse';
import { PageWrapper } from '../../containers/PageWrapper';
import { RouteParams } from '../Permissions/Permission';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

export const QuestionTypeNewEdit = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const { id } = useParams<RouteParams>();
    const numericId = Number(id);
    const history = useHistory();

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editRoles);

    const { data, error, mutate, isValidating } = useSWR<AuditQuestionType>(
        `${API_URL.AUDIT_QUESTION_TYPE_ITEM}?id=${numericId}`,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
        }
    );

    const submit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi({
                    url: API_URL.AUDIT_QUESTION_TYPE_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(`${appPaths.QUESTION_TYPES}`);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.AUDIT_QUESTION_TYPE_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutate();
        }
    }, [numericId]);

    const goBackUrl = `${appPaths.QUESTION_TYPES}`;

    return (
        <PageWrapper error={error} loading={isValidating}>
            <HeaderPage
                stepName={
                    numericId === 0
                        ? t('Master-data.topics.detail.main-headline-new')
                        : t('Master-data.topics.detail.main-headline-edit')
                }
                heading={
                    numericId === 0
                        ? t('Master-data.topics.detail.main-headline-new')
                        : t('Master-data.topics.detail.main-headline-edit')
                }
                breadCrumbSteps={[
                    { name: t('Master-data.topics.detail.main-headline'), pathToCutCount: 1 },
                ]}
                noPaper
                noMb
            />

            <Grid item container xs={12} md={5}>
                <Paper>
                    <Box p={3}>
                        <QuestionTypeForm
                            data={data}
                            onSubmit={submit}
                            goBackUrl={goBackUrl}
                            userCanEdit={userCanEdit}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
