export enum UserRoles {
    Admin = 'admin',
    Edit = 'edit',
    Any = 'any',
    Public = 'public',
}

export interface ApplicationUser {
    id: number;
    uId: number;
    cardNumber: string | null;
    defaultLanguage: number;
    email: string | null;
    name: string | null;
    userName: string;
    type: number | null;
    roles: UserRoles[];
}

// export const defaulApplicationUser: ApplicationUser = {
//     id: 0,
//     uId: 0,
//     cardNumber: null,
//     defaultLanguage: 0,
//     email: null,
//     name: 'Default user',
//     userName: 'defaultUser',
//     type: 0,
//     roles: [],
// }
