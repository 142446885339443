import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CellValue, ColumnFormat } from './Table';
import { Failure } from '../Icons/Icons';
import { Language } from '../../contexts/AppContext';

interface Props<T> {
    value: CellValue;
    row: T;
    language: Language | null;
    format?: ColumnFormat;
    actions?: Partial<Record<ColumnFormat, (row) => void>>;
}

export function formatCellValue<T>({ value, row, language, format, actions }: Props<T>) {
    if (value === null) {
        return null;
    }

    if (format === ColumnFormat.ERROR) {
        const handleErrorClick = actions?.[ColumnFormat.ERROR];

        if (!value) {
            return null;
        }

        if (row && handleErrorClick) {
            return (
                <IconButton size="small" onClick={() => handleErrorClick(row)}>
                    <Failure />
                </IconButton>
            );
        }
        return <Failure />;
    }

    if (typeof value === 'boolean') {
        if (value) {
            return 'Ano'; // language === Language.EN ? 'Yes' : 'Ano'
        }
        return 'Ne'; // language === Language.EN ? 'No' : 'Ne'
    }

    if (format === ColumnFormat.DATE) {
        return new Intl.DateTimeFormat(language ?? Language.EN).format(new Date(value));
    }

    if (format === ColumnFormat.DATETIME) {
        return new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }).format(new Date(value));
    }
    if (format === ColumnFormat.HTML_TEXT) {
        if (typeof value === 'string') return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }

    if (format === ColumnFormat.NUMBER) {
        return value.toLocaleString(language ?? Language.EN);
    }

    return value;
}
