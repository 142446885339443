import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { VsmsForm } from '../../form/vsms/VsmsForm';
import { CreateVsms } from '../../types/VsmApiResponse';
import { putApi } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import { UserRoles } from '../../types/applicationUser.types';
import { IForm } from '../../types/IForm';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';
interface Props extends IForm {
    data?: CreateVsms | null;
    mutate: () => void;
    editPermission?: Array<UserRoles>;
}
export const VsmDetailFormContainer = ({ data, mutate, editPermission, goBackUrl }: Props) => {
    const { t } = useTranslation();
    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(editPermission);
    const editVsm = async (values: CreateVsms) => {
        if (!userCanEdit) return;
        await putApi({
            url: API_URL.VSM_UPDATE,
            data: values,
            callAfterSuccess: () => {
                mutate();
                NotificationManager.success(t('Notify.item-updated'));
            },
        });
    };
    return (
        <Paper>
            <Box p={3}>
                <VsmsForm
                    onSubmit={editVsm}
                    userCanEdit={userCanEdit}
                    data={data}
                    buttonTitle={t('Edit')}
                    goBackUrl={goBackUrl}
                />
            </Box>
        </Paper>
    );
};
