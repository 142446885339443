import React from 'react';
import { Field, Form, Formik } from 'formik';
import { MenuItem } from '@material-ui/core';
import { isNil } from 'ramda';
import { cFieldNames, validationSchema } from './schema';
import { UserSearchField } from '../../components/Form/UserSearchField';
import { IForm } from '../../types/IForm';
import { SubmitButton } from '../../components/Form/SubmitButton';
import { CreateZoneLeader, ZoneLeader } from '../../types/ZoneLeaderApiResponse';
import FieldSelect from '../../components/Form/FieldSelect';
import { useTranslation } from '../../contexts/translationContext';
export interface IZoneLeaderForm extends IForm {
    data?: ZoneLeader | null;
    zoneId: number;
}
interface Props extends IZoneLeaderForm {
    onSubmit: (values: any) => void;
}

export const ZoneLeaderForm = ({ onSubmit, data, zoneId, userCanEdit, goBackUrl }: Props) => {
    const { t } = useTranslation();
    const initialValues: CreateZoneLeader = {
        id: 0,
        parentZoneId: zoneId,
        netId: '',
        taskResponsible: false,
    };
    return (
        <Formik
            enableReinitialize
            initialValues={isNil(data) ? initialValues : data}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
                userCanEdit && onSubmit(values);
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Field
                        name={cFieldNames.netId}
                        label="Net id"
                        fullWidth
                        component={UserSearchField}
                        disabled={!userCanEdit}
                    />
                    <Field
                        name={cFieldNames.taskResponsible}
                        label={t('Master-data.hierarchy.detail.production.zone.leader.input-2')}
                        fullWidth
                        component={FieldSelect}
                    >
                        <MenuItem key={'true'} value="true">
                            {t(
                                'Master-data.hierarchy.detail.production.zone.leader.input-2-option-1'
                            )}
                        </MenuItem>
                        <MenuItem key={'false'} value="false">
                            {t(
                                'Master-data.hierarchy.detail.production.zone.leader.input-2-option-2'
                            )}
                        </MenuItem>
                    </Field>
                    <SubmitButton visible={userCanEdit} goBackUrl={goBackUrl} />
                </Form>
            )}
        </Formik>
    );
};
