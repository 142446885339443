import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translationContext';
import { ReportLayout3 } from '../../types/Report5STypes';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Grid, Typography } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import { useStyles } from './styles';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface Props {
    reportL3Data: ReportLayout3;
}

interface ChartData {
    target: number[];
    actualEval: number[];
    labels: string[];
}
export const Report5SCurrentEvalChart = ({ reportL3Data }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [chartData, setChartData] = useState<ChartData>();

    useEffect(() => {
        let target: number[] = [];
        let actualEval: number[] = [];
        let labels: string[] = [];
        Object.keys(reportL3Data.evaluationOfLastWeek).map((key: any, index) => {
            target = [...target, reportL3Data.evaluationOfLastWeek[key][0]];
            actualEval = [...actualEval, reportL3Data.evaluationOfLastWeek[key][1]];
            labels = [...labels, Object.keys(reportL3Data.evaluationOfLastWeek)[index]];
        });
        setChartData({ actualEval: actualEval, labels: labels, target: target });
    }, []);

    const data = {
        labels: chartData?.labels,
        datasets: [
            {
                label: t('Report.5S.chart.target'),
                data: chartData?.target,
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderColor: 'rgba(100, 100, 100, 1)',
                borderWidth: 2,
            },
            {
                label: t('Report.5S.chart.evaluation'),
                data: chartData?.actualEval,
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderColor: 'rgba(0, 0, 255, 1)',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: t('Report.5S.chart.headline'),
                font: {
                    size: 24,
                },
                color: '#000',
            },
        },
        scales: {
            r: {
                ticks: {
                    display: false,
                },
                pointLabels: {
                    font: {
                        size: 16,
                    },
                },
                suggestedMin: 1,
            },
        },
    };

    return (
        <>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    margin: '0 auto',
                    overflow: 'hidden',
                    textAlign: 'center',
                }}
            >
                <Radar data={data} options={options} />
            </div>
        </>
    );
};
