import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import StickyHeadTable from '../../components/Table/Table';
import { Columns } from './Columns';
import { API_URL } from '../../apiTypes';
import { fetcher } from '../../apiUtils';
import { AlertDialog } from '../../components/AlertDialog';
import { useDeleteDialog } from '../../hooks/useDeleteDialog';
import { AuditQuestionTypeApiResponse } from '../../types/AuditQuestionTypeApiResponse';
import { appPaths } from '../../paths';
import { UserRoles } from '../../types/applicationUser.types';
import { useTranslation } from '../../contexts/translationContext';

export const QuestionTypeList = () => {
    const { t } = useTranslation();
    const editRoles = [UserRoles.Admin];
    const stepNames = useStepNames();
    const history = useHistory();
    const { data, error, mutate } = useSWR<AuditQuestionTypeApiResponse>(
        `${API_URL.AUDIT_QUESTION_TYPE_LIST}`,
        fetcher
    );
    const handleAddItem = ({ id }) => history.push(`${appPaths.QUESTION_TYPES}/${id}`);

    const onDelete = () => mutate();
    const { openAlert, handleDeleteAttempt, handleAlertClose, handleAlertConfirm } =
        useDeleteDialog({
            deleteUrl: API_URL.AUDIT_QUESTION_TYPE_DELETE,
            onDelete,
        });

    return (
        <PageWrapper loading={!data} error={error}>
            <HeaderPage
                heading={t('Master-data.topics.main-headline')}
                stepName={stepNames.PRODUCTION}
                buttonSectionPermission={editRoles}
                buttonSection={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddItem({ id: 0 })}
                    >
                        {t('Master-data.topics.add-question-type')}
                    </Button>
                }
            />
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <StickyHeadTable
                        columns={Columns(t)}
                        rows={data}
                        handleDeleteItem={handleDeleteAttempt}
                        handleEditItem={handleAddItem}
                        handleAddItem={() => handleAddItem({ id: 0 })}
                        addItemAndDeletePermissions={editRoles}
                    />
                </Grid>
            </Grid>
            <AlertDialog
                open={openAlert}
                title={t('Delete')}
                content={t('Master-data.topics.message-delete-confirm')}
                handleClose={handleAlertClose}
                handleConfirm={handleAlertConfirm}
            />
        </PageWrapper>
    );
};
