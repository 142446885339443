import * as yup from 'yup';

export const cFieldNames = {
    comment: 'comment',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.comment]: yup.string(),
    });
};
