import * as yup from 'yup';
import { equals } from 'ramda';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNames = {
    name: 'name',
    description: 'description',
    parentProductionId: 'parentProductionId',
    building: 'building',
    floor: 'floor',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.name]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNames.description]: yup.string().nullable(),
        [cFieldNames.parentProductionId]: yup
            .number()
            .test('Card Test', t(cFormValidationMessages.required), (value) => !equals(value, 0)),
    });
};
