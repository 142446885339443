import { useHistory, useParams } from 'react-router';
import { identity, ifElse } from 'ramda';
import { Box, Grid, Paper } from '@material-ui/core';
import { equalsZero } from 'ramda-extension';
import React, { useEffect } from 'react';
import useSWR from 'swr';
import { RouteParams } from '../Permissions/Permission';
import { VsmsForm } from '../../form/vsms';
import { fetcher, postApi, putApi } from '../../apiUtils';
import { CreateVsms } from '../../types/VsmApiResponse';
import { API_URL } from '../../apiTypes';
import { appPaths } from '../../paths';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { UserRoles } from '../../types/applicationUser.types';
import { useAuthContext } from '../../contexts/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import { NotificationManager } from 'react-notifications';

export const NewEditMasterData = () => {
    const roles = [UserRoles.Admin];
    const { t } = useTranslation();
    const { id } = useParams<RouteParams>();
    const history = useHistory();
    const numericId = Number(id);
    const apiUrl = `${API_URL.VSM_ITEM}?id=${id}`;

    const { isAccessibleByUserRoleAndAdminPrivilege } = useAuthContext();
    if (!isAccessibleByUserRoleAndAdminPrivilege) return <Loader />;
    const userCanEdit = isAccessibleByUserRoleAndAdminPrivilege(roles);

    const { data, error, mutate, isValidating } = useSWR<CreateVsms>(apiUrl, fetcher, {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
    });

    useEffect(() => {
        if (!equalsZero(numericId)) {
            mutate();
        }
    }, [numericId]);

    const onSubmit = (values) =>
        ifElse(
            equalsZero,
            () =>
                postApi<CreateVsms>({
                    url: API_URL.VSM_CREATE,
                    data: values,
                    callAfterSuccess: () => {
                        NotificationManager.success(t('Notify.item-created'));
                        history.push(appPaths.MASTER_DATA);
                    },
                }),
            () =>
                putApi({
                    url: API_URL.VSM_UPDATE,
                    data: values,
                    callAfterSuccess: () => NotificationManager.success(t('Notify.item-updated')),
                })
        )(numericId);

    const goBackUrl = `${appPaths.MASTER_DATA}`;
    return (
        <PageWrapper error={error} loading={isValidating}>
            <HeaderPage
                heading={t('Master-data.hierarchy.detail.main-headline-new')}
                stepName={t('Master-data.hierarchy.detail.main-headline-new')}
                breadCrumbSteps={[
                    { name: t('Master-data.hierarchy.main-headline'), pathToCutCount: 3 },
                ]}
                noPaper
                noMb
            />

            <Grid item container xs={5}>
                <Paper>
                    <Box p={3}>
                        <VsmsForm
                            onSubmit={userCanEdit ? onSubmit : identity}
                            data={data}
                            userCanEdit={userCanEdit}
                            goBackUrl={goBackUrl}
                        />
                    </Box>
                </Paper>
            </Grid>
        </PageWrapper>
    );
};
