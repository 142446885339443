import * as yup from 'yup';
import { cFormValidationMessages } from '../../constants/validationMessages';

export const cFieldNamesAssign = {
    netId: 'netId',
    comment: 'comment',
};

export const cFieldNamesFinish = {
    comment: 'comment',
};

export const cFieldNamesChangeZone = {
    vsmId: 'vsmId',
    productionId: 'productionId',
    zoneId: 'zoneId',
};

export const cFieldNamesSetPlannedDate = {
    date: 'date',
    comment: 'comment',
};

export const cFieldNamesSetComment = {
    comment: 'comment',
};

export const validationSchemaComment = (t: any) => {
    yup.object().shape({
        [cFieldNamesSetComment.comment]: yup.string().required(t(cFormValidationMessages.required)),
    });
};

export const validationSchemaAssign = (t: any) => {
    yup.object().shape({
        [cFieldNamesAssign.netId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNamesAssign.comment]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
export const validationSchemaFinish = (t: any) => {
    yup.object().shape({
        [cFieldNamesFinish.comment]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
export const validationSchemaChangeZone = (t: any) => {
    yup.object().shape({
        [cFieldNamesChangeZone.vsmId]: yup.string().required(t(cFormValidationMessages.required)),
        [cFieldNamesChangeZone.productionId]: yup
            .string()
            .required(t(cFormValidationMessages.required)),
        [cFieldNamesChangeZone.zoneId]: yup.string().required(t(cFormValidationMessages.required)),
    });
};
