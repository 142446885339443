import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

interface Props {
    title: string;
    subTitle: string;
    textAlign?: 'left' | 'center' | 'right';
}

export const QuestionHeadline = ({ title, subTitle, textAlign }: Props) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="h1"
                    className={classes.heading}
                    style={{ textAlign: textAlign || 'left' }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="h2"
                    className={classes.subHeading}
                    style={{ textAlign: textAlign || 'left' }}
                >
                    {subTitle}
                </Typography>
            </Grid>
        </>
    );
};
