import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../contexts/translationContext';
import { fetcher } from '../../apiUtils';
import { API_URL } from '../../apiTypes';
import useSWR from 'swr';
import { Loader } from '../../components/Loader/Loader';
import { Grid, Typography } from '@material-ui/core';
import { AuditType } from '../../types/AuditTypeApiResponse';
import { useStyles } from './styles';
import { VsmApiResponse } from '../../types/VsmApiResponse';
import { Production, ProductionApiResponse } from '../../types/ProductionApiResponse';
import { useLocation } from 'react-router';
import { Task, TasksApiResponse, Solver, TaskState } from '../Tasks/types';
import { FilterParams, ReportTasksFilter } from './ReportTasksFilter';
import { ReportTasksChart } from './ReportTasksChart';
import { ReportTasksDetail } from './ReportTasksDetail';
import { ReportHeader } from '../../components/ReportHeader/ReportHeader';
import { Zone } from '../../types/ZoneApiResponse';

export interface TaskAnswerData {
    task: Task;
    attachments: any[];
}

/**
 * Report Tasks
 * @returns
 */
export const ReportTasks = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const location = useLocation();

    const parseUrlParamsToFilterParams = (params: string): FilterParams => {
        const paramsArr = params.split('&');
        let _openedOnly: boolean = false;
        let _dangerousFinding: boolean = false;
        let _auditTypeIds: number[] = [];
        let _vsmIds: number[] = [];
        let _productionIds: number[] = [];
        let _zoneIds: number[] = [];
        let _solverIds: string[] = [];
        paramsArr.forEach((param) => {
            const paramArr = param.split('=');
            switch (paramArr[0]) {
                case 'openedOnly':
                    _openedOnly = paramArr[1] === 'true' ? true : false;
                    break;
                case 'dangerousFinding':
                    _dangerousFinding = paramArr[1] === 'true' ? true : false;
                    break;
                case 'auditTypeIdArr':
                    _auditTypeIds = [..._auditTypeIds, Number(paramArr[1])];
                    break;
                case 'vsmIdArr':
                    _vsmIds = [..._vsmIds, Number(paramArr[1])];
                    break;
                case 'productionIdArr':
                    _productionIds = [..._productionIds, Number(paramArr[1])];
                    break;
                case 'zoneIdArr':
                    _zoneIds = [..._zoneIds, Number(paramArr[1])];
                    break;
                case 'solverIdArr':
                    _solverIds = [..._solverIds, paramArr[1]];
                    break;
            }
        });

        return {
            auditTypeIds: _auditTypeIds,
            vsmIds: _vsmIds,
            productionIds: _productionIds,
            zoneIds: _zoneIds,
            gembaGroupIds: [],
            solverIds: _solverIds,
            selectedOpenOnly: _openedOnly,
            dangerousFinding: _dangerousFinding,
        };
    };

    const [filterParams, setFilterParams] = useState<FilterParams>(
        parseUrlParamsToFilterParams(location.search)
    );

    const [solverList, setSolverList] = useState<Solver[] | null>(null);
    const [filteredProductionList, setFilteredProductionList] = useState<Production[]>([]);
    const [filteredZoneList, setFilteredZoneList] = useState<Zone[]>([]);
    const [filteredTaskList, setFilteredTaskList] = useState<Task[] | null>(null);
    const [filteredTaskChartList, setFilteredTaskChartList] = useState<Task[] | null>(null);
    const [tasksAnswerData, setTasksAnswerData] = useState<TaskAnswerData[]>([]);
    const [actualIndex, setActualIndex] = useState<number>(0);
    const [showOnlyStateFromChart, setShowOnlyStateFromChart] = useState<TaskState | null>(null);

    // auditType list
    const { data: auditTypeList } = useSWR<AuditType[]>(`${API_URL.AUDIT_TYPE_LIST}`, fetcher);
    // VSM list
    const { data: vsmList } = useSWR<VsmApiResponse>(`${API_URL.VSM_LIST}`, fetcher);
    // Production list
    const { data: productionList } = useSWR<ProductionApiResponse>(
        `${API_URL.PRODUCTION_LIST}`,
        fetcher
    );
    // Zone list
    const { data: zoneList } = useSWR<Zone[]>(`${API_URL.ZONES_LIST}`, fetcher);

    useEffect(() => {
        if (!productionList) return;
        let tmpFilteredProdList = productionList;
        if (filterParams.vsmIds.length !== 0) {
            tmpFilteredProdList = productionList.filter(
                (prod: Production) =>
                    filterParams.vsmIds.some((id: number) => id === prod.parentVsmId) ?? prod
            );
        }
        setFilteredProductionList(tmpFilteredProdList);
    }, [productionList]);

    useEffect(() => {
        if (!zoneList) return;
        let tmpFiltered = zoneList;
        if (filteredProductionList.length > 0) {
            tmpFiltered = tmpFiltered.filter((x) =>
                filteredProductionList.map((x) => x.id).includes(x.parentProductionId)
            );
        }

        if (filterParams.productionIds.length != 0) {
            tmpFiltered = tmpFiltered.filter((x) =>
                filterParams.productionIds.includes(x.parentProductionId)
            );
        }

        setFilteredZoneList(tmpFiltered);
    }, [zoneList, filteredProductionList]);

    // list task filtered
    const {
        data: taskList,
        isValidating: taskListLoading,
        mutate: mutateTasks,
    } = useSWR<TasksApiResponse>(
        `${API_URL.USER_TASK_GET_LIST_FILTERED}${location.search
            .replace('&openedOnly=true', '')
            .replace('&openedOnly=false', '')}`,
        fetcher
    );

    useEffect(() => {
        if (!taskList) return;
        let tmpSolverList: Solver[] = [];
        taskList.map((task: Task) => {
            var findItem = tmpSolverList.find((x: Solver) => x.netId === task.solverNetId);
            if (!findItem)
                tmpSolverList.push({
                    name: task.solverName,
                    netId: task.solverNetId,
                });
        });
        setSolverList(tmpSolverList);
        let tmpFilteredTasks: Task[] = taskList;
        if (filterParams.solverIds.length > 0) {
            tmpFilteredTasks = taskList.filter((task: Task) => {
                if (filterParams.solverIds.some((x: string) => x === task.solverNetId)) return task;
            });
        }

        setFilteredTaskChartList(tmpFilteredTasks);

        if (showOnlyStateFromChart)
            tmpFilteredTasks = tmpFilteredTasks.filter((x) => x.state === showOnlyStateFromChart);

        if (location.search.includes('openedOnly=true'))
            tmpFilteredTasks = tmpFilteredTasks.filter((x) => x.state !== TaskState.FINISHED);

        setFilteredTaskList(tmpFilteredTasks);
    }, [taskList, showOnlyStateFromChart]);

    const getAnswerData = (actualIndex: number) => {
        let tasksAnswerData: any[] = [];

        filteredTaskList &&
            filteredTaskList.map(async (task: Task, index: number) => {
                tasksAnswerData = [...tasksAnswerData, { task: task, attachments: [] }];
                if (index === actualIndex)
                    fetch(
                        `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_GET_LIST}?answerId=${task.parentAnswerId}`
                    )
                        .then((res) => res.json())
                        .then((r) => {
                            tasksAnswerData[actualIndex] = {
                                task: tasksAnswerData[actualIndex].task,
                                attachments: r,
                            };
                            setTasksAnswerData(tasksAnswerData);
                        });
            });
    };

    useEffect(() => {
        if (!filteredTaskList || filteredTaskList.length === 0) return;
        getAnswerData(actualIndex);
    }, [actualIndex]);

    useEffect(() => {
        if (filteredTaskList && filteredTaskList.length === 0) return;
        getAnswerData(actualIndex);
    }, [filteredTaskList]);

    // Volá se při změně filtru. Nastaví URL parametry. Čímž dojde k refreshy.
    const filterChanged = (filterParams: FilterParams) => {
        let urlQuery = '';

        // openedOnly
        urlQuery += `&openedOnly=${filterParams.selectedOpenOnly}`;
        // dangerous findings
        urlQuery += `&dangerousFinding=${filterParams.dangerousFinding}`;
        // auditTypes
        filterParams.auditTypeIds.forEach((item) => {
            urlQuery += `&auditTypeIdArr=${item}`;
        });
        // vsm
        filterParams.vsmIds.forEach((item) => {
            urlQuery += `&vsmIdArr=${item}`;
        });
        // productions
        filterParams.productionIds.forEach((item) => {
            urlQuery += `&productionIdArr=${item}`;
        });
        // zones
        filterParams.zoneIds.forEach((item) => {
            urlQuery += `&zoneIdArr=${item}`;
        });
        // solvers
        filterParams.solverIds.forEach((item) => {
            urlQuery += `&solverIdArr=${item}`;
        });
        window.location.search = urlQuery;
    };

    if (
        !auditTypeList ||
        !vsmList ||
        !productionList ||
        !zoneList ||
        taskList === null ||
        solverList === null ||
        filteredTaskList === null ||
        filteredTaskChartList === null
    )
        return <Loader />;

    return (
        <Grid
            container
            style={{
                padding: 0,
                margin: '0 auto',
                display: 'flex',
            }}
        >
            <ReportHeader title={t('Report.task.main-headline')} disableBorder />
            {/* left panel */}
            <Grid item xs={4} style={{ height: '95vh', padding: 5 }}>
                <Grid item xs={12} style={{ height: '45%' }}>
                    <ReportTasksFilter
                        auditTypeList={auditTypeList}
                        productionList={filteredProductionList}
                        vsmList={vsmList}
                        solverList={solverList}
                        zoneList={filteredZoneList}
                        defaultFilterParams={filterParams}
                        onFilterChange={filterChanged}
                    />
                </Grid>
                <Grid item xs={12} style={{ height: '45%' }}>
                    {filteredTaskList && filteredTaskList.length > 0 ? (
                        <ReportTasksChart
                            taskList={filteredTaskChartList}
                            showOnlyStateFromChart={showOnlyStateFromChart}
                            onFilterByState={setShowOnlyStateFromChart}
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>

            {/* mid panel */}
            <Grid item xs={8} style={{ height: '95vh', padding: 5 }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                    {filteredTaskList && filteredTaskList.length > 0 && (
                        <ReportTasksDetail
                            tasksAnswerData={tasksAnswerData}
                            actualIndex={actualIndex}
                            setActualIndex={setActualIndex}
                        />
                    )}
                    {filteredTaskList && filteredTaskList.length === 0 && (
                        <Typography style={{ margin: '25% auto', textAlign: 'center' }}>
                            {t('Report.task.no-data')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
