import React, { useEffect } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useAuthContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { appPaths } from '../../paths';

export const HomePage = () => {
    const { appUser } = useAuthContext();
    const history = useHistory();

    useEffect(() => {
        if (!appUser) history.push(appPaths.LOGIN_PAGE);
        else history.push(appPaths.TASKS);
    }, [appUser]);

    return <Loader />;
};
