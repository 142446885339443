import React, { useEffect } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useStylesL4 } from './styles';
import '../Common/audit.scss';
import { Zone } from '../../../types/ZoneApiResponse';
import { useTranslation } from '../../../contexts/translationContext';
import { GembaType, GembaTypeTranslated } from './AuditLayout_4';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';

interface Props {
    auditsForUser: any;
    zoneGroups: number[] | undefined;
    setZoneGroups: any;
    selectedZoneGroup: number | undefined;
    setSelectedZoneGroup: any;
    filteredZones: any;
}

export const SelectGroupL4 = ({
    auditsForUser,
    zoneGroups,
    setZoneGroups,
    selectedZoneGroup,
    setSelectedZoneGroup,
    filteredZones,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStylesL4();
    const history = useHistory();

    useEffect(() => {
        if (!auditsForUser) return;
        let tmpZoneGroups: number[] = [];
        auditsForUser.map((audit: any) => {
            let index = tmpZoneGroups.findIndex((g: any) => g === audit.intParam1);
            if (index === -1 && audit.intParam1 !== null) {
                tmpZoneGroups = [...tmpZoneGroups, audit.intParam1];
            }
        });
        setZoneGroups(tmpZoneGroups);
    }, [auditsForUser]);

    return (
        <>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography align="center" className={classes.labelText}>
                    {t('Audit.layout.L4.new-photos')} - {t(GembaTypeTranslated.Weekly)}:
                </Typography>
            </Header>
            <Content bottomOffset={0}>
                <Grid
                    container
                    alignContent={'center'}
                    alignItems={'center'}
                    style={{
                        textAlign: 'center',
                    }}
                    spacing={2}
                >
                    <Grid item xs={12}></Grid>
                    {zoneGroups && (
                        <Grid item xs={12} style={{ textAlign: 'left', margin: '20px 0 20px 0' }}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography
                                        align="center"
                                        className={classes.labelText}
                                        style={{ marginTop: 5 }}
                                    >
                                        {t('Audit.layout.L4.label-assigned-group')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        id="select"
                                        label={t('Audit.layout.L4.label-assigned-group')}
                                        value={selectedZoneGroup}
                                        className={classes.formSelect}
                                        style={{ textAlign: 'center' }}
                                        onChange={(e) =>
                                            setSelectedZoneGroup(e.target.value as any)
                                        }
                                    >
                                        {zoneGroups.map((group: number, index: number) => (
                                            <MenuItem key={index} value={group}>
                                                {group}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {filteredZones && selectedZoneGroup && (
                        <Grid item xs={12} style={{ textAlign: 'left', margin: '20px 0 0 30px' }}>
                            <Typography
                                variant="body1"
                                className={classes.labelText}
                                style={{ textAlign: 'left' }}
                            >
                                {t('Audit.layout.L4.label-group-zones')}
                            </Typography>
                            <List>
                                {filteredZones.map((zone: Zone) => (
                                    <ListItem key={zone.id}>
                                        <ListItemText
                                            primary={zone.name}
                                            className={classes.listItemText}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    )}
                </Grid>
            </Content>
        </>
    );
};
